import React from "react";

export default function PrivacyPolicy() {

    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}><span itemProp="name">Home</span></a>
                                <meta itemProp="position" content="1"/></li>
                                <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><span itemProp="item"><span itemProp="name"><strong>Privacy Statement</strong></span></span>
                                <meta itemProp="position" content="2"/></li></ol>            </div>
                </div>
            </section>

            <section className="section section--wysiwyg">
                <div className="container">
                    <div className="row">
                        <div className="wysiwyg">
                            <h1 className="title"><span style={{color: '#dd1b35'}}>Privacy Policy</span></h1>
                        </div>

                        <div className="">
                            <div className="row row-collapse" id="row-1584897271">
                                <div id="col-712727562" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <p>The Middleby Corporation (collectively, “Middleby”) respects your privacy. This Policy describes how the Middleby Corporation collects and uses Personal Information submitted to us via the internet or during the normal course of business.</p>
                                        <div className="wysiwyg"> <h1>Privacy Policy</h1></div>
                                        <p>Please read this policy carefully to understand how Middleby collects, shares, and treats your Personal Information according to the terms of this Privacy Policy.&nbsp; If you do not agree with our policies and practices, your choice is not to use our Website.&nbsp; This Privacy Policy may change from time to time, so please check this Privacy Policy periodically for updates.</p>
                                        <div className="wysiwyg"> <h1>What is Personal Information?</h1></div>
                                        <p>Personal Information is any information that relates to an individual or household and identifies or can be used to identify an individual. Examples include an individual’s name, address, email address, telephone number, Social Security number, or other unique identifier. Sensitive Personal Information includes, but is not limited to, an individual’s gender, race, disabilities, or veteran status.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-359209514">
                                <div id="col-275107422" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <div className="wysiwyg"><h1>When is Personal Information collected?</h1></div>
                                        <p>Personal information may be collected during the application and employment processes. If you provide Personal Information (e.g., a resume or job application), this information will only be used for employment-related purposes.</p>
                                        <p>Personal information may also be collected when you contact us directly, through the web, or through email. Personal information, such as your name and email address, will be collected if you sign up to receive any of our newsletters.</p>
                                        <p>Finally, Personal Information may be collected if you access a Middleby website. For example, information collected can include your IP address, the areas you visit, the technology that you are using, and the Internet Service Provider used. You can use the options in your internet browser to limit or prevent the transmission of this data.&nbsp; The information we automatically collect is used to estimate our audience size and usage patterns; store information about your preferences, allowing us to customize our website according to your individual interests; speed up your searches; and recognize you when you return to our website.&nbsp; Data we collect automatically, which may not be Personal Information, is treated as Personal Information when it is attached to other Personal Information.</p>
                                        <div className="wysiwyg">  <h1>How will your Personal Information be used?</h1></div>
                                        <p>We use your Personal Information to provide you with the services or information you request as well as for website analytical purposes, and providing you with other information you request from and about Middleby.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-1101484854">
                                <div id="col-179848050" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <h1>When will your Personal Information be shared?</h1>
                                        <p>Except as described within this policy, we will not share Personal Information with a third party unless you consent to the disclosure or disclosure is required or authorized by law. Please note that each Middleby subsidiary has its own website Privacy Policy that outlines the manner in which it collects, uses, and potentially shares your Personal Information.&nbsp; To the extent you are purchasing from, receiving parts or service or otherwise utilizing services provided by a Middleby subsidiary, that company’s privacy policy will control.</p>
                                        <p>We may provide customer contact information to third parties so that we can respond to your questions or requests.</p>
                                        <p>We may share Personal Information with service providers, consultants, or affiliates for our internal business purposes. We may also disclose your personal data as part of the purchase or sale of any business or assets.</p>
                                        <p>Personal data collected for employment purposes will only be used for that purpose and will not be disclosed outside Middleby except to those third parties that assist in the processing of payroll or other company benefits or to respond to inquiries from third parties where authorization from the employee has been obtained.</p>
                                        <p>Our websites contain links to other websites operated by third parties. We cannot control these third party websites, which may collect Personal Information from you. Any personal information provided to these third parties is not covered by this policy and we encourage you to review the privacy policies of these sites before using them.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-1616299243">
                                <div id="col-1156489373" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <div className="wysiwyg"> <h1>Storage, retention, and accuracy of Personal Information</h1></div>
                                        <p>Middleby takes all reasonable steps to prevent the unauthorized use or disclosure of your Personal Information; however, transmission of information over the internet is not completely secure. You should consider this risk before transmitting any information to us over the web. Once we have received your data, we do take procedures to try to prevent unauthorized access.</p>
                                        <p>We will retain your Personal Information only as long as required to fulfill the purpose for which it was provided or to comply with applicable laws. We also take commercially reasonable steps to ensure that your Personal Information is reliable for its intended use, accurate, complete, and where necessary, kept up-to-date.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-1572684126">
                                <div id="col-1413212981" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <h1>Use of our websites by children</h1>
                                        <p>Our websites are not intended for use by children under the age of 16. We do not encourage nor solicit information from or about children. If parents believe that their minor children have provided Middleby with Personal Information, please contact us using the information provided below.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-2065345195">
                                <div id="col-1499716607" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <div className="wysiwyg">    <h1>Complaint and contact information</h1></div>
                                        <p>In order to access, correct, or delete your Personal Information, please contact us at legal@middleby.com or via mail at The Middleby Corporation, Attention Privacy Officer, 1400 Toastmaster Drive, Elgin, IL, 60120, USA.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-collapse" id="row-1993985272">
                                <div id="col-956668496" className="col small-12 large-12">
                                    <div className="col-inner">
                                        <div className="wysiwyg">     <h1>Changes to the policy</h1></div>
                                        <p>This policy is current as of the effective date set forth above. Middleby reserves the right to change this policy from time to time consistent with the privacy laws and principles.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}