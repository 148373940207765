import React, { useState, useEffect } from "react";

export default function PerfectBurger() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>

            <section className={`section section1 section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6">
                                <h4 className="mb-4 fw-bold">Craft the Perfect Burger Experience with Middleby Celfrost</h4>
                                <p>Dominate the burger game with efficient, high-quality equipment solutions from <span className="primarycolor fw-bold">Middleby Celfrost</span> . From perfectly toasted buns to crispy fries and juicy patties, we empower you to build a thriving burger business that keeps customers coming back for more.</p>
                                <p>Streamline operations with speed ovens, vertical bun toasters, and high-volume fryers. Ensure quality with blast chillers, shock freezers, and precision tools for toppings. Expand your menu with griddles, beverage dispensing systems, and milkshake machines.</p>
                                <p className="primarycolor fw-bold mt-3"><strong>Partner with Middleby Celfrost and craft the perfect burger experience!</strong></p>
                            </div>


                            <div className={`col-md-5 mb-5 section19 `}>

                                <img className="burgerimage p-0 mb-3 exp-img1 imagehover" src={`${process.env.REACT_APP_API_URL}assets/img/66.jpg`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}