import React from "react";

export default function Convectionoven() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Convection Oven


                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <h5>Unlock Culinary Excellence with Premium Convection Ovens</h5>
                                    <p>Middleby Celfrost brings you an exquisite collection of convection ovens designed to redefine your culinary experience. Explore our renowned brands, each offering distinct features for unparalleled cooking prowess.</p>

                                    <h5>Toastmaster: Precision Baking with Moisture Mastery</h5>
                                    <p>Introducing Toastmaster, where innovation meets baking perfection. Our Toastmaster ovens feature water injection for precise moisture control, ensuring your baked creations achieve optimal texture and flavor. Revolutionize your baking with cutting-edge technology that guarantees exceptional results.</p>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br/>
                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-45-1.png`} alt="Convectionoven image" title="Convectionoven image" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                            <br/>
                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-43-1.png`} alt="Convectionoven image" title="Convectionoven image" />
                            </div>
                        </div>

                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <p className="pt-4 text-justify">
                                    <h5>Lincat: Versatile Convection Excellence Compatible with GN Pans</h5>
                                    <p>Discover Lincat's exceptional convection ovens, including the Convector series and LYNX LCO series. These ovens are crafted to be compatible with GN pans, providing a versatile range of choices. With options for 2, 3, and 4 trays and sizes ranging from 1/2 to 1/1 GN, Lincat convection ovens offer both precision and flexibility in your culinary creations.</p>

                                    <h5>NUVU: Redefining Premium Convection Standards</h5>
                                    <p>For those seeking premium convection performance, NUVU stands as the epitome of excellence. Our NUVU convection ovens feature bakery-standard trays measuring 600mm x 400mm, setting a new benchmark for quality. Experience a world of baking possibilities with top-of-the-line features and craftsmanship.</p>

                                    <p>Transform your kitchen into a hub of culinary innovation with Middleby Celfrost's convection ovens. Whether you prioritize moisture control, versatility with GN pans, or premium baking standards, our range of brands has the perfect solution for you.</p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}