import React from "react";

export default function About() {
    return (
        <>
            <section className="js-block-masthead section section--masthead advanced">
                <div className="section--masthead__top">
                    <div className="js-parallax images images--middle" data-offset="-0.05" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/bckgnd--masthead-top-01.jpg)` }}
                    >
                    </div>
                    <div className="js-parallax images images--front" data-offset="0.15" style={{backgroundPosition: 'center', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/img-masthead-about-01.png)` }}
                   ></div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="wysiwyg">
                                    <h1 className="title"><span style={{ color: 'white' }}>About us</span></h1>
                                </div>
                                <div className="col-md-6">
                                    <div className="wysiwyg">
                                        <p> Middleby Celfrost is a part of USA-based Middleby Corporation, Middleby Celfrost distribute global catering equipment brands, such as
                                            <strong>Lincat, Pitco, TurboChef, Sveba dahlen, Firex, Midd=kitchen, Toastmaster, Cooktek, Josper, Bakers Pride</strong> and more through a network of Indian distribution partners. <br />
                                            <strong>Celfrost</strong>, a distinguished commercial foodservice equipment company, is renowned for providing advanced refrigeration solutions to the foodservice industry in India.
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                        <img src={`${process.env.REACT_APP_API_URL}assets/img/img-masthead-about-01.png`} alt="about us image" title="about us image"/>
                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section--masthead__bottom fadeinsec pt-0" id="next" style={{ marginTop: '-30px' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6">
                                <div className="wysiwyg">
                                    <br /><br />
                                    <img src={`${process.env.REACT_APP_API_URL}assets/img/aboutus.jpg`} alt="about us image" title="about us image" />
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-6">
                                <div className="wysiwyg">
                                    <br />
                                    <p style={{ textAlign: 'justify' }}>
                                        Celfrost's state-of-the-art refrigeration products cater to the discerning needs of the <strong> hospitality, healthcare, and retail industries</strong>, with offerings such as display cabinets, storage cabinets, blast chillers, Chest freezers, Visicoolers, Professional refrigeration, ice machines, bakery & catering equipments, and other Middleby products. <br />
                                        Leveraging the immense resources and expertise of the Middleby Corporation, Celfrost's dedication to excellence and innovation is widely acknowledged in the Indian foodservice industry. The <strong> Middleby Corporation boasts a portfolio of over 125 brands,</strong> with a strong emphasis on commercial cooking and food preparation equipment. <br />
                                        In conclusion, Celfrost stands tall as a highly regarded brand in the Indian foodservice sector, recognized for its superior refrigeration solutions and unwavering commitment to customer satisfaction. As a integral part of Middleby Corporation, Celfrost is poised to continue its ascent to greater heights in the years to come.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--call-to-action  fadeinsec" id="next2" style={{ backgroundColor: '#dd1b35' }}>
                <div className="container" style={{ padding: '80px 5px' }}>
                    <div className="title">
                        <h2 className="fontweightstyle text-white" style={{ color: 'white' }}>"Company History"</h2>
                    </div>
                    <div className="content p-0">
                        <div className="row gx-5 gy-4 align-items-center">
                            <div className="col-md-auto">
                                <p style={{ fontSize: '16px' }} className=" text-white">
                                    "For more than 100 years, the Middleby Family of food service and food preparation equipment brands have been a favourite around the world. The Middleby Corporation is a global leader in the foodservice equipment industry. The company develops, manufactures, markets and services a broad line of equipment used in the commercial foodservice, food processing, refrigeration and residential kitchen equipment industries. In 2014, The Middleby Corporation was named a Fastest Growing Company by Fortune Magazine. In addition, Middleby Celfrost is recognized in India as a preferred commercial foodservice equipment supplier with a broad line of cold side products such as professional refrigerators, cold rooms, ice machines, and freezers, marketed under the Celfrost brand. Celfrost is a leading supplier of equipment to many of the fast-growing restaurant chains and hotel groups. Middleby Celfrost has an extensive marketing and service network in India, including brand centres that we strategically leverage to support the entire Middleby portfolio of brands in the market. We are also excited about the opportunity to introduce the Celfrost brand and portfolio of products into other emerging markets utilizing Middleby's existing sales and marketing infrastructure. Middleby Celfrost hosts an in-house Service team dedicated to cater to service related queries of its customers. A dedicated toll-free helpline and online connectivity via email stays active Weekdays while the technical team is active 24x7 for Customer Service and Satisfaction. Our service has a well-defined and organised structure that is primarily Customer-Centric. Our Team aims at rendering a Hassle-Free Customer Service Experience."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--call-to-action">
                <div className="container">
                    <div className="title">
                        <h2 className="fontweightstyle">Guiding Philosophy</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="service-style2" style={{ backgroundImage: 'url(assets/uploads/vision.png' }}>
                                <div className="service-bg1 d-flex flex-column justify-content-center text-center">
                                    <h2 className="title-vm mb-4">Our Vision</h2>
                                    <p className="service-text">Be The Preferred Organisation For All our Stakeholders</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="service-style2" style={{ backgroundImage: 'url(assets/uploads/mission.jpg' }}>
                                <div className="service-bg1 d-flex flex-column justify-content-center text-center">
                                    <h2 className="title-vm mb-5">Our Mission</h2>
                                    <p className="service-text">Build value with innovative products that deliver speed, lower operating cost, save energy and improve sustainability impacting our customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--call-to-action">
                <div className="container" style={{ padding: '40px 5px' }}>
                    <div className="title">
                        <h2 className="fontweightstyle">"E Waste Policy"</h2>
                    </div>
                    <div className="content">
                        <div className="row gx-5 gy-4 align-items-center">
                            <div className="col-md-auto">
                                <p>The programme aims to create effective awareness in various levels (of society) to reduce the adverse impact on environment and health arising out of the polluting technologies used in recycling e-waste in the unorganized sector.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}