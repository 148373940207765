import React, { useState, useEffect } from "react";
import Splide from '@splidejs/splide';
export default function Marine() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        new Splide('#main-slider', {
            pagination: false,
            type: 'fade',
            drag: 'free',
            focus: 'right',
            perPage: 1,
            autoScroll: {
                speed: 1,
            },
            speed: 4e3,
            rewind: !0,
            autoplay: !0,
            interval: 6e3,
            pauseOnHover: !1,
            pauseOnFocus: !1,
        }).mount();
    }, []);

    return (
        <>
            <section className="js-block-masthead section section--masthead advanced explore-page">
                <div id="main-slider" className="splide">

                    <div className="splide__track splide-overlay">
                        <div className="splide__list h-100vh">
                            <div className="splide__slide explorepagestyle" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore1.jpg`} />
                            </div>
                            <div className="splide__slide explorepagestyle">
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/about2.jpg`} />
                            </div>
                            <div className="splide__slide explorepagestyle" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/about3.jpg`} />
                            </div>
                        </div>
                    </div>

                    <div className="content explore-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 mx-auto p-3 text-white">
                                    <div className="wysiwyg ">
                                        <h1><span>MARINE </span></h1>
                                        <h2 className="title mb-2"><span>Middleby Celfrost: Trusted Innovation in Commercial Food Service Equipment. Explore our industry-leading solutions for kitchens of all sizes</span></h2>
                                        <h5 className="text-justify slide-para">For those who operate in the vast and demanding world of maritime cuisine, Middleby Celfrost stands as a trusted partner. We understand the unique challenges of galley kitchens on ships, from space constraints to rigorous safety regulations. That's why we offer a comprehensive range of innovative and industry-compliant marine kitchen equipment, designed to deliver exceptional performance on the high seas.</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section p-0 section1 bg-dark section--products m-0 `}>
                <div className="w-100 p-0">
                    <div className="row bs-gutter">
                        <div className={`col-lg-6 section4`}>
                            <div className="px-5  mt-4 mb-5 pb-2">
                                <h3 className="primarycolor h1 fw-bold mt-5 mb-3">Crafted for Efficiency in Confined Spaces:</h3>
                                <ul>
                                    <li><strong className="primarycolor"> ✔ Compact Design: </strong>Our equipment boasts a space-saving design, ideal for optimizing galley layouts on various sized vessels. From cruise liners to ferries, we have solutions to maximize functionality within limited footprints.</li>
                                    <li><strong className="primarycolor"> ✔ Modular Flexibility: </strong>Many of our products are modular, allowing for flexible configuration based on specific galley needs.</li>
                                    <li><strong className="primarycolor"> ✔ Multifunctionality:  </strong>Middleby Celfrost offers equipment that combines multiple functions, reducing the overall number of appliances needed and saving valuable counter space.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0 aboutvisionimage" style={{ backgroundImage: `url(${`${process.env.REACT_APP_API_URL}assets/img/explore/marine-03.jpg`})`, backgroundSize: 'cover' }}></div>
                    </div>
                </div>
            </section>

            <section className={`section p-0 section2 bg-grey section--products m-0`}>
                <div className="w-100 p-0">
                    <div className="row bs-gutter">
                        <div className="col-lg-6 p-0" style={{ backgroundImage: `url(${`${process.env.REACT_APP_API_URL}assets/img/aboutmission.png`})`, backgroundSize: 'cover' }}></div>
                        <div className={`col-lg-6 section28`}>
                            <div className="px-5  mt-4 mb-2 pb-2">
                                <h3 className="primarycolor h1 fw-bold">Unwavering Reliability for Uninterrupted Service:</h3>

                                <ul>
                                    <li><strong className="primarycolor"> ✔ Heavy-Duty Construction: </strong>Our marine kitchen equipment is built to withstand the rigors of seafaring life. We utilize high-quality materials and robust engineering to ensure long-lasting performance, even in harsh maritime environments.</li>
                                    <li><strong className="primarycolor"> ✔ Minimized Downtime: </strong>Our equipment is designed for easy maintenance and serviceability, minimizing downtime in case of repairs. This translates to uninterrupted food service operations for your crew and passengers.</li>
                                    <li><strong className="primarycolor"> ✔ Global Service Network: </strong>Middleby Celfrost boasts a well-established global service network, ensuring access to qualified technicians and spare parts wherever your vessel may travel.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section p-0 section3 bg-white section--products m-0`}>
                {/* <div class="wysiwyg">
                    <h2 className="text-center"><strong><span>RELATED PRODUCTS</span></strong></h2>
                    <p className="text-center">Click to explore each product range</p>
                    <hr/>
                </div> */}
                <div>
                    <img className="w-100" src={`${process.env.REACT_APP_API_URL}assets/img/explore/marine-partner-01.jpg`} />
                    <img className="w-100" src={`${process.env.REACT_APP_API_URL}assets/img/explore/marine-partner-02.png`} />
                </div>
            </section>

            <div data-tf-live="01HTC500DHG6Q514TYQJWTTC71"></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
        </>
    )
}