import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const ProductCarousel = ({ product, productImg }) => {
    console.log('productImg', productImg)

    return (
        <Carousel showIndicators={false} showArrows={false}>
            {productImg.map((image, index) => (
                image.url !== '' && (
                    <div key={index}>
                        <img
                            src={`${process.env.REACT_APP_API_URL}assets/images/product/${product}/${image.url}`}
                            alt={`Image ${index + 1}`}
                        />
                    </div>
                )
            ))}
        </Carousel>
    );
};

export default ProductCarousel;
