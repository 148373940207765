import React from "react";

export default function Foodwarmingholding() {
    return (
        <>
            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Food Warming Holding
                                    </strong>
                                </h1>
                                <br />
                                <p className="text-justify">
                                    <h5>Enhance Your Culinary Experience with Premium Holding Equipment</h5>
                                    <p>At Middleby Celfrost, we recognize that maintaining the perfect temperature for your culinary creations is crucial for delivering exceptional dining experiences. Introducing our range of Holding/Warming Stations featuring top-notch brands such as Toastmaster, Pitco, Lincat, and Carter Hoffman.</p>

                                    <p>Whether you operate a renowned restaurant, catering business, or a bustling commercial kitchen, our stations are meticulously designed to ensure your dishes are served hot and fresh every time. Experience the convenience of precision temperature control, elevating the quality of your culinary offerings.</p>

                                    <h5>Unmatched Quality, Unrivaled Performance</h5>
                                    <p>Move beyond traditional heat lamps and revolutionize your holding process. Our Holding/Warming Stations extend holding time and maintain the quality of your culinary delights, from crispy French fries and chicken nuggets to poppers, fried shrimp, and more. Enjoy food that stays hot, ready to serve, with improved quality and reduced waste.</p>

                                    <h5>Trusted Brands for Superior Results</h5>
                                    <p>Select from a range of trusted brands—Toastmaster, Pitco, Lincat, or Carter Hoffman—each ensuring the highest standards of quality and performance. Your choice guarantees an unparalleled holding experience.</p>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br />
                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-115-1.png`} alt="Food Warming Holding image" title="Food Warming Holding image" />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p className="text-justify">
                                    <h5>Keep Your Food Deliciously Warm</h5>
                                    <p>With Middleby Celfrost, you can rely on quality and performance, irrespective of the brand you choose. Our Holding/Warming Equipment is your assurance to keep your food deliciously warm and ready to serve.</p>

                                    <p>Elevate your culinary operations with precision temperature control and top-tier holding solutions. Explore our range of Holding/Warming Stations to enhance the freshness and warmth of your dishes.</p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}