import React from "react";

export default function Landing() {
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name"><strong>OUR SERVICES</strong></span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="space-top space-extra-bottom background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-bg-1-1.png&quot;)'}}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8 col-xl-6"><br />
                            <div className="title-area">
                                <h1 className="sec-subtitle primarycolor fontsizelrg">Our Services</h1>
                            </div><br /><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon">
                                    <img src={`${process.env.REACT_APP_API_URL}assets/img/maintaince.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">
                                    Preventive Maintenenance
                                </h3>
                                <p className="service-text">Preventive maintenance to reduce breakdowns, reduce maintenance costs, preserve equipment in optimum condition, and extend its useful life.<br /> type of contracts...</p>
                                <br />
                                <div>
                                    <a href="preventive-maintenenance.php" className="vs-btn style3">Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/easytoreach.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">
                                    Easy to reach :
                                </h3>
                                <p className="service-text">We operates dedicated in house Customer care Center with technical assistance. Our technical assistants shall understand your challenges and provide probable Cause and remedy. A call would be registered issuing a complaint ID for further communication. Every Call would be attended in stipulated time frame to meet our customers Expectations.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/presence.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Our Presence :
                                </h3>
                                <p className="service-text">Middleby Celfrost Service Engineers and Franchise presence in all major cities Pan India who are intensively trained which ensures value added services either Corrective Or preventive services.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/breakdownservice.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Break Down Service
                                </h3>
                                <p className="service-text">Middleby Celfrost service network ensure that your equipment <br />performs in optimal operating condition while maintaining its highest productivity.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/installation.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Installation , Commissioning
                                </h3>
                                <p className="service-text">We provide Installation & Commissioning for our equipment where necessary.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-410">
                                <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/Spares2.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Spares :
                                </h3>
                                <p className="service-text">Regional parts centres speed up delivery of parts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section--masthead__bottom" style={{display: 'none'}}>
                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="title"><span style={{color: '#dd1b35'}}>OUR SERVICES</span></h1>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Preventive Maintenenance</strong></h1>
                                <p>Combination ovens are widely considered to be
                                    one of the most versatile cooking machines
                                    in the modern professional kitchen. Hugely
                                    powerful, these multifunction ovens deliver
                                    impressive cooking results by combining
                                    steam and convection cooking technology.
                                    <a href="preventive-maintenenance.php">Read More</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Easy to reach :</strong></h1>
                                <p>We operates dedicated in house Customer care Center with technical assistance. Our technical assistants shall understand your challenges and provide probable Cause and remedy. A call would be registered issuing a complaint ID for further communication. Every Call would be attended in stipulated time frame to meet our customers Expectations.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Our Presence :</strong></h1>
                                <p>Middleby Celfrost Service Engineers and Franchise presence in all major cities Pan India who are intensively trained which ensures value added services either Corrective Or preventive services.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Break Down Service</strong></h1>
                                <p>Middleby Celfrost service network ensure that your equipment performs in optimal operating condition while maintaining its highest productivity.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Installation , Commissioning</strong></h1>
                                <p>We provide Installation & Commissioning for our equipment where necessary.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wysiwyg text-center">
                                <h1 className="text-primary"><strong>Spares :</strong></h1>
                                <p>Regional parts centres speed up delivery of parts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--call-to-action">
                <div className="container">
                    <div className="title">
                        <h2>Turbo chef cleaning procedure</h2>
                    </div>
                </div>
                <div className="content">
                    <iframe className="" src="https://www.youtube.com/embed/saAJLJLaou4" allowfullscreen style={{width: '100%', height: '500px'}}></iframe>
                    <div className="row gx-5 gy-4 align-items-center" style={{display: 'none'}}>
                        <div className="col-md-auto">
                            <div className="blog-img"><a href="#" />
                            </div>
                            <div className="blog-content">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}