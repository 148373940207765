import React, { useState, useEffect } from "react";

export default function DiningCoffee() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
         


            <section className={`section section1  section--products mx-0 my-0 pt-3 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row align-items-center justify-content-between">

                            <div className={`col-md-5 mb-5 section18`}>


                                <img className="aboutimage1 p-0 mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/33.jpg`} />
                                <img className="aboutimage1 p-0 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/44.jpg`} />
                            </div>
                            <div className="col-md-6">
                                <h4 className="primarycolor fw-bold">From Casual Dining to Coffee Shops: Equipping Your Restaurant Success Story with Middleby Celfrost</h4><br />
                                <p>Envision a bustling restaurant abuzz with satisfied customers. Middleby Celfrost equips you to create that reality, offering a comprehensive range of solutions for casual dining, fast casual, QSR, multi-cuisine, cafes, and coffee shops.</p>
                                <p>Envision a bustling restaurant abuzz with satisfied customers. Middleby Celfrost equips you to create that reality, offering a comprehensive range of solutions for casual dining, fast casual, QSR, multi-cuisine, cafes, and coffee shops.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}