import React, { useState, useEffect } from "react";

export default function VentlessSolutions() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {/* <section className="js-block-masthead  section section--masthead advanced py-5 bg-slider-explore">
                <div className="elementor-background-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto bg-itm2 ">
                            <div className="text-center">
                                <h4 className="pb-3"><span className="bg-primary">Middleby Celfrost</span></h4>
                                <h1 className="text-white pb-3">Ventless Solutions:</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className={`section section1 section--products bg-white m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>

                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h2 className="primarycolor text-center fw-bold fs-2">
                                    Ventless Solutions:
                                    <br /> Breathe New Life into Your Kitchen with Middleby Celfrost
                                </h2>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-6 mb-5 section25 `}>

                                {/* <div className="col-md-6 mb-5"> */}
                                <img className="ventlesssolutionimage w-100 p-0 p-3 box-shadpw mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/exp-07.jpg`} />

                            </div>
                            <div className={`col-md-6 mb-5 section24 `}>

                                {/* <div className="col-md-6"> */}
                                <p>Middleby Celfrost understands the challenges of limited ventilation in kitchens. That's why we offer a comprehensive line of ventless cooking equipment, empowering you to create a successful restaurant concept without the constraints of traditional ventilation systems.</p>

                                <h2 className="fw-bold text-blue">
                                    Unlock New Possibilities:
                                </h2>

                                <ul className="pt-2 list-unstyled">
                                    <li> <span className="primarycolor fw-bold fs-1-2 ">1. Maximize Space:  </span> Freely arrange your kitchen layout without the limitations of venting hoods.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2">2. Simplify Installation: </span> Reduce upfront costs and installation times compared to traditional equipment.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2">3. Expand Your Menu:  </span>Offer a wider variety of menu options without worrying about ventilation capacity.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2">4.  Sustainable Choice:</span> Minimize energy consumption and contribute to a greener kitchen environment.</li>
                                </ul>
                            </div>
                            <p className="fw-bold fs-1-2 mt-1">Partner with Middleby Celfrost and discover the freedom of ventless cooking. Our innovative equipment delivers exceptional performance and empowers you to create a more versatile and efficient kitchen, regardless of ventilation limitations.</p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}