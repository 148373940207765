import React from "react";

export default function Coldrooms() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                        Coldrooms
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>

                                    {/* <strong>Master Batch Cooking with Firex Bratt Pans</strong>
                                    <p className="mb-3">Unlock the potential of your kitchen with our exceptional Batch Cooking/Bratt Pans from the renowned Firex brand. Designed for culinary innovation and efficiency, these Bratt Pans are your key to mastering large-scale cooking with precision and control.</p> */}

                                    <strong>Celfrost Cold Room Solutions: Guaranteed Freshness for Your Business</strong>
                                    <p className="mt-2"><strong>Celfrost Cold Room Solutions </strong>provide a reliable and efficient way to maintain optimal storage conditions for your perishable goods. Our high-performance cold rooms are ideal for a variety of industries, including:</p>
                                    <ul className="mb-4">
                                        <li>Food &amp; Beverage</li>
                                        <li>Pharmaceuticals</li>
                                        <li>Healthcare</li>
                                        <li>Florists</li>
                                        <li>Restaurants &amp; Hospitality</li>
                                    </ul>

                                    <strong>Benefits of Celfrost Cold Rooms:</strong>
                                    <ul className="my-3">
                                        <li>Precise Temperature Control: Maintain consistent temperatures critical for food safety and product integrity.</li>
                                        <li>Superior Energy Efficiency: Our advanced insulation technology minimizes energy consumption, reducing your operational costs.</li>
                                        <li>Durable Construction: Built with high-quality materials for long-lasting performance and minimal maintenance.</li>
                                        <li>Customizable Solutions: We offer a range of sizes and configurations to meet your specific needs.</li>
                                        <li>Safety Features: Our cold rooms prioritize safety with features like emergency exits and easy-to-clean surfaces.</li>
                                        <li>Improved Organization: Optimize storage space with adjustable shelving and efficient layout options.</li>
                                    </ul>

                                    <strong>Why Choose Celfrost Cold Rooms?</strong>
                                    <ul className="my-3">
                                        <li>Expertise: We have extensive experience in designing and building cold rooms for various applications.</li>
                                        <li>Quality Assurance: All Celfrost cold rooms are built with the highest quality materials and components.</li>
                                        <li>Reliable Service: Our dedicated team is available to provide support throughout the entire process, from pre-sales consultation to installation and after-sales service.</li>
                                    </ul>

                                    <p>Invest in a Celfrost Cold Room Solution and experience the peace of mind that comes with knowing your valuable inventory is stored in a safe and controlled environment.</p>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/images/product/256/pro-256-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}