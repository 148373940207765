import React from "react";

export default function Landing() {
   return (
    <>
    <section className="section section--masthead basic">
    <div className="container">
        <div className="breadcrumbs">
            <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><a itemProp="item" href="/"><span itemProp="name">Home</span></a>
                    <meta itemProp="position" content="1"/>
                </li>
                <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><span itemProp="item"><span itemProp="name"><strong>Experience Center</strong></span></span>
                    <meta itemProp="position" content="2"/>
                </li>
            </ol>
        </div>
    </div>
</section>

<section className="space-top space-extra-bottom background-image bgimg">
    <div className="container">
        <h1 className="sec-subtitle primarycolor fontsizelrg">
            Experience Center
        </h1>
        <br/><br/>
        <div className="row justify-content-center text-center deepakstyle" style={{backgroundColor: 'rgb(250, 250, 250)'}}>
            <div className="col-lg-6 col-xl-6 padding0">
                <div className="title-area ">
                    <p className="exp-para">
                        Discover the excellence of Middleby Celfrost at our network of Brand experience centers across India. Step into our dedicated spaces, where you can explore our exceptional range of <strong>Refrigeration and Culinary products</strong> and make informed decisions.
                        Immerse yourself in the world of Middleby Celfrost at our brand experience centers. <strong>See our products up close and experience their quality, craftsmanship, and innovation firsthand.</strong>
                    </p>
                </div>
            </div>
            <div className="col-lg-6 col-xl-6 padding0"><br />
                <div className="title-area ">
                    <img src="assets/uploads/experienceimage1.jpg" alt="Features"/>
                </div>
            </div>
        </div>
        <br/><br/><br/>
        <div>
            <div className="row justify-content-center text-center deepakstyle">
                <div className="col-lg-6 col-xl-6 padding0"><br />
                    <div className="title-area ">
                        <img src="assets/uploads/experienceimage2.jpg" alt="Features"/>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 padding0">
                    <div className="title-area ">
                        <p className="exp-para">
                            Our knowledgeable staff will guide you through our extensive lineup, answering your questions and providing valuable insights to help you choose the perfect solution for your Kitchen or Store.
                            Whether you're seeking commercial kitchen equipment, soft serve machines, Bakery ovens, Coffee Machines, Chest freezers, or any other product from our portfolio, our brand centers offer a unique opportunity to explore the features, functionality, and benefits of each item.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/><br/>
        <div>
            <div className="row justify-content-center text-center deepakstyle" style={{backgroundColor: 'rgb(250, 250, 250)'}}>
                <div className="col-lg-6 col-xl-6 padding0">
                    <div className="title-area ">
                        <p className="exp-para">
                            Witness how our products can enhance your business operations and elevate your culinary endeavors.
                            Visit our brand experience centers and immerse yourself in the Middleby Celfrost experience. See, touch, and experience our products in a comfortable and immersive environment. Make informed decisions and embark on a journey of culinary excellence with Middleby Celfrost.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 padding0"><br />
                    <div className="title-area ">
                        <img src="assets/uploads/experienceimage3.jpg" alt="Features"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4 col-lg-4 mtt none ">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        KORAMANGALA
                    </h3>
                    <h4 className="paddingh4">A THREE REFRIGERATION</h4>
                    <p className="service-text paddingpara"> Contact person: TRIVIKRAM <br />
                        Address: 13, EJIPURA, <br />
                        100 FEET ROAD,
                        KORAMANGALA, BANGALORE<br />
                        Phone:9538499889 <br />
                        Email:koramangalabc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        PATIALA
                    </h3>
                    <h4 className="paddingh4">PREMIER ELECTRICALS</h4>
                    <p className="service-text paddingpara"> Contact person: SUMMIT GARG <br />
                        Address: BAHERA ROAD, PATIALA <br />
                        Phone:9417682000, 8054057111 <br />
                        Email:premeirelectricalspatiala@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5 padding fonttitle bgcolor">GOA</h3>
                    <h4 className="paddingh4">SUVIDHA KITCHEN</h4>
                    <p className="service-text paddingpara">Contact person: SAHIL ARORA <br />
                        Address: S-3, Nova Cidade Complex, <br />
                        Near Mall De Goa,<br />
                        Porvorim, Goa - 403 521<br />
                        Phone:9763801606, 7447427794<br />
                        Email:celfrostsuvidha@gmail.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">ZIRAKPUR</h3>
                    <h4 className="paddingh4">GURU KRIPA REFRIGERATION - CHANDIGARH</h4>
                    <p className="service-text paddingpara"> Contact person: PARAMJEET SINGH <br />
                        Address: Chandigarh – Zirakhpur Main Highway Road, <br />
                        Opposite Sales Tax Barrier,Near Jai Mata Marbles, <br />
                        Zirakpur – 140603 Punjab<br />
                        Phone:09814033083, 09814088849<br />
                        Email: chandigarhbc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">COCHIN</h3>
                    <h4 className="paddingh4">JAICO ELECTRICALS</h4>
                    <p className="service-text paddingpara"> Contact person: JACOB <br />
                        Address: Janatha Road, Janatha,Vyttila,<br />
                        Cochin-683102<br />
                        Phone:9846076165 <br />
                        Email:cochinbc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        UTTRAKHAND
                    </h3>
                    <h4 className="paddingh4">PRAKASH ENTERPRISES</h4>
                    <p className="service-text paddingpara"> Contact person: ARVIND PALIWAL <br />
                        Address: Plot No- 10,Trimurti Nagar, <br />
                        PAC Idgah Road, Jwalapur-249407, Uttrakhand <br />
                        Phone:9997977002 <br />
                        Email:haridwarbc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        RAIPUR
                    </h3>
                    <h4 className="paddingh4">PUJA MARKETING</h4>
                    <p className="service-text paddingpara">Contact person: NEERAJ GUPTA <br />
                        Address: Opp. Ayurvedic College,<br />
                        G.E.Road, Raipur - 492 001 <br />
                        Phone:9425208979<br />
                        Email: pujamarketingraipur@gmail.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        DELHI
                    </h3>
                    <h4 className="paddingh4">SKYCON APPLIANCES</h4>
                    <p className="service-text paddingpara"> Contact person: Inderpal singh<br />
                        Address: Flat No-20A, Pocket-A, Jail Road,<br />
                        Opposite-Main Gate Hari Nagar Bus Depot,<br />
                        New Delhi-110064<br />
                        Phone:9810419129<br />
                        Email:westdelhibc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        GURGAON
                    </h3>
                    <h4 className="paddingh4">SHREE RAM AGENCIES</h4>
                    <p className="service-text paddingpara"> Contact person: Sandeep<br />
                        Address: Shree Ram Agencies, Shop no- 365,<br />
                        Opp Shani Mandir, Adjoining Samvit Hospital,<br />
                        Subhash Chowck, Sohna Road,<br />
                        Islampur -97, Gurugram, Haryana<br />
                        Phone:9416019315<br />
                        Email:gurgaonbc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        JAIPUR
                    </h3>
                    <h4 className="paddingh4">UTSAV ELECTROMART</h4>
                    <p className="service-text paddingpara"> Contact person: Rajat Gupta<br />
                        Address: C-221, Mahesh Nagar,<br />
                        Gopalpura Bypass, Jaipur-302015<br />
                        Phone:9782690188<br />
                        Email:jaipurbc@middlebycelfrost.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        MEERUT
                    </h3>
                    <h4 className="paddingh4">ARHANT STEEL</h4>
                    <p className="service-text paddingpara"> Contact person: ABHISHEK<br />
                        Address: 95/1, Loha Mandi smith ganj Kabari Bajar,<br />
                        Meerut, Uttarpradesh<br />
                        Phone:8909704530</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        LUDHIANA
                    </h3>
                    <h4 className="paddingh4">BARNALA TRADING COMPAN</h4>
                    <p className="service-text paddingpara">Contact person: Pankaj Singla<br />
                        Address: 3367 Ferozepur Road, 1st Floor,<br />
                        Ludhiana -141001 (PB)<br />
                        Phone:01615010340, +91 9780014950, +91 9780014960<br />
                        Email:contact@barnala.biz</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        KULLU
                    </h3>
                    <h4 className="paddingh4">MALHOTRA REFRIGERATION</h4>
                    <p className="service-text paddingpara">Contact person: Aman Malhotra<br />
                        Address: Bhuntar, Kullu<br />
                        Phone:9816991995</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        JALANDHAR
                    </h3>
                    <h4 className="paddingh4">EVEREST INDUSTRIES</h4>
                    <p className="service-text paddingpara"> Contact person: Rajesh sanan<br />
                        Address: SCF5 Pratap Bagh, Jalandhar<br />
                        Phone:9357258015</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        PUNE
                    </h3>
                    <h4 className="paddingh4">COOL POINT REFRIGERATION</h4>
                    <p className="service-text paddingpara"> Contact person: SABIT SUTAR<br />
                        Address: Shop No.1/2,Laxmitara Market,<br />
                        Near of Dange Chowk,Thergaon Pune-33<br />
                        Phone:9823331155</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        DELHI
                    </h3>
                    <h4 className="paddingh4">
                        UNITED AGENCIES</h4>
                    <p className="service-text paddingpara">
                        Contact person: MANISH SETH <br />
                        Address: 6, NETAJI SUBASH MARG,<br />
                        DARYA GANJ, NEW DELHI -110002<br />
                        Phone:9811016688/9911016688</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        HANUMANGARH
                    </h3>
                    <h4 className="paddingh4">NEW ARORA ENTERPRISES</h4>
                    <p className="service-text paddingpara">Contact person: Mr. Anil Arora<br />
                        Address: Town-Junction Road, Opp Beniwal Hospital, Hanumangarh Junction, Rajasthan-335512<br />
                        Phone: 9414332554</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        SIRSA
                    </h3>
                    <h4 className="paddingh4">NAMDHARI REFRIGERATION</h4>
                    <p className="service-text paddingpara">Contact person: Mr. Prem<br />
                        Address: Plot no-40,Industrial Area 3rd, Near Peer Mandir, Rania Road Sirsa<br />
                        Phone: 9466334999</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        HISAR
                    </h3>
                    <h4 className="paddingh4">KHERA ELECTRONICS</h4>
                    <p className="service-text paddingpara">
                        Contact person: Kumar Khera<br />
                        Address: Sco 70, Red Square Market, Opposite Nalwa Blood Bank, Hisar-125001<br />
                        Phone: 9416302892<br />
                        Email: hisarbc@middlebycelforst.com, kheraelec.hsr@gmail.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        RANCHI
                    </h3>
                    <h4 className="paddingh4">OMKAR SALES AND SERVICES</h4>
                    <p className="service-text paddingpara">Contact person: Prabhat Kumar<br />
                        Address: 87,Anand Niketan,Indrapuri Road no -9 (Beside Post Office)<br />
                        Ratu Road,Ranchi - 834005.(Jharkhand)<br />
                        Phone: 9955453940,07992472289<br />
                        Email: omkarsaleandservices@gmail.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        DELHI
                    </h3>
                    <h4 className="paddingh4">
                        UNIFROST FOOD SERVICE EQUIPMENT</h4>
                    <p className="service-text paddingpara"> Contact person: Pawan Kumar Sharma<br />
                        Address: H No-147, Kh No-241/2, Ground Floor<br />
                        Opposite Metro Pillar No-48<br />
                        MG Road, New Mangla Puri<br />
                        Sultan Pur, New Delhi-110030<br />
                        Phone: 9873255539, 9811791713<br />
                        Email: sales@unifrostindia.com</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        DELHI
                    </h3>
                    <h4 className="paddingh4">AARCO ONE</h4>
                    <p className="service-text paddingpara"> Contact person: Pawan Kumar Sharma<br />
                        Address: I-3, Lajpat Nagar-II,<br />
                        Lajpat Nagar, South Delhi,<br />
                        Delhi - 110024</p>
                </div>
            </div>
            <div className="col-md-4 col-lg-4 mtt none">
                <div className="service-style1">
                    <div className="service-bg background-image" style={{backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)'}}>
                    </div>
                    <h3 className="service-title h5  padding fonttitle bgcolor">
                        GURGAON
                    </h3>
                    <h4 className="paddingh4">SKYCON APPLIANCES</h4>
                    <p className="service-text paddingpara"> Contact person: Mr. Sarbjeet Singh<br />
                        Address: Dahiya Farms,Secr 17-18 Crossing,<br />
                        Opp Green Park Farm,Gurgaon ,Haryana- 122001<br />
                        Phone: 9810463672<br />
                        Email: skycon.celfrost@gmail.com</p>
                </div>
            </div>
        </div>
    </div>
</section>
<br /><br /><br /><br />
    </>
    )
}