import React from "react";

export default function Ventless() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Ventless
                                    </strong>
                                </h1>
                                <br />
                                <div>
                                <strong>Perfect Fry - Ventless Fryer:</strong><br />
                                    <p style={{ textAlign: 'justify' }}>Perfect Fry reimagines frying without the need for an exhaust hood. Enjoy the convenience of delicious fried foods in any kitchen setting.</p><br/>
                                    <strong>CiboPlus Speed Oven:</strong><br/><br/>
                                    <p>The CiboPlus Speed Oven is a versatile, ventless appliance that offers rapid cooking without the fuss of extensive ventilation systems.</p><br/>
                                    <strong>TurboChef Speed Oven:</strong><br/><br/>
                                    <p>TurboChef is synonymous with innovation, and its Speed Oven offers rapid cooking technology without the need for a traditional kitchen exhaust.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 p-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-359-1.png`} alt="Waste Station" title="Waste Station" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1 py-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-314-1.png`} alt="Waste Station" title="Waste Station image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <div className="col-md-10">
                                    <strong>TurboChef Conveyor Oven:</strong><br/><br/>
                                    <p>Enjoy the benefits of conveyor oven cooking without the constraints of traditional ventilation.</p><br/>
                                    <strong>Extraction Hood for Combi Oven:</strong><br/><br/>
                                    <p>Our extraction hood complements your combi oven, ensuring a clean kitchen environment without the need for extensive exhaust systems.</p><br/>
                                    <p>Middleby Celfrost brings you a breath of fresh air in your kitchen. Experience the efficiency, innovation, and convenience of ventless equipment that works seamlessly to elevate your culinary operations. Inhale the future of cooking with Middleby Celfrost.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}