import React from "react";

export default function Fryers() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Fryers
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <h1>Explore Culinary Mastery with Middleby Celfrost's Fryer Collection</h1>

                                    <p className="col-11">
                                        Embark on a culinary journey with Middleby Celfrost, where excellence meets versatility in our extensive range of commercial fryers. Increase your kitchen's capabilities with countertop electric and induction-based fryers, boasting capacities from 4 to 10 litres. Our renowned brands include Midd=Kitchen, Celfrost, Toastmaster, Frifri, and Lincat. Dive deeper into innovation with floor-standing fryers, offering oil capacities of 18, 24, 35 litres and more, proudly presented by brands like Pitco, Toastmaster, BKI, and Midd=Kitchen.

                                    </p>

                                </p>

                                <p className="pt-4 text-justify">
                                    <h1>
                                        Unmatched Variety, Unbeatable Value

                                    </h1>

                                    <p className="col-11">
                                        Middleby Celfrost presents a comprehensive collection of commercial fryers, catering to diverse business needs. Explore our selection of value fryers, available in compact countertop and floor-standing models. Ideal for startups and small eateries, these reliable fryers ensure outstanding performance without compromising your budget. Select from trusted brands like Toastmaster and Midd=Kitchen, recognized for durability and value.
                                    </p>

                                </p>

                                <p className="pt-4 text-justify">
                                    <h1>
                                        Culinary Prowess Redefined - Pitco Fryers


                                    </h1>

                                    <p className="col-11">
                                        For those pursuing the epitome of frying excellence, we proudly introduce Pitco fryers. Our premium floor-standing fryers from Pitco set new industry standards, delivering precision, durability, and efficiency to ensure your fried dishes achieve unparalleled perfection.

                                    </p>

                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 " src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-73-1.png`} alt="Fryers image" title="Fryers image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">


                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-76-1.png`} alt="Fryers image" title="Fryers image" />
                            </div>
                        </div>

                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <p className="pt-4 text-justify">
                                    <h1>
                                        Cutting-Edge Ventless Countertop Frying

                                    </h1>
                                    <p className="col-11">
                                        Experience the forefront of technology with the ventless countertop fryer from Perfect Fry Company, revolutionizing the capabilities of your kitchen.</p>
                                </p>


                                <p className="pt-5 text-justify">
                                    <h1 className="pt-2">
                                        Craft Crispy and Golden Culinary Creations

                                    </h1>

                                    <p className="col-11 ">
                                        Crafted to perfection, our commercial fryers guarantee deliciously crispy and golden cooking results. Whether you're preparing Southern-style fried chicken or classic French fries, our fryers, available in both gas and electric versions, provide a diverse range to meet the demands of your busiest service.

                                    </p>

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}