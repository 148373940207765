import React from "react";

export default function WasteStation() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Waste Station IP Compactors
                                    </strong>
                                </h1>
                                <br />
                                <div>
                                <h1>Revolutionizing Waste Management with IMC Solutions</h1><br />
                                    <p style={{ textAlign: 'justify' }}>Embark on a journey towards sustainable and efficient waste management with our cutting-edge solutions from IMC, proudly made in the UK. Introducing the IMC WasteStation Compact and IP Compactors, these innovative systems redefine how we handle both food and non-food waste.</p><br/>
                                    <strong>IMC WasteStation Compact: Transforming Food Waste Management</strong><br/><br/>
                                    <p>The IMC WasteStation Compact is a game-changer in food waste management. It efficiently grinds food waste into fine particles, directing them into the integrated dewatering system. Through centrifugal action, it separates excess liquid from macerated waste, channeling it directly to the drain. The resulting solid fraction of food waste is neatly collected in small, easily managed, lidded bins, ready for onward processing. Experience a revolutionary approach to handling food waste responsibly.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 p-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-238-1.png`} alt="Waste Station" title="Waste Station" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1 py-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-237-1.png`} alt="Waste Station" title="Waste Station image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <div className="col-md-10">
                                    <strong>IP Compactors: Revolutionizing Non-Food Waste Management</strong><br/><br/>
                                    <p>IP Compactors redefine non-food waste management by efficiently reducing the volume of materials like glass, tin, and cardboard. These systems work in harmony to provide a comprehensive approach to waste management, significantly reducing waste volume, improving liquid separation, and promoting responsible waste disposal. Join us in creating a more sustainable and efficient future with our IP Compactors.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}