import React, { useState, useEffect } from "react";

export default function FoodRetail() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
           


            <section className={`section section1 section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h4 className="primarycolor text-center fw-bold fs-3">Modernizing the Food Retail Experience <br/>with Middleby Automation</h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6">
                                <p className="text-black">Today's food retail landscape is all about convenience and efficiency. Customers expect fresh, high-quality food, prepared quickly. But rising costs and labor challenges can strain operations.</p>
                                <p>Middleby automation offers a powerful solution for supermarkets and food retailers. We provide a range of automated technologies designed to streamline back-of-house processes, from inventory management to food preparation.</p>

                                <h2 className="primarycolor fw-bold">
                                    Imagine:
                                </h2>
                                <ul className="pt-3 list-unstyled">
                                    <li> <span className="primarycolor fw-bold fs-1-2">1. &nbsp; Automated Replenishment: </span> Never run out of stock again.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2"> 2. &nbsp;Centralized Food Prep: </span> Deliver consistent quality across locations.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2"> 3.&nbsp; Faster Service: </span> Reduce wait times and improve customer satisfaction.</li>
                                    <li> <span className="primarycolor fw-bold fs-1-2"> 4.&nbsp; Reduced Food Waste: </span> Optimize inventory and minimize spoilage.</li>
                                </ul>
                                <p className="fw-bold">Middleby Celfrost understands the unique needs of food retail. Let's discuss how our automation solutions can revolutionize your supermarket operations.</p>
                            </div>
                            {/* <div className="col-md-5 mb-5"> */}
                            <div className={`col-lg-5  mb-5 section33`}>
                                <img className="pizzeriaimage box-shadow p-0 mb-3 p-2 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/exp-12.jpg`} />
                                <img className="pizzeriaimage p-0 box-shadow p-2 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/exp-13.jpg`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}