import React from "react";

export default function Softserve() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Soft Serve Thick Shakes
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <h5>Delight in Frozen Bliss with Icetro and Taylor Softy Machines</h5>
                                    <p>Immerse yourself in a world of frozen indulgence with our exquisite soft serve and thick shakes machines from two distinguished brands: Icetro and Taylor. Elevate your dessert offerings and captivate your customers with the irresistible allure of these premium machines.</p>

                                    <h5>Icetro Soft Serve & Thick Shakes:</h5>
                                    <p>Savor the velvety goodness of Icetro soft serve, where every bite promises a creamy texture and an explosion of flavor. Whether you run a café, restaurant, or ice cream parlor, Icetro delivers an indulgent experience that keeps customers coming back for more. Transform ordinary moments into extraordinary delights with Icetro.</p>

                                    <h5>Taylor Soft Serve & Thick Shakes:</h5>
                                    <p>Experience the pinnacle of quality and innovation in frozen desserts with Taylor. Our Taylor soft serve and thick shakes equipment provide the perfect platform to craft delectable and consistent treats. Precision engineering ensures each serving is a delightful experience, setting a new standard for frozen indulgence. Taylor—where perfection meets frozen delight.</p>

                                    <p>Choose Middleby Celfrost for a frozen treat experience that exceeds expectations. Explore our range of Icetro and Taylor softy machines, and let the blissful journey into frozen delight unfold.</p>

                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-282-1.png`} alt="SLUSH MACHINE" title="SLUSH MACHINE"/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}