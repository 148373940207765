import React, { useState, useEffect } from "react";

export default function IceCream() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
           

            <section className={`section section1 bg-white section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-5 mb-5 m-auto section30 `}>
                                {/* <div className="col-md-5 mb-5 m-auto"> */}
                                <img className="ventlesssolutionimage p-3 box-shadow rounded-1" src={`${process.env.REACT_APP_API_URL}assets/img/exp-18.jpg`} />
                            </div>
                            <div className="col-md-6">
                                <p className="fw-6 text-black">The world craves delightful treats,
                                    but managing busy ice cream and juice parlours can be a challenge.
                                    Long lines, inconsistent product quality, and labor costs can quickly melt your profits.
                                    Middleby Automation offers a cool solution.
                                </p>

                                <h6 className="text-black  fw-bold mt-1">
                                    We provide innovative technologies to streamline operations and elevate your customer experience.
                                    <span className="primarycolor">  Imagine:</span>
                                </h6>

                                <ul className="mt-3">
                                    <li>  <span className="primarycolor fw-bold "> Automated Dispensing Systems:  </span> Deliver perfect portions of ice cream, juice, and frozen yogurt quickly and consistently.</li>
                                    <li>  <span className="primarycolor fw-bold "> Inventory Management:          </span> Maintain optimal stock levels and minimize waste.</li>
                                    <li>  <span className="primarycolor fw-bold "> Labor Optimization:            </span> Free staff for customer service and cleaning tasks.</li>
                                    <li>  <span className="primarycolor fw-bold "> Faster Service:                </span> Reduce wait times and keep queues moving.</li>
                                </ul>

                            </div>
                            <p className="pt-4 text-center fw-bold">Middleby Celfrost understands the unique needs of ice cream and juice parlours. Let's create a customized automation strategy that keeps your customers coming back for more smiles and delicious treats.</p>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}