import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Brands() {
    const { brand } = useParams();
    const [detail, setDetail] = useState(null);
    const fetchBrand = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'BrandDetails', brand: brand });
            setDetail(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchBrand();
    }, []);
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope
                            itemType="https://schema.org/BreadcrumbList"><li
                                className="crumb" itemProp="itemListElement"
                                itemScope itemType="https://schema.org/ListItem"><a
                                    itemProp="item" href="index"><span
                                        itemProp="name">Home</span></a><meta
                                    itemProp="position" content="1" /></li>
                            <li
                                className="crumb crumb-3243"
                                itemProp="itemListElement" itemScope
                                itemType="https://schema.org/ListItem"><span
                                    itemProp="item"><span itemProp="name"><strong>{detail && detail.name ? detail.name : ''}</strong></span></span><meta
                                    itemProp="position" content="2" /></li></ol>
                    </div>
                </div>
            </section>


            <section className="section section--brand">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="column column--left">
                                <h1 className="visually-hidden">{detail && detail.name ? detail.name : ''}</h1>

                                <div className="logo">

                                    <img
                                        width="339" height="200"
                                        src={`${process.env.REACT_APP_API_URL}/assets/images/tags/${detail && detail.logo ? detail.logo : ''}`}
                                        className="attachment-full size-full"
                                        alt="Hounö logo" decoding="async"
                                        loading="lazy"

                                    />
                                </div>

                                <h2>{detail && detail.heading ? detail.heading : ''}</h2>

                                {detail && detail.image &&
                                    <img width="600" height="750"
                                        src={`${process.env.REACT_APP_API_URL}/assets/images/tags/${detail && detail.image ? detail.image : ''}`}
                                        className="attachment-brand-image size-brand-image"
                                        alt="Hounö Invoq - 6 grid combi oven" decoding="async" loading="lazy" />
                                }
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-5 ms-auto">
                            <div className="column column--right">

                                {detail && detail.heading &&
                                    <div dangerouslySetInnerHTML={{ __html: detail.description }} />}

                                <div
                                    className="actions">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <a className="btn"
                                                href={`${process.env.REACT_APP_APP_URL}/products/brand/${detail && detail.slug ? detail.slug : ''}`}
                                                data-product="<?=$brandrew['name'];?>">
                                                <span>View {detail && detail.name ? detail.name : ''} products</span>


                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}