import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Chatbot from './Componants/Chatbot';


export default function Header() {

    const [isActive, setIsActive] = useState(false);
    const [isActives, setIsActives] = useState('');


    const handleClick = () => {
        setIsActive(!isActive);
    };

    const closeMenu = () => {
        setIsActive(false);
    };


    return (
        <header id="header" className={`header ${isActive ? 'nav-active' : ''} ${isActives ? 'submenu-active' : ''}`} role="banner">


            <div className="inner justify-content-between">
                <h1 id="header__title">
                    <Link to={`${process.env.REACT_APP_APP_URL}/`} rel="home" title="Middleby Celfrost">
                        <span className="visually-hidden">Home</span>
                        <img alt="Middleby Celfrost" src={`${process.env.REACT_APP_API_URL}assets/img/logo/logo-white.png `} />
                    </Link>
                </h1>
                <p id="header__tagline">Where passion meets innovation</p>

                <div id="header__contact">
                    <div className="row flex-column flex-xl-row">
                        <div className="col-xl-auto">
                            <a href="tel:1800-102-1980"><span className="icon text-primary"><i className="fa fa-phone"></i></span> 1800-102-1980</a><br />
                            <a className="grn" href="tel:+91 1244828500"><span className="icon text-success" style={{ Color: '#dd1b35' }}><i className="fa fa-phone"></i></span> +91 1244828500</a>
                        </div>
                        <div className="col-xl-auto">
                            <a href="mailto:service.india@middlebycelfrost.com"><span className="icon text-primary"><i className="far fa-envelope"></i></span> service.india@middlebycelfrost.com</a><br />
                            <a className="grn" href="mailto:sales@middlebycelfrost.com"><span className="icon text-success" style={{ Color: '#dd1b35' }}><i className="far fa-envelope"></i></span> sales@middlebycelfrost.com</a>
                        </div>
                    </div>
                </div>
                <button id="header__toggle" className={`js-nav-toggle hamburger hamburger--spin ${isActive ? 'is-active' : ''}`} type="button" aria-label="Mobile Navigation Toggle" aria-expanded="false" onClick={handleClick}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <nav id="nav" role="navigation" aria-label="Main Navigation">
                    <div id="nav__wrapper" className={`header w-sm-100 w-md-25`} >
                        <p id="nav__title" >
                            <Link className="logo" to={`${process.env.REACT_APP_APP_URL}/`} rel="home" title="Middleby Celfrost">
                                <img alt="Middleby Celfrost" src={`${process.env.REACT_APP_API_URL}/assets/img/logo/logo-white.png`} />
                            </Link>
                            <span className="visually-hidden">Menu</span>
                        </p>
                        <ul id="nav__primary" className="nav__primary" role="menubar">
                            <li id="menu-item-1" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/`} onClick={closeMenu}  role="menuitem" tabIndex="-1" aria-haspopup="false" aria-expanded="false">Home</Link>
                            </li>
                            <li id="menu-item-5" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-5" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/about-us`} onClick={closeMenu} role="menuitem" tabIndex="0" aria-haspopup="true" aria-expanded="false">About us</Link>
                            </li>
                            <li id="menu-item-2" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2" role="none">
                                <a href={`${process.env.REACT_APP_APP_URL}/our-products`} onClick={closeMenu}  role="menuitem" tabIndex="-1" aria-haspopup="false" aria-expanded="false">Brands & Products</a>
                            </li>
                            {/* <li id="menu-item-8" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/experience-center`} onClick={closeMenu}  role="menuitem" tabIndex="-1" aria-haspopup="true" aria-expanded="false">Experience Centres</Link>
                            </li> */}
                            
                            <li id="menu-item-9" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-93" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/service`} onClick={closeMenu}  role="menuitem" tabIndex="-1" aria-haspopup="false" aria-expanded="false">After-Sales Service</Link>
                            </li>    
                            <li id="menu-item-5" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-5" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/culinary`} onClick={closeMenu}  role="menuitem" tabIndex="0" aria-haspopup="true" aria-expanded="false">Culinary support</Link>
                            </li>                       
                            <li id="menu-item-5" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-5" role="none">
                                <Link target='_blank' to={`${process.env.REACT_APP_APP_URL}/catalouge/catalouge.php`} onClick={closeMenu}   role="menuitem" tabIndex="0" aria-haspopup="true" aria-expanded="false">Downloads</Link>
                            </li>
                            <li id="menu-item-8" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/contact-us`} onClick={closeMenu} role="menuitem" tabIndex="-1" aria-haspopup="true" aria-expanded="false">Contact Us</Link>
                            </li>
                            <li id="menu-item-8" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8" role="none">
                                <Link to={`${process.env.REACT_APP_APP_URL}/category/blogs`} onClick={closeMenu} role="menuitem" tabIndex="-1" aria-haspopup="true" aria-expanded="false">News</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <Chatbot />
        </header>
    );
}
