import React, { useState, useEffect } from "react";

export default function AuthenticPizzas() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
          
          {/* <section className={`section section1 bg-black text-white section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
               */}
            <section className={`section section1 section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3 pb-0">
                                <h4 className="primarycolor fw-bold">
                                    Craft Authentic Pizzas with Middleby Celfrost
                                </h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between pt-0">
                            <div className="col-md-6">
                                <p>Equip your pizzeria for success with Middleby Celfrost, your one-stop shop for top-tier pizzeria equipment. From perfectly crisp crusts to melty cheese and delicious toppings, we empower you to craft pizzas that will have your customers coming back for more.</p>

                                <h2 className="fw-bold">
                                    Unleash the Potential of Your Pizzeria:
                                </h2>

                                <ul className="pt-2 list-unstyled">
                                    <li>  <span className="primarycolor fw-bold">✔  Stone Pizza Ovens:</span>  Deliver authentic baking methods for pizzas with a signature crispy crust.</li>
                                    <li> <span className="primarycolor fw-bold">✔   Deck Ovens</span> Ensure consistent heat for even baking and perfect results every time.</li>
                                    <li> <span className="primarycolor fw-bold">✔  High-Performance Prep Equipment: </span> Prepare your dough and toppings efficiently to meet demand.</li>
                                    <li> <span className="primarycolor fw-bold">✔  Holding Cabinets:</span> Maintain optimal temperatures for cooked pizzas to ensure quality and freshness.</li>
                                </ul>
                                <p className="fw-6 mt-4">Partner with Middleby Celfrost to create a pizzeria that stands out. Our equipment solutions are designed for efficiency, performance, and durability, empowering you to focus on what matters most - crafting delicious pizzas that leave a lasting impression.</p>
                            </div>

                            <div className={`col-md-5 mb-5 section23 `}>
                                <img className="pizzeriaimage p-0 mb-3 exp-img1" src={`${process.env.REACT_APP_API_URL}assets/img/exp-05.jpg`} />
                                <img className="pizzeriaimage p-0 exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/exp-06.jpg`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}