import React, { useState, useEffect } from "react";

export default function IOT() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <section className="section mik_section_1 new_inovation_section_1" id="section_1770293034">
                <div className="bg section-bg fill bg-fill bg-loaded"></div>
                <div className="section-content container overflow-hidden relative">
                    <div className="row w-100" id="row-766277957">
                        <div id="col-666011233" className="col small-12 large-12">
                            <div className="col-inner">
                                <div id="text-1921655771" className="text header-section text-center">
                                    <h1><strong>Stay Connected in the Kitchen</strong></h1>
                                    <h4><strong>with IoT-Based Solutions from Middleby's family of brands</strong></h4>
                                    <div className="img-inner dark mt-5">
                                        <img fetchpriority="high" decoding="async" width="167" height="116" src="https://www.middlebycelfrost.com/allapi/assets/images/tags/55celfrost.png" className="attachment-original" alt="Open Kitchen logo" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section" id="section_851968365">
                <div className="container">
                    {/* <div className="bg section-bg fill bg-fill bg-loaded bg-loaded"></div> */}
                    <div className="section-content container overflow-hidden relative">
                        <div className="row" id="row-1445605766">
                            <div id="col-2080983459" className="col small-12 large-12">
                                <div className="col-inner">
                                    <div id="text-3981860061" className="text-center text landing-content">
                                        <h1>
                                            <span style={{ fontSize: '160%' }}>
                                                <strong>Middleby IoT-based Solutions</strong>
                                            </span>
                                        </h1>
                                        <hr />
                                    </div>
                                </div>
                            </div>

                            <div id="col-927938531" className="col small-12 large-12">
                                <div className="col-inner">
                                    <div id="text-1851826248" className="text">
                                        <h2 className="text-center">Data Enables Operators to Run Kitchens More Efficiently</h2>
                                        <p className="text-center">Open Kitchen enables users to monitor, analyze and control all facility assets, including HVAC, lighting and refrigeration. With the <a href="https://powerhousedynamics.com/solutions/connected-equipment/?__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&amp;__hssc=201877710.1.1715939072483&amp;__hsfp=486857294" target="_blank" rel="noreferrer">Open Kitchen application</a>, restaurants, convenience stores, other food service operators and food retailers can connect and monitor different brands of kitchen equipment and automate essential kitchen functions using just one platform.<br />
                                        </p>
                                    </div>
                                </div>
                            </div>



                            <div id="col-663778171" className="col small-12 large-12">
                                <div className="col-inner">
                                    <div className="video video-fit mb">
                                        <iframe title="Open Kitchen" width="1145" height="650" src="https://www.youtube.com/embed/5Qd59fwmYCM?feature=oembed&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.middleby.com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-7="true" id="391849997" data-gtm-yt-inspected-32955573_776="true"></iframe>
                                        <div className="swp-content-locator"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section section1 section--products m-0 bg-white ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                            </div>
                        </div>
                        <div className="row justify-content-between py-3">
                            <div className="col-md-6 px-5">
                                <h1 className="text-muted fw-bold fs-2">Optimizing Foodservice with Data Analytics</h1>
                                <p>Open Kitchen optimizes operational processes through the digitization of the commercial kitchen, creating efficiencies and a range of benefits for multisite operators.</p>

                                <h6 className="text-muted fw-bold  mb-2">Open Kitchen at a Glance:</h6>
                                <ul className="mt-3 list-unstyled">
                                    <li>An <a className="primarycolor" href="https://powerhousedynamics.com/solutions/connected-equipment/?__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&amp;__hssc=201877710.1.1715939072483&amp;__hsfp=486857294" target="_blank" rel="noreferrer">open solutions capable of communicating with equipment</a> from all manufacturers.</li>
                                    <li>Out-of-the-box predictive analytics, configurable reports and escalating alerts</li>
                                    <li>Remote recipe distribution and a recipe creation hub</li>
                                    <li>Real-time wireless temperature monitoring</li>
                                    <li>Powerful mobile app simplifies task management</li>
                                </ul>
                            </div>


                            <div className="col-md-6 px-5 section17 mb-5">
                                <img className="w-100" src={`${process.env.REACT_APP_API_URL}assets/img/explore/Open-Kitchen-Pitco.png`} alt="Open Kitchen Pitco"/>
                            </div>
                        </div>

                        <div className="row justify-content-between py-5">
                            <div className={`col-md-6 px-5 section17 mb-5`}>
                                <img className="w-100" src={`${process.env.REACT_APP_API_URL}assets/img/explore/PhD-Open-Kitchen.png`} alt="PhD Open Kitchen"/>
                            </div>

                            <div className="col-md-6 px-5">
                                <h1 className="text-muted fw-bold fs-2">Real-time Knowledge Offers Benefits</h1>
                                <p>
                                    <a className="primarycolor" href="https://powerhousedynamics.com/solutions/connected-equipment/?__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&amp;__hssc=201877710.1.1715939072483&amp;__hsfp=486857294" target="_blank" rel="noreferrer">Open Kitchen® from Powerhouse Dynamics</a> is a powerful enterprise equipment and energy management platform that provides multisite operators of small facilities with intuitive controls and analytics to reduce energy and maintenance costs and, for food service operators, enhances kitchen performance. Save up to 20% of energy costs, better manage your critical equipment, and gain full visibility into operations.</p>

                                <ul className="mt-4">
                                    <li>Lower your operating costs</li>
                                    <li>Create consistently comfortable&nbsp;conditions for your customers and staff</li>
                                    <li>Increase equipment uptime and optimize performance</li>
                                    <li>Reduce food safety risks</li>
                                    <li>Improve product quality</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="section" id="section_1310336056">
                <div class="container relative">
                    <div class="row" id="row-1862284259">
                        <div id="col-2107181625" class="col small-12 large-12">
                            <div class="col-inner">
                                <div id="text-1171179086" class="text">
                                    <h2><b>Arby's Case Study:<br />
                                        Saving Money with Open Kitchen, IoT, and Smart Kitchens</b></h2>
                                    <div>Arby's Franchisee, The Brumit Group, is improving efficiency and controlling costs across 50+ locations utilizing the benefits of IoT and smart kitchen tech.</div>
                                    <div>Learn how you can save money while making food and equipment safer.</div>
                                    <p>
                                        <strong>
                                            <span style={{ fontSize: ' 130%' }}>
                                                <a href="https://www.middleby.com/wp-content/uploads/2021/09/PhD-Brumit-Case-Study.pdf" target="_blank" rel="noreferrer">Download the Arby's Case Study</a>
                                            </span>
                                        </strong>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section py-5" id="section_494210394">
                <div className="container">
                    <div class="section-content container overflow-hidden relative">
                        <div class="row" id="row-675048622">
                            <div id="col-629315324" class="col small-12 large-12">
                                <div class="col-inner">
                                    <div id="text-2275148459" class="text landing-content text-muted text-center">
                                        <h2>
                                            <img decoding="async" class="aligncenter size-full wp-image-4108 lazy-load-active" src={`${process.env.REACT_APP_API_URL}assets/img/explore/lab2fab-logo-png-1.png`} alt="" width="260" height="126" />
                                        </h2>
                                        <h2 className="mb-3 mt-5">
                                            <strong>The Cutting Edge of Food Automation</strong>
                                        </h2>
                                        <p>Cloud-based artificial intelligence and machine learning ensures your organization continuously learns and gets better over time. Lab2Fab's servR is the smarts behind it all, connecting all L2F IoT products&nbsp;with easy, actionable insights&nbsp;so you can manage operations with ease.<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5" id="section_607504038">
                <div className="section-content container overflow-hidden relative">
                    <div className="row row-small row-full-width" id="row-352347965">
                        <div id="col-1033351005" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-3561579800" className="text landing-content">
                                    <p>
                                        <img decoding="async" className="aligncenter size-full wp-image-4114 lazy-load-active" src={`${process.env.REACT_APP_API_URL}assets/img/explore/Lighter_Glass.jpg`} alt="" width={438} height={324} />
                                    </p>
                                    <h2>Increasing profits with the perfect pour</h2>
                                    <p>
                                        Precision pour spouts&nbsp;transmit real-time data
                                        tracking&nbsp;mimicking free pours,&nbsp;saving you thousands of
                                        dollars&nbsp;in liquor loss and unrecorded sales.
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="col-50363401" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-326710729" className="text landing-content">
                                    <h3 style={{ textAlign: "center" }}>Introducing Skyflo</h3>
                                    <p>
                                        <iframe
                                            loading="lazy"
                                            width={640}
                                            height={360}
                                            src="https://www.youtube.com/embed/k15SH9u_CRA?enablejsapi=1&origin=https%3A%2F%2Fwww.middleby.com"
                                            title="Skyflo Pro Animation"
                                            frameBorder={0}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen=""
                                            data-gtm-yt-inspected-21="true"
                                            data-gtm-yt-inspected-32955573_776="true"
                                            id={829216328}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section py-5" id="section_1868576543">
                <div className="section-content overflow-hidden relative">
                    <div className="row row-small row-full-width" id="row-1686134166">
                        <div id="col-920434451" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-1481077293" className="text landing-content">
                                    <h4 className="text-center fw-bold">
                                        An Elegant Solution to a Messy Problem
                                    </h4>
                                    <p className="text-center">
                                        <iframe
                                            loading="lazy"
                                            title="Skyflo_ an elegant solution to a messy problem"
                                            src="https://player.vimeo.com/video/895912181?dnt=1&app_id=122963"
                                            width={534}
                                            height={400}
                                            frameBorder={0}
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            data-gtm-yt-inspected-7="true"
                                            data-gtm-yt-inspected-32955573_776="true"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="col-993686473" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-4067343443" className="text landing-content">
                                    <h4 className="text-center fw-bold">
                                        Customizable Smart Pour Spouts
                                    </h4>
                                    <p className="text-center">
                                        <iframe
                                            loading="lazy"
                                            title="Skyflo_ fully customizable smart pour spouts"
                                            src="https://player.vimeo.com/video/895899904?dnt=1&app_id=122963"
                                            width={534}
                                            height={400}
                                            frameBorder={0}
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            data-gtm-yt-inspected-7="true"
                                            data-gtm-yt-inspected-32955573_776="true"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg mt-5">
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-6`}>
                                <iframe className="w-100"
                                    loading="lazy"
                                    height={300}
                                    src="https://www.youtube.com/embed/C8Bl48NPeoc?enablejsapi=1&origin=https%3A%2F%2Fwww.middleby.com"
                                    title="FryBot™ overview video"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen="false"
                                    data-gtm-yt-inspected-7="true"
                                    data-gtm-yt-inspected-32955573_776="true"
                                    id={613515404}
                                />
                            </div>

                            <div className="col-md-6">
                                <h3 className="mb-3 fw-bold">Kitchen Automation is Here</h3>
                                <p>
                                    Meet FryBot™, fully automated technology to streamline the
                                    frying process. Improving speed of service, enhancing labor
                                    efficiency, improving quality, safety and savings, the future of
                                    restaurant is evolving. FryBot™ improves your throughput,
                                    increases your yields, allowing for higher profits, consistent
                                    quality and consistent performance. Employees can focus on more
                                    important and impactful tasks when FryBot™ is added to the team.
                                    Always on, always delicious.
                                </p>
                                <p>
                                    FryBot™ is capturing all usage data and storing it in the cloud
                                    which is accessible through the web app. FryBot™ gathers data on
                                    fry time, frying period, amount of food, type of food and oil
                                    temperature. This data is used for learning demand and inventory
                                    needs for a given operation.
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="section_469452437">
                <div className="section-content container overflow-hidden relative">
                    <div className="row" id="row-327931538">
                        <div id="col-389089058" className="col small-12 large-12">
                            <div id="text-2674622049" className="text-center text-white">
                                <p>
                                    <iframe
                                        loading="lazy"
                                        width={560}
                                        height={315}
                                        src="https://www.youtube.com/embed/Umv8HLiHjoA?enablejsapi=1&origin=https%3A%2F%2Fwww.middleby.com"
                                        title="PizzaBot overview"
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        data-gtm-yt-inspected-7="true"
                                        data-gtm-yt-inspected-32955573_776="true"
                                        id={752422974}
                                    />
                                </p>
                                <h2 className="mt-5">Find the IOT Solutions that Middleby is cooking up!</h2>
                                <p>
                                    With a number of innovative technologies designed to optimize
                                    your commercial kitchen, we're here to help you get started.
                                </p>
                                <p>
                                    <span
                                        id="hs-cta-wrapper-f209cd19-575b-4537-8e5f-5fb7828fde3d"
                                        className="hs-cta-wrapper"
                                    >
                                        <span
                                            id="hs-cta-f209cd19-575b-4537-8e5f-5fb7828fde3d"
                                            className="hs-cta-node hs-cta-f209cd19-575b-4537-8e5f-5fb7828fde3d"
                                            style={{ visibility: "visible" }}
                                            data-hs-drop="true"
                                        >
                                            <a
                                                id="cta_button_7290009_1b34a80c-bc5c-4289-b555-5bd3492b0c3e"
                                                className="cta_button "
                                                href="https://solutions.middleby.com/cs/c/?cta_guid=1b34a80c-bc5c-4289-b555-5bd3492b0c3e&signature=AAH58kEj55u5m79oLsXVlXtdJG8uvtgoNQ&portal_id=7290009&placement_guid=f209cd19-575b-4537-8e5f-5fb7828fde3d&click=b9c92d21-1f2f-40eb-9410-5cf1c0041a85&redirect_url=APefjpFFN7IbNbNRY7kRiFPV1ayHjCIv6FtlRvEWcO6Yd98YXlnhPRVyT6mpksPMF5JioM8LZxpwyuIPlJk5jTvdOU3eIG6SGl1rLP0oV-IkJr2DkUPC5jhGZZaNtMAqWudOwQtYzkDgz-TFKvHpr_Miq0dZSMNAnCf9be_DWnyYefppuTkTDaZ2CDqKt5RAsWEFoEWkAaZakPTQTj1-6DJSOCvqHy_pMFqA1EH4lxhI-2a6mYHaWzwNT-jM4Vzn_YqxWv26Ib1A&hsutk=0c046c17a9eea8021257834be261bd8b&canon=https%3A%2F%2Fwww.middleby.com%2Fiot%2F&__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&__hssc=201877710.1.1715939072483&__hsfp=486857294&contentType=standard-page"
                                                target="_blank"
                                                rel="noreferrer"
                                                cta_dest_link="https://solutions.middleby.com/ghost-kitchen-assessment"
                                                title="REQUEST A QUOTE"
                                            >
                                                <a className="btn mt-3" href="products/brand/beech-oven">
                                                    <span>REQUEST A QUOTE</span>
                                                </a>
                                            </a>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section py-5" id="section_1245781412">
                <div className="section-content container overflow-hidden relative">
                    <div className="row" id="row-1373371621">
                        <div id="col-121708480" className="col small-12 large-12 text-center">
                            <p className="text-center">
                                <img
                                    decoding="async"
                                    className="aligncenter wp-image-4098 lazy-load-active"
                                    src={`${process.env.REACT_APP_API_URL}assets/img/explore/IOT-Logo-GIF.gif`}
                                    alt=""
                                    width={300}
                                    height={300}
                                />
                            </p>
                            <h2>
                                <strong>Plug into the Future of Foodservice</strong>
                            </h2>
                            <p>
                                Whether its labor challenges, space limitations, or lack of
                                consistency, connect to the leading foodservice technologies you
                                can only find with Middleby's IoT-based solutions. See some of
                                the solutions in action in the interactive&nbsp;
                                <strong>
                                    <a href="http://experience.middleby.com/ghost-kitchens"
                                        target="_blank"
                                        rel="noreferrer">
                                        Ghost Kitchen Experience
                                    </a>
                                </strong>
                                !<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5" id="section_480727592">
                <div className="section-content container overflow-hidden relative">
                    <div className="row row-large row-divided" id="row-1992955850">
                        <div id="col-1967444688" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-518498993" className="text landing-content">
                                    <h4>
                                        <img
                                            decoding="async"
                                            className="aligncenter size-medium wp-image-2926 lazy-load-active"
                                            src={`${process.env.REACT_APP_API_URL}assets/img/explore/Restaurant-and-Technology.jpg`}
                                            alt="Laptop keyboard with restaurant icon on button"
                                            width={800}
                                            height={400}
                                            sizes="(max-width: 800px) 100vw, 800px"
                                        />
                                    </h4>
                                    <h5 className="fw-bold mb-2 mt-4">The Growing Relationship Between Restaurants and Technology</h5>
                                    <p>
                                        COVID-19 has put operations and processes under the microscope,
                                        and the result has been a clearer view of how technology can help
                                        with safety and efficiency, the two things that are most critical
                                        in foodservice today.
                                    </p>
                                    <p>
                                        <span
                                            id="hs-cta-wrapper-2e651c04-4d5b-440f-8d28-1e5609aadee5"
                                            className="hs-cta-wrapper"
                                        >
                                            <span
                                                id="hs-cta-2e651c04-4d5b-440f-8d28-1e5609aadee5"
                                                className="hs-cta-node hs-cta-2e651c04-4d5b-440f-8d28-1e5609aadee5"
                                                style={{ visibility: "visible" }}
                                                data-hs-drop="true"
                                            >
                                                <a
                                                    id="cta_button_7290009_7a5e9093-eac6-43fd-ba48-0968f9c7c23c"
                                                    className="cta_button "
                                                    href="https://solutions.middleby.com/cs/c/?cta_guid=7a5e9093-eac6-43fd-ba48-0968f9c7c23c&signature=AAH58kFQMYGJ6xUOtEuzJ2zJhgo8fGzUbw&portal_id=7290009&placement_guid=2e651c04-4d5b-440f-8d28-1e5609aadee5&click=06b51c94-8438-4375-ae46-d57376f306e6&redirect_url=APefjpHsEqCvQM1GJDLjIvd9j2KiRYJV2CpS0ektEpmAxNSHiYxuzXBIy3uyaFZB7DNfqA_VPIRY4qa9NMZUsRfh0t3ROzSzb2KPoH8dgUvHrHgWrW1zP26oePdGhP9cts1nkl15ctXNHig8Qvfy8mzBqtBXJWGpoj_UWmgJ2gJLLv11CwcGUIK9uG-9lIScUPb1TssLwGSA2zB6ACOlzx7E1v0R7gJX8wXpJqsUEBRimmHBAbIhKCHB2EyjNhGeEkBY2-tLGVfxznkApiJdwB-kngOkyst-82xoGm9SBnCdXckBeDZ82vM&hsutk=0c046c17a9eea8021257834be261bd8b&canon=https%3A%2F%2Fwww.middleby.com%2Fiot%2F&__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&__hssc=201877710.1.1715939072483&__hsfp=486857294&contentType=standard-page"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{}}
                                                    cta_dest_link="https://www.middleby.com/learn/relationship-between-restaurants-and-technology/"
                                                    title="Learn More"
                                                >
                                                    <a className="btn mt-3" href="products/brand/beech-oven">
                                                        <span>Learn More</span>
                                                    </a>
                                                </a>
                                            </span>
                                        </span>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="col-283146564" className="col-md-6 col-sm-12 col-lg-6">
                            <div className="col-inner">
                                <div id="text-342213858" className="text landing-content">
                                    <h4>
                                        <img
                                            decoding="async"
                                            className="aligncenter size-medium wp-image-3543 lazy-load-active"
                                            src={`${process.env.REACT_APP_API_URL}assets/img/explore/Ghost-Kitchen-Filter.jpg`}
                                            alt="Ghost Kitchen Filter"
                                            width={800}
                                            height={450} sizes="(max-width: 800px) 100vw, 800px"
                                        />
                                    </h4>
                                    <h5 className="fw-bold mb-2 mt-4">Ghost Kitchens Are Not a Trend, They're Here to Stay</h5>
                                    <p>
                                        There are some trends we were seeing pre-COVID that are still
                                        relevant today, and in some cases, are even accelerated. One of
                                        those trends is the ghost kitchen.
                                    </p>
                                    <p>
                                        <span
                                            id="hs-cta-wrapper-7a4196d9-99ae-4d11-bcc7-07e21e708ca2"
                                            className="hs-cta-wrapper"
                                        >
                                            <span
                                                id="hs-cta-7a4196d9-99ae-4d11-bcc7-07e21e708ca2"
                                                className="hs-cta-node hs-cta-7a4196d9-99ae-4d11-bcc7-07e21e708ca2"
                                                style={{ visibility: "visible" }}
                                                data-hs-drop="true"
                                            >
                                                <a
                                                    id="cta_button_7290009_f92ba8b0-3268-429f-9e1d-581cb9b7dc40"
                                                    className="cta_button "
                                                    href="https://solutions.middleby.com/cs/c/?cta_guid=f92ba8b0-3268-429f-9e1d-581cb9b7dc40&signature=AAH58kH_7fZUaojj9WdVoXCa1XVVSIJ2IA&portal_id=7290009&placement_guid=7a4196d9-99ae-4d11-bcc7-07e21e708ca2&click=a22eb08d-118e-4a42-b250-fb725e076278&redirect_url=APefjpEAnJu-o0-qO4sXIpX0D0XhCD_GTrK140WPK1-yFsHRVPyf9nMWuZ-WxfrDR98N6jfpZfhmw2DhJoNZ4LikYBUUhZWKmWu8RhcOF55FpaszlIRv5KAhTZcD5LhXNpnjiLFCLXzbL9OmyN7A2lOqr4dePOBdXwfR_s-7CB2pmABDTJVI3_WgHmqUDzFhiARlm5PZ-UfMsqktkffb_gWo4NY2eSX-0y9bRmXfwJ6wu1hbHaXR1kw-kPfsUSGZypjRqVILRfQxTm3JRLPjpA53rWVSczCXjA&hsutk=0c046c17a9eea8021257834be261bd8b&canon=https%3A%2F%2Fwww.middleby.com%2Fiot%2F&__hstc=201877710.0c046c17a9eea8021257834be261bd8b.1715162730368.1715859397869.1715939072483.6&__hssc=201877710.1.1715939072483&__hsfp=486857294&contentType=standard-page"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{}}
                                                    cta_dest_link="https://www.middleby.com/learn/ghost-kitchen-trends-in-2020/"
                                                    title="Learn More"
                                                >
                                                    <a className="btn mt-3" href="products/brand/beech-oven">
                                                        <span>Learn More</span>
                                                    </a>
                                                </a>
                                            </span>
                                        </span>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}