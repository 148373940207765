import React, { useState, useEffect } from "react";
import Splide from '@splidejs/splide';
export default function AboutNew() {


    useEffect(() => {
        new Splide('#main-slider', {
            pagination: false,
            type: 'fade',
            drag: 'free',
            focus: 'right',
            perPage: 1,
            autoScroll: {
                speed: 1,
            },
            speed: 4e3,
            rewind: !0,
            autoplay: !0,
            interval: 6e3,
            pauseOnHover: !1,
            pauseOnFocus: !1,
        }).mount();
    }, []);
    return (
        <>



            <section className="js-block-masthead section section--masthead advanced explore-page">
                <div id="main-slider" className="splide">

                    <div className="splide__track splide-overlay">
                        <div className="splide__list h-100vh">
                            <div className="splide__slide explorepagestyle" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore1.jpg`} />
                            </div>
                            <div className="splide__slide explorepagestyle">
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/about2.jpg`} />
                            </div>
                            <div className="splide__slide explorepagestyle" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/about3.jpg`} />
                            </div>
                        </div>
                    </div>

                    <div className="content explore-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 mx-auto p-5">
                                    <div className="wysiwyg ">
                                        <h1 className="text-white pb-3 slide-text">
                                            This is Middleby Celfrost
                                        </h1>
                                        <h5 className="text-white slide-para text-justify">
                                            Middleby Celfrost is a part of USA-based Middleby Corporation, Middleby Celfrost distribute global
                                            catering equipment brands, such asLincat, Pitco, TurboChef, Sveba dahlen, Firex, Midd=kitchen,
                                            Toastmaster, Cooktek, Josper, Bakers Pride and more through a network of Indian distribution partners.
                                            Through an extensive network of distribution partners across India, Middleby Celfrost ensures that these top-tier products reach customers efficiently and reliably. With a blend of innovation, quality, and reliability, Middleby Celfrost continues to be a leader in the commercial
                                            foodservice equipment sector, catering to the diverse needs of the Indian market.
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






            <section className="js-block-masthead section section--masthead advanced">

                <div className="section--masthead__bottom fadeinsec mt-3" id="next" style={{ marginTop: '-30px' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-sm-6">
                                <div className="wysiwyg">

                                    <img className="w-100 box-shadow p-3 rounded-1 shadow automaticon  border border-primary exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/aboutcelfrost.jpg`} alt="about us image" title="about us image" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wysiwyg">



                                    <p className="pt-3 m-0 mb-2">
                                        Celfrost's state-of-the-art refrigeration products cater to the
                                        discerning needs of the<strong className="primarycolor"> hospitality, healthcare, and retail industries
                                        </strong>,
                                        with offerings such as display cabinets, storage cabinets, blast chillers, Chest freezers, Visicoolers, Professional refrigeration, ice machines, bakery & catering equipments, and other Middleby products.

                                    </p>

                                    <p className="p-0 m-0 mb-2">
                                        Leveraging the immense resources and expertise of the Middleby Corporation, Celfrost's dedication to excellence and innovation is widely acknowledged in the Indian foodservice industry.
                                        The <strong className="primarycolor"> Middleby Corporation boasts a portfolio of over 125 brands,
                                        </strong>
                                        with a strong emphasis on commercial cooking and food preparation equipment.
                                    </p>

                                    <p>
                                        In conclusion, Celfrost stands tall as a highly regarded brand in the Indian foodservice sector, recognized for its superior refrigeration solutions and unwavering commitment to customer satisfaction. As a integral part of Middleby Corporation, Celfrost is poised to continue its ascent to greater heights in the years to come.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
















            <section className="section section--call-to-action  fadeinsec" id="next2" style={{ backgroundColor: '#dd1b35' }}>
                <div className="container" style={{ padding: '80px 5px' }}>
                    <div className="title">
                        <h2 className="fontweightstyle text-white" style={{ color: 'white' }}>"Company History"</h2>
                    </div>
                    <div className="content p-0">
                        <div className="row gx-5 gy-4 m-0 align-items-center">
                            <div className="col-md-auto">
                                <p style={{ fontSize: '16px' }} className=" text-white">
                                    "For more than 100 years, the Middleby Family of food service and food preparation equipment brands have been a favourite around the world. The Middleby Corporation is a global leader in the foodservice equipment industry. The company develops, manufactures, markets and services a broad line of equipment used in the commercial foodservice, food processing, refrigeration and residential kitchen equipment industries. In 2014, The Middleby Corporation was named a Fastest Growing Company by Fortune Magazine. In addition, Middleby Celfrost is recognized in India as a preferred commercial foodservice equipment supplier with a broad line of cold side products such as professional refrigerators, cold rooms, ice machines, and freezers, marketed under the Celfrost brand. Celfrost is a leading supplier of equipment to many of the fast-growing restaurant chains and hotel groups. Middleby Celfrost has an extensive marketing and service network in India, including brand centres that we strategically leverage to support the entire Middleby portfolio of brands in the market. We are also excited about the opportunity to introduce the Celfrost brand and portfolio of products into other emerging markets utilizing Middleby's existing sales and marketing infrastructure. Middleby Celfrost hosts an in-house Service team dedicated to cater to service related queries of its customers. A dedicated toll-free helpline and online connectivity via email stays active Weekdays while the technical team is active 24x7 for Customer Service and Satisfaction. Our service has a well-defined and organised structure that is primarily Customer-Centric. Our Team aims at rendering a Hassle-Free Customer Service Experience."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>










            <section className="section section--call-to-action bg-white">
                <div className="container">
                    <div className="title">
                        <h2 className="fontweightstyle text-center">Guiding Philosophy</h2>
                        {/* <h1 className=" text-center primarycolor fw-bold">

                            GUIDING PHILOSOPHY
                            </h1> */}
                    </div>

                </div>
            </section>

            <section className={`section p-0 section1 bg-dark section--products m-0 `}>
                <div className="w-98 p-0">
                    <div className="row ">
                        <div className={`col-lg-6 section28 `}>
                            <div className="px-3  mt-4 mb-5 pb-2">
                                <h3 className="primarycolor h1 fw-bold mt-5 mb-3">
                                    Our Vision
                                </h3>

                                <h4 className="text-black mb-4 mt-5 fw-bold">
                                    Be The Preferred Organisation For All our Stakeholders

                                </h4>
                                <ul className="list-unstyled mt-3 mb-5">
                                    <li>
                                        To be the world's leading partner in creating intelligent, connected kitchens that empower our customers to achieve operational excellence and redefine the future of foodservice.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0 aboutvisionimage" style={{ backgroundImage: `url(${`${process.env.REACT_APP_API_URL}assets/img/aboutvision.png`})`, backgroundSize: 'cover' }}></div>

                    </div>
                </div>
            </section>

            <section className={`section p-0 section1 bg-grey section--products m-0 `}>
                <div className="w-98 p-0">
                    <div className="row ">
                        <div className="col-lg-6 p-0" style={{ backgroundImage: `url(${`${process.env.REACT_APP_API_URL}assets/img/aboutmission.png`})`, backgroundSize: 'cover' }}></div>
                        <div className={`col-lg-6 section28`}>
                            <div className="px-3  mt-4 mb-2 pb-2">
                                <h3 className="primarycolor h1 fw-bold">
                                    Our Mission
                                </h3>

                                <ul className="list-unstyled mt-3">
                                    <li>                  <span className="primarycolor fw-bold"> ✔   Innovation: </span>
                                        We relentlessly pursue cutting-edge technologies and develop ingenious solutions that optimize kitchen workflows and elevate foodservice experiences.

                                    </li>

                                    <li className="pt-2"> <span className="primarycolor fw-bold "> ✔ Customer Focus: </span>
                                        We are dedicated to understanding the unique needs of our customers and providing customized solutions that drive their success.
                                    </li>

                                    <li className="pt-2"> <span className="primarycolor fw-bold "> ✔ Reliability: </span>
                                        We engineer and manufacture premium-quality equipment designed for exceptional performance and long-lasting durability.
                                    </li>

                                    <li className="pt-2"> <span className="primarycolor fw-bold"> ✔ Efficiency:  </span>
                                        We empower our customers to streamline operations, maximize uptime, and minimize costs.

                                    </li>


                                    <li className="pt-2"> <span className="primarycolor fw-bold "> ✔ Sustainability:  </span>
                                        We are committed to developing eco-friendly solutions that promote responsible resource consumption and reduce our environmental footprint.

                                    </li>

                                </ul>

                                <p className=" pt-3 ">
                                    By upholding these core values, Middleby Celfrost is positioned to transform the commercial kitchen landscape, enabling our customers to thrive in a dynamic and competitive industry.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className={`section section1 bg-black text-white section--products m-0 `}>
                <div className="container">
                    <div className="wysiwyg">

                        <div className="row align-items-center justify-content-between pt-0">
                            <div className="col-md-6">
                                <h1 className="h1 fz-4 text-white fw-bold mb-4" style={{ fontSize: '4rem' }} >
                                    Middleby & Sustainability
                                </h1>
                                <p className="mb-4">
                                    Middleby has a goal of meeting the current customer
                                    needs without compromising future generations. Throughout our three business platforms sustainably is a priority in our
                                    engineering, manufacturing and day-to-day processes.
                                    Not only do we focus
                                    on how we operate, but we believe we have an obligation to innovate and deliver advanced. eco-friendly solutions to our customers. In turn, partnering with our customers, we are supporting their sustainability goals. The “triple bottom line” of economic, environmental, and social outcomes is a priority in how we conduct business.

                                </p>

                                <a className="btn" target="_blank" href="https://middlebycorporation.gcs-web.com/static-files/6afb37fb-edf9-41f8-9c67-66200d1ff8bc">
                                    Learn more
                                </a>



                            </div>

                            <div className={`col-md-5 mb-5 section23 `}>

                                <img className="pizzeriaimage p-0 mb-2 exp-img2 " src={`${process.env.REACT_APP_API_URL}assets/img/aboutustainability1.png`} />
                                <img className="pizzeriaimage p-0 mb-2 exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/aboutustainability2.png`} />
                                <img className="pizzeriaimage p-0 exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/aboutustainability3.png`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--call-to-action">
                <div className="container" style={{ padding: '40px 5px' }}>
                    <div className="title">
                        <h2 className="fontweightstyle">"E Waste Policy"</h2>
                    </div>
                    <div className="content">
                        <div className="row gx-5 gy-4 m-0 align-items-center">
                            <div className="col-md-auto">
                                <p>The programme aims to create effective awareness in various levels (of society) to reduce the adverse impact on environment and health arising out of the polluting technologies used in recycling e-waste in the unorganized sector.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div data-tf-live="01HTC500DHG6Q514TYQJWTTC71"></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
        </>




    )
}