import React from "react";

export default function Professionalrefrigeration() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Professional Refrigeration
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Safeguard Freshness and Quality with Middleby Celfrost Professional Refrigeration</h5>
                                    <p>Middleby Celfrost Professional Refrigeration is your trusted partner for ensuring the optimal storage and preservation of your ingredients. We offer a comprehensive range of high-performance refrigerators, freezers, blast chillers, and cold rooms, all meticulously designed to meet the demanding needs of professional kitchens.</p>

                                    <h5>Why Choose Middleby Celfrost Professional Refrigeration?</h5>
                                    <ul>
                                        <li><strong>Unmatched Performance:</strong> Our advanced refrigeration systems deliver exceptional temperature control, maintaining consistent and optimal environments for all your food items, from delicate produce to meats and seafood.</li>
                                        <li><strong>Superior Food Safety:</strong> We prioritize hygiene and sanitation in our designs. Easy-to-clean surfaces, antimicrobial coatings (on select models), and efficient air circulation systems minimize the risk of contamination, ensuring food safety compliance.</li>
                                        <li><strong>Unwavering Reliability:</strong> Built with durable materials and robust engineering, our professional refrigeration solutions are built to withstand the rigors of commercial kitchens, offering years of dependable performance.</li>
                                        <li><strong>Maximized Efficiency:</strong> Middleby Celfrost prioritizes energy efficiency. Our innovative technologies minimize energy consumption while maintaining optimal cooling, reducing your operating costs.</li>
                                        <li><strong>Diverse Solutions:</strong> We offer a wide variety of refrigeration solutions to cater to your specific needs. Choose from:
                                            <ul>
                                                <li>Reach-in Refrigerators and Freezers: Available in various sizes and configurations, ideal for everyday storage of food and beverages.</li>
                                                <li>Blast Chillers: Quickly cool down cooked foods, preserving freshness and extending shelf life.</li>
                                                <li>Undercounter Refrigeration: Space-saving solutions for maximizing your kitchen layout.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-171-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Benefits of Investing in Middleby Celfrost Professional Refrigeration:</h5>
                                    <ul>
                                        <li><strong>Reduced Food Spoilage:</strong> Maintain optimal storage conditions to minimize waste and maximize your profitability.</li>
                                        <li><strong>Enhanced Food Quality:</strong> Consistent temperature control preserves the taste, texture, and nutritional value of your ingredients.</li>
                                        <li><strong>Improved Workflow Efficiency:</strong> Easy-to-access and organized storage solutions allow your staff to work efficiently.</li>
                                        <li><strong>Peace of Mind:</strong> Experience the confidence that your food is stored safely and hygienically.</li>
                                    </ul>

                                    <p>Explore the Middleby Celfrost difference! Visit our website today to discover a full range of professional refrigeration solutions designed to elevate your kitchen's performance.</p>

                                    <h5>Additional Information:</h5>
                                    <ul>
                                        <li>You can highlight specific features of certain products, like self-closing doors, digital temperature control panels, or adjustable shelving.</li>
                                        <li>Consider including a call to action, encouraging visitors to contact your sales team for a personalized consultation.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}