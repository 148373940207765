import React from "react";

export default function About() {
   return (
      <>
   <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Page Not Found</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                    <div className="row text-center py-5">
                    <h1><strong>Page Not Found</strong></h1>

                    </div>

                </div>
            </section>
      </>
     )
}