import React from "react";

export default function Chestfreezer() {
    return (
        <>
                    <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                    Chest Freezer

                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify"  style={{ textAlign: "justify"}} >
                                    <p>
                                    Celfrost offers the perfect storage solution for all your freezing needs. Whether you own a restaurant, supermarket, or need extra freezer space at home, Celfrost offers two great options to choose from: hard top and glass top chest freezers.
                                    </p>

                                    <p>
                                    The hard top chest freezers are designed with durability and reliability in mind. Their robust construction and dependable cooling performance make them perfect for tough conditions. The solid lid provides excellent insulation, keeping your perishable items well-preserved and frozen. The spacious interior allows for efficient organization and easy access to your frozen goods.
                                    <br/>
                                    For a more visually appealing option, the glass top chest freezers from Celfrost are a great choice. These freezers feature a sleek and modern design with a transparent top, allowing you to showcase your frozen products while keeping them at the perfect temperature. The glass top offers easy visibility, making it effortless for customers or users to locate and select items. These freezers not only provide reliable cooling performance but also create an attractive display that enhances the aesthetics of your establishment.
                                    <br/>
                                    Both the hard top and glass top chest freezers from Celfrost come with advanced features like adjustable temperature controls, energy-efficient operation, and robust built-in handles for easy mobility. With their spacious capacity options, these chest freezers provide ample storage space to meet your specific needs.
                                    <br/>
                                    Choose Celfrost chest freezers for superior freezing capabilities, durability, and style. Upgrade your freezing solutions and ensure your products stay frozen and fresh for longer periods with Celfrost, the trusted brand in refrigeration
                                      </p>

                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br/>
                                <br/>
                                <img className="aboutimage1 transform"   src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-148-1.png`} alt="Chest Freezer image" title="Chest Freezer image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}