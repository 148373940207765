import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Landing() {

    const [BookFromData, setBookFromData] = useState({
        action: 'DemonstrationSubmit',
        inputFullName: '',
        inputNumber: '',
        inputEmail: '',
        inputCity: '',
        inputMessage: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const BookhandleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, BookFromData);

            if (response.data.status === true) {
                toast.success(response.data.message);
                setBookFromData({
                    action: 'DemonstrationSubmit',
                    inputFullName: '',
                    inputNumber: '',
                    inputEmail: '',
                    inputCity: '',
                    inputMessage: ''
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



    return (
        <>
            <ToastContainer />
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Contact Us</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="section section--contact">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <div className="wysiwyg address">
                                <h3><strong>Middleby Celfrost</strong></h3>
                                <p>
                                    <strong>Head Office</strong><br />
                                    Unit No. 713-716 , 7th Floor,<br />
                                    JMD Megapolis, Sector-48,<br />
                                    Sohna Road, Gurugram - 122018, Haryana
                                </p>
                                <p>
                                    <a href="assets/img/Map.jpg" target="_blank">
                                        <strong>Brand Center / Authorised Dealers</strong><br />
                                        <img src={`${process.env.REACT_APP_API_URL}assets/img/Map.jpg`} width="200" />
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row gx-5 gy-4">
                                <div className="wysiwyg form">
                                    <h3><strong>How can we help?</strong></h3>
                                    <div className="wpcf7 js" id="wpcf7-f248-o1" lang="en-GB" dir="ltr">
                                        <div className="screen-reader-response">
                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                            <ul></ul>
                                        </div>
                                        <form onSubmit={BookhandleSubmit} method="post" className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                                            <div className="form-group form-label-field">
                                                <p>
                                                    <label className="label" htmlFor="inputFullName">Full Name*</label>
                                                    <span className="wpcf7-form-control-wrap" data-name="inputFullName">
                                                        <input
                                                            onChange={handleChange}
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            id="inputFullName" aria-required="true"
                                                            aria-invalid="false"
                                                            value={BookFromData.inputFullName}
                                                            type="text"
                                                            name="inputFullName" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="form-group form-label-field">
                                                <p>
                                                    <label className="label" htmlFor="inputNumber">Contact Number*</label>
                                                    <span className="wpcf7-form-control-wrap" data-name="inputNumber">
                                                        <input
                                                            onChange={handleChange}
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                                            id="inputNumber"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            value={BookFromData.inputNumber}
                                                            type="tel"
                                                            name="inputNumber" />

                                                    </span>
                                                </p>
                                            </div>
                                            <div className="form-group form-label-field">
                                                <p>
                                                    <label className="label" htmlFor="inputEmail">Email Address*</label>
                                                    <span className="wpcf7-form-control-wrap" data-name="inputEmail">
                                                        <input
                                                            onChange={handleChange}
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                            id="inputEmail"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            value={BookFromData.inputEmail}
                                                            type="email"
                                                            name="inputEmail" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="form-group form-label-field">
                                                <p>
                                                    <label className="label" htmlFor="inputCity">City*</label>
                                                    <span className="wpcf7-form-control-wrap" data-name="inputCity">
                                                        <input
                                                            onChange={handleChange}
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                            id="inputCity"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            value={BookFromData.inputCity}
                                                            type="text"
                                                            name="inputCity" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="form-group form-group-textarea form-label-field">
                                                <p><label className="label" htmlFor="inputMessage">How can we help?</label>
                                                    <span className="wpcf7-form-control-wrap" data-name="inputMessage">
                                                        <textarea
                                                            onChange={handleChange}
                                                            cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"
                                                            id="inputMessage" aria-invalid="false" name="inputMessage"
                                                            value={BookFromData.inputMessage}
                                                        ></textarea>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <p>*Required
                                                    </p>
                                                </div>
                                                <div className="col-5">
                                                    <p>
                                                        <button className="btn btn--block" type="submit"><span>Send</span>
                                                            <span className="icon" style={{ height: '13px' }}>
                                                                <i className="fas fa-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="wpcf7-response-output" aria-hidden="true"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--map">
                <div className="section--map__top">
                    <div className="container">
                        <h2>
                            <span>HOW TO <strong>FIND US</strong></span>
                            <span className="icon"></span>
                        </h2>
                    </div>
                </div>
                <div className="section--map__bottom">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.0016855624344!2d77.03644931425394!3d28.419206000456498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18edf65a0135%3A0xc0cabebd7592b9a2!2sMiddleby%20Celfrost%20Innovations%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1669707237442!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </>
    )
}