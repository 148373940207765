import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css';

export default function BlogDetails() {
    const { id } = useParams();
    const [data, setData] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "BlogDetails",
                "id": id
            });
            console.log('response.data', response.data.data)
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {data && (
                <>
                    <section className="section section--masthead basic">
                        <div className="container">
                            <div className="breadcrumbs">
                                <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                                    <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><a itemProp="item" href="/"><span itemProp="name">Home</span></a>
                                        <meta itemProp="position" content="1" />
                                    </li>
                                    <li className="crumb crumb-2" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                        <span itemProp="item"><span itemProp="name">
                                        <a href={`${process.env.REACT_APP_APP_URL}/category/blogs`}>{data.type}</a></span></span>
                                        <meta itemProp="position" content="2" />
                                    </li>

                                    <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><span itemProp="item"><span itemProp="name">
                                        <strong>{data.heading}</strong></span></span>
                                        <meta itemProp="position" content="2" />
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <br /><br /><br />
                    <section className="section section--news-single">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="column column--left">
                                        <div className="wysiwyg">
                                            <h1 className="text-primary mb-4"><strong>{data.heading}</strong></h1>

                                            <p className="text-grey-dark"><strong>{data.date}</strong></p>
                                            <p dangerouslySetInnerHTML={{ __html: data.description }} />

                                                </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="column column--right">

                                        <aside className="aside">
                                            <div className="wysiwyg">
                                                <h2 className="title">News</h2>
                                            </div>

                                           <ul className="list--categories">
                                            <li><a className={`${data.type == 'blogs' ? 'is-active' : ''}`}  href={`${process.env.REACT_APP_APP_URL}/category/blogs`}><span className="icon fa fa-commenting"> </span> Blogs</a></li>
                                            <li><a className={`${data.type == 'case-studies' ? 'is-active' : ''}`}  href={`${process.env.REACT_APP_APP_URL}/category/case-studies`}> <span className="icon fa fa-search-plus"> </span>Case Studies  </a></li>
                                            <li><a className={`${data.type == 'events' ? 'is-active' : ''}`}  href={`${process.env.REACT_APP_APP_URL}/category/events`}> <span className="icon fa fa-calendar"> </span>Events</a></li>
                                            <li><a className={`${data.type == 'videos' ? 'is-active' : ''}`}  href={`${process.env.REACT_APP_APP_URL}/category/videos`}> <span className="icon fa fa-video-camera"> </span>Vvideos</a></li>
                                           </ul>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    )
}