import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './screens/header';
import Footer from './screens/footer';
import Landing from './screens/Landing';
import About from './screens/About';
import ExperienceCenter from './screens/experienceCenter';
import Culinary from './screens/Culinary';
import ContactUs from './screens/contactUs';
import Services from './screens/Services';
import ServicesNew from './screens/ServicesNew';
import Blogs from './screens/Blogs'
import BlogDetails from './screens/BlogDetails'
import Products from './screens/Products';
import SingleProduct from './screens/singleProduct';
import Careers from './screens/Careers';
import Career from './screens/career';
import Brands from './screens/Brands';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Explore from './screens/explore';
import ExploreMainPage from './screens/ExploreMainPage';
import ScrollToTop from "./screens/Componants/ScrollToTop";
import PageNotFound from "./screens/404Page";
import AboutNew from './screens/AboutNew';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<Explore />} />
          <Route path="/about-old" element={<About />} />
          <Route path="/about-us" element={<AboutNew />} />
          <Route path="/experience-center" element={<ExperienceCenter />} />
          <Route path="/culinary" element={<Culinary />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/service-old" element={<Services />} />
          <Route path="/service" element={<ServicesNew />} />
          <Route path="/our-products" element={<Landing />} />
          <Route path='/explore/:id' element={<ExploreMainPage/>} />
          <Route path='/category/:id' element={<Blogs/>} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/products/category/:category" element={<Products />} />
          <Route path="/products/brand/:brand" element={<Products />} />
          <Route path="/products/search/:search" element={<Products />} />
          <Route path="/product-detail/:id" element={<SingleProduct />} />
          <Route path="/careers-old" element={<Careers />} />
          <Route path="/careers" element={<Career />} />
          <Route path='/brand/:brand' element={<Brands />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route
            path="*"
            element={<PageNotFound />}
          />

        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
