import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import PerfectBurger from './Explore/perfectBurger';
import DiningCoffee from './Explore/DiningCoffee';
import PrivateDining from './Explore/privateDining';
import BulkCooking from './Explore/bulkCooking';
import BakeryPastry from './Explore/bakeryPastry';
import AuthenticPizzas from './Explore/authenticPizzas';
import VentlessSolutions from './Explore/ventlessSolutions';
import GreenMiddleby from './Explore/greenMiddleby';
import Automation from './Explore/Automation';
import FoodRetail from './Explore/foodRetail';
import Beverage from './Explore/Beverage';
import CloudKitchen from './Explore/cloudKitchen';
import IceCream from './Explore/iceCream';
import Marine from './Explore/Marine';
import IOT from './Explore/IOT';


export default function ExploreMainPage() {
    const { id } = useParams();

    const [data, setData] = useState(null);
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'ExplorePageProduct', pagename: id });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const renderProductCat = () => {
        if (!data || !data.data) return null;

        return data.data.map(product => (
            <div className="col-md-4 col-lg-3" key={product.id}>
                <article className="article--product-type">
                    <Link to={`/products/category/${product.slug}`}>
                        <img src={`${process.env.REACT_APP_API_URL}assets/images/category/${product.image}`} />
                        <h3>{product.name}</h3>
                    </Link>
                </article>
            </div>
        ));
    };

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);




    const categoryDescription = (id) => {
        return id && (() => {
            switch (id) {
                case 'burger':
                    return <PerfectBurger />;
                case 'dining-coffee':
                    return <DiningCoffee />;
                case 'private-dining':
                    return <PrivateDining />;
                case 'bulk-cooking':
                    return <BulkCooking />;
                case 'bakery-pastry':
                    return <BakeryPastry />;
                case 'authentic-pizzas':
                    return <AuthenticPizzas />;
                case 'ventless-solutions':
                    return <VentlessSolutions />;
                case 'green-middleby':
                    return <GreenMiddleby />;
                case 'automation':
                    return <Automation />;
                case 'food-retail':
                    return <FoodRetail />;
                case 'beverage':
                    return <Beverage />;
                case 'cloud-kitchen':
                    return <CloudKitchen />;
                case 'ice-cream':
                    return <IceCream />;
                case 'marine':
                    return <Marine />;
                case 'iot':
                    return <IOT />;

                default:
                    return;
            }
        })()
    }


    return (
        <>
           {id!=='marine' &&
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={`${process.env.REACT_APP_APP_URL}`}>
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            {/* <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href='../explore'>
                                    <span itemProp="name">
                                        <strong>Explore</strong>
                                    </span>
                                </a>
                                <meta itemProp="position" content="2" />
                            </li> */}
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>{id ? id : ''}</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
}
            {id ? categoryDescription(id) : ''}

            {data && data.data.length > 0 &&
                <>
                    <div className="section section--shadow top"></div>
                    <section className="section section--products">
                        <div className="container">
                            <div className="wysiwyg">
                                <h2 style={{ textAlign: 'center' }}><strong>
                                    <span style={{ Color: '#dd1b35' }}>RELATED PRODUCTS</span></strong></h2>
                                <p style={{ textAlign: 'center' }}>Click to explore each
                                    product range</p>
                                <hr />
                                <p style={{ textAlign: 'center' }}></p>
                            </div>
                            <div className="row g-4">
                                {renderProductCat()}
                            </div>
                        </div>
                    </section>

                </>
            }

        </>
    )
}