import React, { useEffect } from 'react'
 
const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)
 
    script.onload = () => {
      window.botpressWebChat.init({
        composerPlaceholder: "Reply to Middleby",
        botId: '<botID>',
        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        messagingUrl: 'https://messaging.botpress.cloud',
        clientId: 'd9881864-391a-404d-8169-1eb59dccb31f',
        lazySocket: true,
        botName: "Ask Middleby",
        avatarUrl: "https://www.middlebycelfrost.com/allapi/assets/img/Chef.png",
        stylesheet: "https://webchat-styler-css.botpress.app/prod/a57fb919-2ec8-430f-b9ec-95668780848f/v15928/style.css",
        frontendVersion: "v1",
        useSessionStorage: false,
        theme: "prism",
        themeColor: "#dd1b35"
        
      })
    }
  }, [])
 
  return <div id="webchat" />
}
 
export default Chatbot