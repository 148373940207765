import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function Footer() {
   const [data, setData] = useState(null);
   const [brands, setBrands] = useState(null);

   // Function to fetch data from the API for HeaderMenuCategory
   const fetchData = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'HeaderMenuCategory' });
         setData(response.data);
      } catch (error) {
         console.error('Error fetching data:', error);
      }
   };

   // Function to fetch data from the API for HeaderMenuBrands
   const fetchDataBrands = async () => {
      try {
         const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'HeaderMenuBrands' });
         setBrands(response.data);
      } catch (error) {
         console.error('Error fetching brands data:', error);
      }
   };

   // Render product names
   const renderProductNames = () => {
      if (!data || !data.data) return null;

      return data.data.map((product, index) => (
         index % 2 == true && (
            <li key={product.id} role="none">
               <a href={`${process.env.REACT_APP_APP_URL}/products/category/${product.slug}`} role="menuitem" tabIndex="-1">{product.name}</a>
            </li>
         )
      ));
   };

   const renderProductNames2 = () => {
      if (!data || !data.data) return null;

      return data.data.map((product, index) => (
         index % 2 != true && (
            <li key={product.id} role="none">
               <a href={`${process.env.REACT_APP_APP_URL}/products/category/${product.slug}`} role="menuitem" tabIndex="-1">{product.name}</a>
            </li>
         )
      ));
   };

   // Render brand names
   const renderProductBrands = () => {
      if (!brands || !brands.data) return null;

      return brands.data.map((brand,index) => (
         index % 2 != true && (
         <li key={brand.id} role="none">
            <a href={`${process.env.REACT_APP_APP_URL}/brand/${brand.slug}`} role="menuitem" tabIndex="-1">{brand.name}</a>
         </li>
         )
      ));
   };
   
   const renderProductBrands2 = () => {
      if (!brands || !brands.data) return null;

      return brands.data.map((brand,index) => (
         index % 2 == true && (
         <li key={brand.id} role="none">
            <a href={`${process.env.REACT_APP_APP_URL}/brand/${brand.slug}`} role="menuitem" tabIndex="-1">{brand.name}</a>
         </li>
         )
      ));
   };

   useEffect(() => {
      fetchData();
      fetchDataBrands();
   }, []);

   return (
      <footer id="footer">
         <div className="container">
            <div id="footer__top">
               <div className="row g-4 align-items-center">
                  <div className="col-md-auto">
                     <p id="footer__logo">
                        <img alt="Middleby Celfrost" src={`${process.env.REACT_APP_API_URL}assets/img/logo/logo-icon.png`} />
                     </p>
                  </div>
                  <div className="col-md-auto">
                     <p>Copyright &copy; 2024 Middleby Celfrost Innovations Pvt. Ltd.</p>
                  </div>
                  <div className="col-md-auto ms-auto">
                     <ul className="list--social">
                        <li><a href="https://api.whatsapp.com/send?phone=+919310007035&text=Hello" target="_blank">
                           <span className="icon ">
                              <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/whatsapp.png`} />
                           </span>
                        </a>
                        </li>
                        <li><a href="https://www.facebook.com/profile.php?id=61556774815321" target="_blank">
                           <span className="icon">
                              <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/facebook.png`} />
                           </span>
                        </a>
                        </li>
                        <li><a href="https://www.linkedin.com/company/middleby-celfrost-innovations-pvt-ltd/mycompany/?viewAsMember=true" target="_blank">
                           <span className="icon">
                              <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/linkedin.png`} />
                           </span>
                        </a>
                        </li>
                        <li><a href="https://www.instagram.com/middleby_celfrost/"
                           target="_blank">
                           <span className="icon">

                              <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/instagram.png`} />
                           </span>
                        </a>
                        </li>
                        <li>
                           <a href="https://www.youtube.com/@chefdheeraj8185"
                              target="_blank"><span
                                 className="icon">
                                 <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/youtube.png`} />
                              </span>
                           </a>
                        </li>
                        <li>
                           <a href="https://twitter.com/celfrostcares"
                              target="_blank"><span
                                 className="icon">
                                 <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/twitter.png`} />
                              </span>
                           </a>
                        </li>
                        <li>
                           <a href="mailto:sales@middlebycelfrost.com"
                              target="_blank"><span
                                 className="icon">
                                 <img className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/gmail.png`} />
                              </span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div id="footer__middle">
               <div className="row g-4">
                  <div className="col-xl-3">
                     <h3><strong>Middleby Celfrost</strong></h3>
                     <p>
                        <strong>Head Office</strong><br />
                        Unit No. 713-716 , 7th Floor,<br />
                        JMD Megapolis, Sector-48, <br />
                        Sohna Road, Gurugram - 122018, Haryana</p>
                     <p>
                        <a href={`${process.env.REACT_APP_API_URL}assets/img/Map.jpg`} target="_blank"><strong>Brand Center / Authorised Dealers</strong><br />
                           <img src={`${process.env.REACT_APP_API_URL}assets/img/Map.jpg`} width="200" />
                        </a>
                     </p>
                     <p><strong>Get in touch</strong> <br />
                        T. +91 124 4828500<br />
                        E.<a href="mailto:sales@middlebycelfrost.com"><strong>sales@middlebycelfrost.com</strong>
                        </a>
                     </p>
                  </div>
                  <div className="col-xl-9">
                     <div
                        className="row g-4 justify-content-lg-between">
                        <div className="col-md-6 col-lg-auto">
                           <h3><strong>BRANDS</strong></h3>
                           <div className="row">
                              <ul className="col-6 col-lg-auto">{renderProductBrands()}</ul>
                              <ul className="col-6 col-lg-auto">{renderProductBrands2()}</ul>
                           </div>
                        </div>
                        <div className="col-md-3 col-lg-auto">
                           <h3><strong>PRODUCTS</strong></h3>
                           <div className="row">
                              <ul className="col-6 col-lg-auto">{renderProductNames2()}</ul>
                              <ul className="col-6 col-lg-auto">{renderProductNames()}
                                 {/* <?php 
                      $getquery=mysqli_query($conn,"SELECT * FROM `middlebycelf_category` ORDER BY name DESC limit 20");
                      if (mysqli_num_rows($getquery)) {
                          while($rews=mysqli_fetch_assoc($getquery)){
                              ?>
                              <li><a href="products.php?category=<?=$rews['slug'];?>"><?=$rews['name'];?></a></a></li>
                          <?php } 
                      }
                      ?> */}
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-auto">
                           <h3><strong>Quick Links</strong></h3>
                           <nav id="nav__customer-care"
                              className="menu-footer-customer-care-container">
                              <ul
                                 id="menu-footer-customer-care"
                                 class>
                                 <li id="menu-item-64" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64">
                                    <Link to={`/careers`}>Careers</Link></li>
                                 <li id="menu-item-64"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64">
                                    <Link to={`/contact-us`}>Contact
                                       Us</Link></li>
                                 <li
                                    id="menu-item-3036"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3036">
                                    <Link to={`/service`}>Service
                                       &#038; Warranty</Link></li></ul></nav>
                           <br /><br />
                           <h3 className="displaynone">
                              <strong>Stay Connected!</strong>
                           </h3>
                           <ul className="displaynone">
                              <li>
                                 <input className="joinusinput" type="join_us_email" id="join_us_email" name="join_us_email" placeholder="Join us now" />
                                 <div id="join_us_email-err"></div>
                              </li>
                              <li className="mtfooter">
                                 <button className="joinbtnfooter" >Join us</button>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="footer__bottom">
               <div className="row g-5">
                  <div className="col-md-auto ms-auto">
                     <nav id="nav__legal"
                        className="menu-footer-legal-container"><ul
                           id="menu-footer-legal" class>

                           <li
                              id="menu-item-68"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-68">
                              <Link rel="privacy-policy"
                                 to={`/privacy-policy`}>Privacy
                                 Statement</Link></li>
                        </ul></nav>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   )
}