import React, { useState, useEffect,useRef } from "react";
import './Explore.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ServicesNew() {





    const [sectionVisibility, setSectionVisibility] = useState({});

    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Define an array of section classes
            const sectionClasses = ['.section1', '.section2', '.section3', '.section4', '.section5', '.section6']; // Add more sections as needed

            // Calculate positions for each section
            sectionClasses.forEach((sectionClass, index) => {
                const sectionElement = document.querySelector(sectionClass);
                if (sectionElement) {
                    const sectionPosition = sectionElement.getBoundingClientRect().top;
                    sectionPositions[index + 1] = sectionPosition < windowHeight;
                }
            });

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [modalVisible, setModalVisible] = useState(false);
    const [tigglevideo, settigglevideo] = useState(null);

    const OpenTingleModal = (video) => {
        if (video) {
            setModalVisible(true);
            settigglevideo(video)

        } else {
            setModalVisible(false);
        }
    }



    const [BookFromData, setBookFromData] = useState({
        action: 'serviceSubmit',
        companyname: '',
        name: '',
        email: '',
        phone: '',
        model: '',
        serial: '',
        datainstalled: '',
        requesttype: '',
        description: '',
        picture: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
         if (name === "picture") {
            if (files) {
            const images = e.currentTarget.files[0];
            setBookFromData({
                ...BookFromData,
                picture: images
            });
        }
        }else{
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));

        }
    };

    const toastId = useRef(null)
    const BookhandleSubmit = async (e) => {
        toastId.current =toast.loading("Please Wait...");
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('action', 'serviceSubmit');
            formData.append('companyname', BookFromData.companyname);
            formData.append('name', BookFromData.name);
            formData.append('email', BookFromData.email);
            formData.append('phone', BookFromData.phone);
            formData.append('model', BookFromData.model);
            formData.append('serial', BookFromData.serial);
            formData.append('datainstalled', BookFromData.datainstalled);
            formData.append('requesttype', BookFromData.requesttype);
            formData.append('description', BookFromData.description);
            formData.append('image', BookFromData.picture);

            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === true) {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "success",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
                setBookFromData({
                    action: 'serviceSubmit',
                    companyname: '',
                    name: '',
                    email: '',
                    phone: '',
                    model: '',
                    serial: '',
                    datainstalled: '',
                    requesttype: '',
                    description: '',
                    picture: ''
                });
            } else {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "error",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



    return (
        <>


<ToastContainer />
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList" className="text-white">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Services </strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section className={`js-block-masthead section1 section section--masthead advanced bg-sliderservices ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`} style={{ marginTop: '-150px' }}>

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="wysiwyg bg-itm text-center">
                                <h1 className="text-white fs-3">
                                    Middleby Celfrost Service: Keeping Your Equipment Running Smoothly
                                </h1>
                                <p className="text-white text-justify pt-3 fs-1-2">
                                    At Middleby Celfrost, we understand that reliable equipment is crucial to your business success. That's why we offer a comprehensive range of services to ensure your refrigeration solutions operate at peak performance throughout their lifespan. Our dedicated service team is here to support you every step of the way, from installation to ongoing maintenance and repair.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className={`section section2 section--products mx-0 bg-white ${sectionVisibility[2] ? 'fade-in' : 'fade-out'}`}>


                <div className="container">
                    <div className="mb-5 pb-2">

                        <h1 className="primarycolor text-center fw-bold">
                            Services Offered
                        </h1>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-rocket"></i>
                                </span>
                                <p className="mt-3 pb-4 mb-3"><strong>
                                    Installation and Startup </strong>
                                    &nbsp;    Our experienced technicians will ensure your Middleby Celfrost equipment is properly installed and configured to meet your specific needs.
                                    They will provide guidance on operation and answer any initial questions you may have.
                                </p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-users"></i>
                                </span>
                                <p className="mt-3"><strong>
                                    Preventative Maintenance: </strong>
                                    &nbsp;   Regular preventative maintenance helps identify and address potential issues before they become major problems. We offer a variety of preventative maintenance plans to suit your equipment and budget, helping to maximize efficiency and extend the lifespan of your investment.
                                </p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-headphones-alt"></i>
                                </span>
                                <p className="mt-3  pb-4 "><strong>
                                    Technical Support: </strong>
                                    &nbsp;   Our knowledgeable technical support team is available to answer your questions and troubleshoot any issues you may encounter with your Middleby Celfrost equipment. You can reach us by phone, email, or through our convenient online service portal.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-screwdriver-wrench"></i>
                                </span>
                                <p className="mt-3">
                                    <strong>
                                        Parts and Repair:
                                    </strong>
                                    &nbsp; We stock a wide range of genuine Middleby Celfrost parts to ensure timely repairs and minimize downtime. Our factory-trained technicians are highly skilled in repairing all Middleby Celfrost equipment, getting your operations back up and running quickly.

                                </p>
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6">
                            <div className="item item-shadow ">
                                <span className="icon text-white">
                                    <i className="fat fa-user"></i>
                                </span>
                                <p className="mt-3 pb-4">
                                    <strong>
                                        Warranty Support :
                                    </strong>
                                    &nbsp; All Middleby Celfrost equipment comes with a standard warranty. During this period, we will repair or replace any faulty components free of charge. Refer to your warranty documents for specific details and coverage.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="space-top space-extra-bottom background-image py-5" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-bg-1-1.png&quot;)' }}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8 col-xl-6"><br />
                            <div className="title-area">
                                <h1 className="sec-subtitle primarycolor fontsizelrg">Finding the Service You Need:</h1>
                            </div><br /><br />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-500">
                                <div className="service-bg background-image" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)' }}>
                                </div>
                                <div className="service-icon">
                                    <img src={`${process.env.REACT_APP_API_URL}assets/img/maintaince.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Installation and Startup</h3>
                                <p className="service-text">Please refer to the installation manual included with your equipment. If you require assistance, contact your Middleby Celfrost sales representative.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-500">
                                <div className="service-bg background-image" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)' }}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/easytoreach.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Preventative Maintenance:</h3>
                                <p className="service-text">Contact your Middleby Celfrost sales representative to discuss preventative maintenance plans and schedule your first service.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-500">
                                <div className="service-bg background-image" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)' }}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/presence.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Technical Support:</h3>
                                <p className="service-text"><strong>Phone: </strong>Call us at <span className="primarycolor">1800-102-1980 </span>during our business hours.</p>
                                <p className="service-text"><strong>Email: </strong>Send your questions to <span className="primarycolor">service.india@middlebycelfrost.com</span>. We strive to respond to all email inquiries within 24 hours</p>
                                <p className="service-text"><strong>Online Service Portal: </strong>Create an account on our online service portal to submit service requests, track the status of existing requests, and access product resources.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-500">
                                <div className="service-bg background-image" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)' }}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/breakdownservice.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Parts and Repair:</h3>
                                <p className="service-text">Middleby Celfrost service network ensure that your equipment <br />performs in optimal operating condition while maintaining its highest productivity.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="service-style1 h-500">
                                <div className="service-bg background-image" style={{ backgroundImage: 'url(&quot;assets/img/bg/sr-box-bg-1.jpg&quot;)' }}>
                                </div>
                                <div className="service-icon"><img src={`${process.env.REACT_APP_API_URL}assets/img/installation.png`} alt="Features" />
                                </div>
                                <h3 className="service-title h5 padding fonttitle">Warranty Support:</h3>
                                <p className="service-text">We provide Installation & Commissioning for our equipment where necessary.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className={`section section4 p-0  bg-white section--products m-0   ${sectionVisibility[4] ? 'fade-in' : 'fade-out'}`} style={{ marginTop: '-50px' }}>
                <div className="w-98 p-0">
                    <div className="row ">
                        <div className="col-lg-6 bg-customercareserviceimg">
                        </div>
                        <div className="col-lg-6  pb-5  ">
                            <div className="px-3  mt-4 mb-2 pb-2">
                                <h4 className="primarycolor text-left  fw-bold pb-3">
                                    Customer Care and Service
                                </h4>
                                <p className="fs-1-2    lh-base">
                                    Our commitment to customer care goes beyond providing exceptional service. We are here to be a trusted partner in your success. Our knowledgeable sales representatives can assist you in selecting the ideal equipment for your needs, offering expert advice and ensuring a smooth buying experience.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className={`section section5  p-0  bg-black text-white section--products m-0  ${sectionVisibility[5] ? 'fade-in-up' : 'fade-out'}`} style={{ marginTop: '-50px' }}>



                <div className="w-98 p-0">


                    <div className="row ">

                        <div className="col-lg-6 pb-5 mb-5">
                            <div className="px-4 mb-2 mt-5 pb-2">
                                <h4 className="text-white text-left  fw-bold pb-3">
                                    Warranty Information
                                </h4>

                                <p className="fs-1-2  text-white lh-base">
                                    Please refer to the warranty documents included with your Middleby Celfrost equipment for detailed information on coverage, terms, and limitations. These documents outline the standard warranty provided with your specific product. For any warranty-related questions, please contact your Middleby Celfrost sales representative.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-6 p-0 bg-customercareserviceimg2 " >



                        </div>


                    </div>
                </div>
            </section>

            <section className="section section--call-to-action">
                <div className="container">
                    <div className="title"><h2>Watch Middleby Featured Videos</h2></div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/15CeAvIGd1E?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content">
                                                <h2>CiBO+ Cleaning</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/eIcmOVleGMk?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-1.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content">
                                                <h2>Varimixer - ERGO Serie</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/HfC9FH5lejc?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-2.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content">
                                                <h2>How fun is cleaning?</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/LGV1CTz9K8Q?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-3.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content h-155">
                                                <h2>Pitco Solstice Fryer Weekly Preventative Maintenance</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/Wg-gtMlS8ns?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-4.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content h-155">
                                                <h2>Quarterly TurboChef Bullet Cleaning & Maintenance Tips</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/Zl-Zs8ksgWo?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-5.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content h-155">
                                                <h2>Daily Cleaning & Maintenance</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4 py-3" >
                            <article className="article article--news article--videos">
                                <a target="_blank" onClick={() => OpenTingleModal("https://www.youtube.com/embed/DHuyZY3Rl98?si=HQYbrG7c_0VOUYQb")} >
                                    <div className="inner video-section">
                                        <div className="top" style={{ position: 'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/hqdefault-6.jpg?1715321860)` }}>
                                            <div className="youtube-absolute">
                                                <i className="fad fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="content">
                                                <h2>Perfect Fry Daily Cleaning</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                    </div>
                </div>
            </section>



            <section className="section6 contact-form-area  pt-5 mt-5 pb-5 mb-5 " style={{ marginTop: '-50px' }}>

                <div className={`container  ${sectionVisibility[6] ? 'fade-in-up' : 'fade-out'}`} >

                    <div className="row">
                        <div className="col-xl-10 col-lg-10 px-5 pt-5 mt-4 rounded-1 m-auto text-center box-shadow">


                            <strong className="car-title primarycolor">
                                Have a question or need service?
                            </strong>
                            <p className="text-center pt-2 pb-2">
                                Please complete the following form to submit a service request or technical support inquiry.
                            </p>
                            <h3 className="pt-3 primarycolor text-center  pb-3 fw-bold">
                                Customer Support Form
                            </h3>

                            <div className="contact-form pt-3">
                                <form id="contact-form"   onSubmit={BookhandleSubmit} name="contact_form" className="default-form2" method="post">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text"
                                                 onChange={handleChange}
                                                  className="servicepageinput1" name="companyname" placeholder="Company Name" required="" />
                                            </div>

                                            <div className="input-box">
                                                <input type="text"  onChange={handleChange} className="servicepageinput1" name="email" placeholder="Email" required="" />


                                            </div>


                                            <div className="input-box">

                                                <input type="text" onChange={handleChange} className="servicepageinput1" name="model" placeholder="Model Number" required="" />

                                            </div>

                                            <div className="input-box">
                                                <input type="text" onChange={handleChange} name="datainstalled" placeholder="Date Installed"
                                                    required="" />
                                            </div>


                                            <div className="input-box">
                                                <input type="text" onChange={handleChange} className="servicepageinput1" name="description" placeholder="Description of Issue:"
                                                    required="" />
                                            </div>




                                        </div>

                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text" onChange={handleChange} className="servicepageinput1" name="name" placeholder="Name"
                                                    required="" />
                                            </div>
                                            <div className="input-box">
                                                <input type="text"
 onChange={handleChange}
                                                    name="phone"

                                                    placeholder="Phone" />
                                            </div>

                                            <div className="input-box">
                                                <input type="text"  onChange={handleChange}className="servicepageinput1" name="serial" placeholder="Serial Number"
                                                    required="" />
                                            </div>

                                            <div className="input-box">

                                                <select className="servicepageinput " name="requesttype" required="" onChange={handleChange}>
                                                    <option value="">Service Request Type</option>
                                                    <option value="Installation">Installation Support</option>
                                                    <option value="Preventative"> Preventative Maintenance Inquiry </option>
                                                    <option value="Technical"> Technical Support Inquiry  </option>
                                                    <option value="Parts Request"> Parts Request   </option>
                                                    <option value="Warranty"> Warranty Claim   </option>
                                                    <option value="Other"> Other   </option>
                                                </select>
                                            </div>

                                            <div className="input-box">
                                                <input type="file" onChange={handleChange} className="servicepageinput " name="picture" accept="image/*, .pdf" required="" />

                                            </div>




                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="button-box text-center">
                                                <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                                <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                    <span className="txt">
                                                        Submit
                                                    </span>
                                                </button>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className={`tingle-modal tingle-modal--overflow ${modalVisible ? 'tingle-modal--visible' : ''}`} id="tingle-modal23">
                <button type="button" className="tingle-modal__close" id="tingle-modal__close23" onClick={() => OpenTingleModal()}>
                    <span className="tingle-modal__closeIcon">
                    </span>
                    <span className="tingle-modal__closeLabel">Close</span>
                </button>
                <div className="tingle-modal-box">
                    <div className="tingle-modal-box__content">
                        <iframe src={tigglevideo} title="Middleby" className="video-iframe"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}