import React from "react";

export default function Microwaves() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                        Microwaves Oven
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Unleash Culinary Convenience with Middleby Celfrost Microwave Ovens</h5>
                                    <p className="col-10">Middleby Celfrost, encompassing both Celfrost and Toastmaster brands, offers a comprehensive line of high-performance microwave ovens designed to meet the diverse needs of busy kitchens. From countertop models to powerful commercial options, our microwaves provide efficient and reliable heating solutions for any application.</p>
                                </p>
                            </div>
                        </div>
                        
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 m-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-90-1.png`} alt="microwave image" title="microwave image" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Key Features:</h5>
                                    <ul>
                                        <li><strong>Fast and Even Cooking:</strong> Enjoy rapid heating with innovative technology that ensures consistent results, whether you're reheating leftovers or defrosting frozen food.</li>
                                        <li><strong>Versatile Functionality:</strong> Many models offer a variety of settings beyond simple reheating, including defrosting, power levels, and even automatic cooking programs for convenient meal preparation.</li>
                                        <li><strong>Durable Construction:</strong> Built to withstand the demands of commercial kitchens, Middleby Celfrost microwave ovens feature high-quality materials and robust designs for long-lasting performance.</li>
                                        <li><strong>User-Friendly Controls:</strong> Simple and intuitive controls make them easy to operate for staff of all experience levels.</li>
                                        <li><strong>Space-Saving Designs:</strong> Choose from countertop models perfect for cafes or back-of-house operations to powerful built-in options that seamlessly integrate into your kitchen layout.</li>
                                    </ul>

                                    <h5>Benefits:</h5>
                                    <ul>
                                        <li><strong>Increased Efficiency:</strong> Reduce cooking times and streamline meal preparation, allowing your staff to focus on other tasks.</li>
                                        <li><strong>Improved Productivity:</strong> Microwave ovens offer a quick and convenient way to reheat dishes, prepare sides, or defrost ingredients, keeping your operation running smoothly.</li>
                                        <li><strong>Enhanced Customer Satisfaction:</strong> Ensure fast and consistent food service with reliable heating capabilities.</li>
                                        <li><strong>Variety of Applications:</strong> From reheating soups and sauces to perfectly defrosting meats and vegetables, Middleby Celfrost microwave ovens offer a versatile solution for your kitchen.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="foodimage" src={`${process.env.REACT_APP_API_URL}assets/img/related/breads/shutterstock_55527706-min.jpg`} alt="Food image" title="Food image" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-6">
                            <div className="wysiwyg">
                                <h5>Finding the Perfect Microwave:</h5>
                                <p>Our extensive range of microwave ovens caters to a variety of needs, encompassing brands like Celfrost and Toastmaster. Choose from:</p>
                                <ul>
                                    <li><strong>Countertop Microwaves:</strong> Perfect for cafes, break rooms, or back-of-house operations requiring a compact and convenient solution.</li>
                                    <li><strong>Commercial Microwaves:</strong> High-powered options ideal for high-volume kitchens, restaurants, and foodservice establishments.</li>
                                    <li><strong>Built-In Microwaves:</strong> Integrate seamlessly into your existing kitchen layout for a sleek and professional look.</li>
                                </ul>

                                <p>Discover the Middleby Celfrost difference. Explore our microwave oven selection today and find the perfect fit for your kitchen!</p>

                                <h5>Additional Information:</h5>
                                <ul>
                                    <li>You can mention specific features of some models, like digital displays, sensor cooking, or interior cavity size.</li>
                                    <li>Consider including a link to a product page where customers can explore specific models in more detail.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}