import React from "react";

export default function Proofers() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Proofers
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">

                                    <p className="col-10">
                                        We offer a comprehensive range of proofing solutions,
                                        including proofer units from trusted brands like
                                        Toastmaster and Sveba Dahlen, as well as
                                        the option for customized large proofing rooms.

                                    </p>

                                </p>

                                <p className="pt-4 text-justify">

                                    <h1>Toastmaster and Sveba Dahlen Proofer Units:
                                    </h1>


                                    <p className="col-10">

                                        Our selection of proofer units from Toastmaster and Sveba Dahlen represents excellence in precision and performance. These proofer units are designed to maintain the ideal temperature and humidity levels, ensuring that your dough rises perfectly every time. Whether you're a bakery, a pizzeria, or a pastry shop, our proofer units will help you achieve consistent, high-quality results.

                                    </p>


                                </p>





                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-380-1.png`} alt="Proofers image" title="Proofers image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">


                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-127-1.png`} alt="Proofers image" title="Proofers image" />
                            </div>
                        </div>


                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">


                                <p className="pt-4 text-justify">


                                    <h1> Customized Large Proofing Rooms:
                                    </h1>
                                    <p className="col-12">
                                        For larger-scale operations and specialized requirements, we also offer customized large proofing rooms. These rooms are tailored to your unique needs, accommodating the volume and specific conditions necessary for your dough proofing process. We work closely with you to ensure that your custom proofing room is designed and built to perfection. For more details please contact us or visit cold room page.



                                    </p>


                                    <p className="col-12">
                                        At Middleby Celfrost, we recognize that the journey to perfect baked goods begins with precise dough proofing. Our proofing solutions, from trusted brands and custom options, provide the quality, performance, and reliability you need to achieve exceptional results.

                                        <br />
                                        Choose Middleby Celfrost for your dough proofing needs, and experience the art of baking at its finest.

                                    </p>
                                </p>









                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}