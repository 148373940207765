import React from "react";

export default function Deckovens() {
    return (
        <>
            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Deck Ovens
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <h5>Innovative Insulation and Heating Elements</h5>
                                    <p>Designed for comfort and energy efficiency, our deck ovens boast effective insulation. Heating elements placed strategically inside the all-welded oven regulate top, bottom, and front heating uniformly. The integrated power guard monitors and controls power output, ensuring optimal oven performance.</p>

                                    <h5>Flexibility Redefined, Endless Possibilities</h5>

                                    <p><strong>Stone Sole and Optional Steam</strong></p>

                                    <p>Middleby Celfrost's deck ovens are equipped with a stone sole (optional) and optional steam functionality, providing unparalleled flexibility. From professional sourdough bread to delicate pastries, these ovens empower you to explore endless possibilities in baking, all at a reasonable price.</p>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 " src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-56-1.png`} alt="Deck Ovens  image" title="Deck Ovens  image" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1 " src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-416-1.png`} alt="Deck Ovens  image" title="Deck Ovens  image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <p className="pt-4 text-justify">
                                    <h5>Explore a World of Brands</h5>
                                    <p><strong>Notable Brands at Your Fingertips</strong></p>

                                    <p>Explore a distinguished range of deck ovens featuring globally acclaimed brands such as Sveba Dahlen, Midd=Kitchen, Toastmaster, Bakers Pride, Blodgett, and Celfrost. Our team is ready to assist you in selecting the perfect oven that aligns with your unique baking needs.</p>

                                    <p>Elevate your baking endeavors with Middleby Celfrost's exceptional deck ovens—where innovation seamlessly meets reliability.</p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}