import React, { useState, useEffect } from "react";

export default function Automation() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <section className={`section section1 bg-white section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="w-98 px-2">
                    <div className="row">
                        {/* <div className="col-lg-6"> */}
                        <div className={`col-lg-6 section27`}>
                            <img className="w-100 box-shadow p-3 rounded-1 shadow automaticon  border border-primary exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/explore/automation-02.jpg`} />

                        </div>
                        <div className="col-lg-6">
                            <div className="px-2 ">

                                <p className="pt-2 pb-3">Middleby Celfrost understands the pressure of today's foodservice industry. Labor shortages and rising costs demand smarter solutions. That's where Middleby automation comes in.</p>
                                <h6 className="fw-bold mt-2 lh-base mb-3">
                                    We offer a comprehensive approach to automating your kitchen
                                    workflow, from ingredient handling to cooking and cleaning.
                                    Imagine:
                                </h6>

                                <ul className="list-unstyled mt-2">
                                    <li ><span className="primarycolor fw-bold"> ✔  Reduced Labor Costs:</span>  Free up your team for higher-value tasks.</li>
                                    <li className="pt-2"> <span className="primarycolor fw-6"> ✔  Enhanced Consistency:</span> Deliver perfect results every time, regardless of operator skill level.</li>
                                    <li className="pt-2"> <span className="primarycolor fw-6"> ✔  Increased Efficiency:</span> Streamline processes and maximize production output.</li>
                                    <li className="pt-2"> <span className="primarycolor fw-6"> ✔  Improved Safety:</span> Minimize human error and create a safer work environment.</li>
                                </ul>

                            </div>
                        </div>
                        <p className="fw-6 mt-3">Partner with Middleby Celfrost to create a pizzeria that stands out. Our equipment solutions are designed for efficiency, performance, and durability, empowering you to focus on what matters most - crafting delicious pizzas that leave a lasting impression.</p>

                    </div>
                </div>
            </section>
        </>
    )
}