import React from "react";

export default function Grillgriddlespanini() {
    return (
        <>
            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Grill Griddles Panini
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">

                                    <p className="col-12">
                                        Explore Culinary Delights with Our Premium Grills, Griddles, and Panini Presses
                                        Whether you’re cooking up eggs and bacon for a busy breakfast, or Sandwiches, Patty, Rolls, steaks and fish for your dinner service, a grill, griddle or chargrill is a fantastic choice for the professional caterer.
                                    </p>

                                    <p className="col-12 pt-2">
                                        At Middleby Celfrost, we redefine cooking excellence with our premium range of grills, griddles, and Panini presses. Unleash your culinary creativity with cutting-edge equipment from renowned brands like Star Manufacturing, Lincat, and Toastmaster.

                                    </p>

                                    <ul className="list-unstyled pt-3">
                                        <h2>Key Features:

                                        </h2>
                                        <li> <strong> Versatile Cooking Solutions: </strong>  From sizzling steaks to pressed Paninis, our range caters to diverse culinary needs.</li>
                                        <li> <strong> Trusted Brands: </strong>  Experience superior quality with industry-leading brands – Star Manufacturing, Lincat, and Toastmaster.</li>
                                        <li> <strong> Efficiency and Precision: </strong> Our grills incorporate state-of-the-art technology for even cooking, precise temperature control, and minimal maintenance.</li>
                                        <li> <strong> Tailored to Your Space: </strong>  Whether you run a restaurant or a cozy cafe, find the perfect fit for your kitchen..</li>

                                    </ul>

                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br/>
                                <br/>
                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-373-1.png`} alt="Grill Griddles Panini image" title="Grill Griddles Panini image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">


                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-372-1.png`} alt="Grill Griddles Panini image" title="Grill Griddles Panini image" />
                            </div>
                        </div>


                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                    Why Choose Middleby Celfrost?

                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify ">

                                    <ul className="list-unstyled pt-3">
                                        <h2>Key Features: </h2>
                                        <li> <strong> Curated Selection: </strong> Handpicked for quality assurance.</li>
                                        <li> <strong>Expert Guidance: </strong>  Our team is ready to help you find the ideal equipment.</li>
                                        <li> <strong> Competitive Prices: </strong> Enjoy premium cooking solutions without exceeding your budget.</li>

                                      </ul>
                                        <p>
                                        Enjoy your culinary journey with Middleby Celfrost. Explore our collection today for an unparalleled cooking experience.

                                        </p>

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



        </>
    )
}