import React, { useState, useEffect } from "react";

export default function CloudKitchen() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
          

            <section className={`section p-0 section1 section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>


                <div className="w-98 p-0">


                    <div className="row ">

                        <div className="col-lg-6">
                            <div className="px-4 mb-2 mt-5 pb-2">
                                <h6 className="  lh-base">
                                    In today's delivery-driven world, speed, consistency, and efficiency are king. Middleby Celfrost understands the unique needs of ghost kitchens, cloud kitchens, and takeaway operations.
                                </h6>
                                <h6 className="fw-bold mt-4 mb-4 lh-base">
                                    We offer a comprehensive suite of automation solutions designed to optimize your off-premise business.
                                    <span className="primarycolor">  Imagine:</span>
                                </h6>

                                <ul className="list-unstyled mt-3">
                                    <li>                  <span className="primarycolor "> 1.  Streamlined Order Fulfillment: </span>  Quickly and accurately prepare high volumes of food.</li>
                                    <li className="pt-3"> <span className="primarycolor "> 2. Reduced Labor Costs:  </span>  Free up staff to focus on quality control and packaging.</li>
                                    <li className="pt-3"> <span className="primarycolor "> 3. Menu Versatility: </span> Easily handle diverse menus with automated cooking and prep.</li>
                                    <li className="pt-3"> <span className="primarycolor "> 4. Enhanced Consistency:  </span> Deliver restaurant-quality meals every single time.</li>
                                </ul>

                                <p className=" pt-3 ">
                                    Middleby Celfrost is your partner in building a thriving delivery or ghost kitchen operation. Let's discuss how automation can help you meet the growing demand for convenient, delicious food.
                                </p>

                            </div>
                        </div>
                        <div className={`col-lg-6 p-0 bg-image3 section29`}></div>
                    </div>
                </div>
            </section>
        </>
    )
}