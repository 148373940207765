import React, { useState, useEffect } from "react";

export default function PrivateDining() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <section className={`section section1 section--products mx-0 mb-0 pt-5  pb-5 bg-white ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container pt-5 ">
                    <div className="wysiwyg">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-3 ">
                                <div className="image-container">
                                    <img className="aboutimage2 p-0 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/77.jpg`} />
                                </div>
                            </div>
                            <div className={`col-md-6  section20 `}>
                                <h4 className="primarycolor fw-bold pb-2">Cultivate Culinary Art with Middleby Celfrost</h4>
                                <p>Craft unforgettable fine dining and private dining experiences. Middleby Celfrost equips you to elevate every course, from Josper charcoal-grilled steaks to perfectly baked artisan breads. Ensure food safety and quality with blast chillers and professional refrigeration. Salamanders and high-end coffee machines add a touch of artistry to both kitchen and service.</p>

                                <p className="pt-2">Partner with Middleby Celfrost to turn your vision into a reality. Our equipment empowers chefs to showcase their culinary artistry, ensuring meticulous food handling and presentation for a truly unforgettable dining experience.</p>
                            </div>
                            <div className={`col-md-3  section21 `}>

                                <div className="image-container">
                                    <img className="aboutimage2 p-0 mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/88.jpg`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


          
        </>
    )
}