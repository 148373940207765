import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Microwaves from './category/Microwaves';
import WasteStation from './category/WasteStation';
import Visicooler from './category/Visicooler';
import Ventless from './category/Ventless';
import Toasters from './category/Toasters';
import Bakerymixers from './category/Bakerymixers';
import Batchcookingbrattpans from './category/Batchcookingbrattpans';
import Chestfreezer from './category/Chestfreezer';
import Confectionaryshowcases from './category/Confectionaryshowcases';
import Convectionoven from './category/Convectionoven';
import Deckovens from './category/Deckovens';
import Foodwarmingholding from './category/Foodwarmingholding';
import Fryers from './category/Fryers';
import Grillgriddlespanini from './category/Grillgriddlespanini';
import Icemachines from './category/Icemachines';
import Inductioncooking from './category/Inductioncooking';
import Minibars from './category/Minibars';
import Proofers from './category/Proofers';
import Salamanders from './category/Salamanders';
import Speedovens from './category/Speedovens';
import Coldrooms from './category/Coldrooms';
import Bainmarie from './category/Bainmarie';
import Dishwasher from './category/Dishwasher';
import Doughhandling from './category/Doughhandling';
import Firebrickoven from './category/Firebrickoven';
import Meatslicer from './category/Meatslicer';
import Professionalrefrigeration from './category/Professionalrefrigeration';
import Softserve from './category/Softserve';


export default function Product() {
    const [data, setData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [filter, setFilter] = useState(null);

    const { category, brand, search } = useParams();
    const [selectedFilter, setSelectedFilter] = useState('');
    const constable = category ? 'category' : brand ? 'brand' : 'search';
    // Function to fetch data from the API
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "ProductByCategory",
                [constable]: category || brand || search
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getCategoryData = async (category) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}?action=CategoryDescription`, {
                id: category
            });
            setCategoryData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getFilter = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "ProductFilter",
                [constable]: category || brand || search
            });
            setFilter(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Render product names
    const renderProductNames = () => {
        if (!filter || !filter.data) return null;

        return filter.data.map(product => (
            <option value={product.name}>{product.name}</option>
        ));
    };

    const categoryDescription = (category) => {
        return category && (() => {
            switch (category) {
                case 'bain-marie':
                    return <Bainmarie />;
                case 'bakery-mixers':
                    return <Bakerymixers />;
                case 'bulk-cooking-bratt-pans':
                    return <Batchcookingbrattpans />;
                case 'chest-freezer':
                    return <Chestfreezer />;
                case 'cold-rooms':
                    return <Coldrooms />;
                case 'confectionary-showcases':
                    return <Confectionaryshowcases />;
                case 'convection-oven':
                    return <Convectionoven />;
                case 'deck-ovens':
                    return <Deckovens />;
                case 'dish-washer':
                    return <Dishwasher />;
                case 'dough-handling':
                    return <Doughhandling />;
                case 'fire-brick-ovens':
                    return <Firebrickoven />;
                case 'food-warming-holding':
                    return <Foodwarmingholding />;
                case 'fryers':
                    return <Fryers />;
                case 'grill-griddles-panini':
                    return <Grillgriddlespanini />;
                case 'ice-cube-machine':
                    return <Icemachines />;
                case 'induction-cooking':
                    return <Inductioncooking />;
                case 'meat-slicer-grinder':
                    return <Meatslicer />;
                case 'microwaves-oven':
                    return <Microwaves />;
                case 'minibars':
                    return <Minibars />;
                case 'professional-refrigeration':
                    return <Professionalrefrigeration />;
                case 'proofers':
                    return <Proofers />;
                case 'salamanders':
                    return <Salamanders />;
                case 'soft-serve-thick-shakes':
                    return <Softserve />;
                case 'high-speed-ovens':
                    return <Speedovens />;
                case 'toasters':
                    return <Toasters />;
                case 'ventless':
                    return <Ventless />;
                case 'visi-coolers-visi-freezers':
                    return <Visicooler />;
                case 'waste-station-ip-compactors':
                    return <WasteStation />;
                default:
                    return (
                        <section>
                            <div className="container">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-8 col-sm-6">
                                        <div className="wysiwyg">
                                            <h1 className="text-left" >
                                                <strong className="titleborder" >
                                                    {category.replace(/-/g, ' ')}

                                                </strong>
                                            </h1>

                                            <p className="pt-4 text-justify">

                                                {categoryData && <div
                                                    style={{ textAlign: 'justify' }}
                                                    dangerouslySetInnerHTML={{ __html: categoryData.description }} />
                                                }

                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-6">
                                        <div className="wysiwyg">
                                            {categoryData &&
                                                <img className="aboutimage1 "
                                                    src={`${process.env.REACT_APP_API_URL}assets/images/category/${categoryData.image}`}
                                                    alt={category} title={category} />
                                            }
                                        </div>
                                    </div>

                                    {/* <div className="col-md-8 col-sm-6 mb-5">
                                        <div className="text-primary text-center">
                                            <h1 style={{ textTransform: 'capitalize' }}>
                                                <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                                    {category.replace(/-/g, ' ')}
                                                </strong>
                                            </h1>
                                            <br />
                                            {categoryData &&
                                                <div className="col-md-9">
                                                    <div
                                                        style={{ textAlign: 'justify' }}
                                                        dangerouslySetInnerHTML={{ __html: categoryData.description }} />
                                                </div>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    );
            }
        })()
    }

    const renderProduct = () => {
        if (!data || !data.data) return null;
        let thisdta = data.data;
        console.log(selectedFilter)
        if (selectedFilter) { thisdta = thisdta.filter(product => product.TagName === selectedFilter) }
        return thisdta.map(product => (
            <div className={`col-md-4 col-lg-3 `}>
                <article className="article article--product">
                    <a href={product.product_link ? product.product_link : `${process.env.REACT_APP_APP_URL}/product-detail/${product.product_slug}`} target={`${product.product_link?"_blank":''}`}>
                        <div className="article--product__top">
                            <img width="300" height="338" src={`${process.env.REACT_APP_API_URL}assets/images/product/${product.id}/${product.image_path_1}`} className="attachment-product-thumb size-product-thumb wp-post-image"
                             alt={product.product_slug} decoding="async" />
                        </div>
                        <div className="article--product__middle">
                            <h2>{product.product_name}</h2>
                            <p><strong>MODEL:</strong> {product.model}</p>
                            {/* <p><strong>Product:</strong> {product.TagName}</p> */}
                            <p className='product-desc-small' dangerouslySetInnerHTML={{ __html: product.description.slice(0, 100) + '... <strong>read more</strong>' }} />
                        </div>
                        <div className="article--product__bottom">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="logo">
                                        <img width="339" height="200" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${product.TagImage}`} className="attachment-full size-full" alt="Toastmaster" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <p><strong>VIEW</strong> <span className="icon">
                                        <i className="fa fa-arrow-right"></i>
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>
                </article>
            </div>
        ));
    };

    function SearchFilter(event) {
        const { value } = event.target;
        setSelectedFilter(value)

    }

    useEffect(() => {
        fetchData();
        getFilter();
        category && getCategoryData(category);
    }, []);


    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name"><strong>Products</strong></span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                            <li className="crumb crumb-archive" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name"><strong>{category ? category.replace(/-/g, ' ') : brand ? brand.replace(/-/g, ' ') : search ? search : ''} </strong></span>
                                </span>
                                <meta itemProp="position" content="3" />
                            </li>
                        </ol>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="wysiwyg text-center">
                                {/* <h1 className="text-primary"><strong>{data.data[0].TagName}</strong></h1> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br /><br /><br />
            {category ? categoryDescription(category) : ''}
            <section className="section section--products">
                <div className="container">
                    <div className="row g-4 justify-content-center mb-5">
                        <div className="col-md-6 col-lg-3">
                            <label className="visually-hidden" htmlFor="brand">Filter
                                Brands:</label>
                            <div className="custom--select">
                                <select className="js-custom-select" name="brand" onChange={SearchFilter}>
                                    <option value="">Filter Brands</option>
                                    {renderProductNames()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        {
                            renderProduct() && renderProduct().length === 0 ? <h3>No Product Found</h3> : renderProduct()
                        }
                    </div>
                    {/* <div className="row g-4 mt-5">
                        <div className="col">
                            <p>15 results</p>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}
