import React from "react";

export default function Meatslicer() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Meat Slicer & Grinder
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Precision Meat Slicers and Grinders: A Culinary Symphony</h5>
                                    <p>Unleash Culinary Mastery with Celfrost and Globe Food Equipment: In the pursuit of culinary excellence, precision is paramount. Elevate your kitchen operations with our exceptional Meat Slicers and Grinders from two industry giants—Celfrost and Globe Food Equipment. Immerse yourself in a world where slicing meat becomes a breeze, and a myriad of models cater to your diverse culinary needs.</p>

                                    <h5>Globe Food Equipment: Masterful Meat Slicing Simplified</h5>
                                    <ul>
                                        <li><strong>Effortless Precision:</strong> Slicing meat reaches new heights of simplicity with Globe Food Equipment. Our range of meat slicers is a testament to unparalleled craftsmanship and cutting-edge technology.</li>
                                        <li><strong>Diverse Models:</strong> Choose from a selection of models, each designed to meet specific culinary demands. Whether you're slicing delicate cuts for fine dining or preparing hearty portions for a bustling kitchen, Globe Food Equipment has the perfect solution.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/images/category/886pro-342-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Celfrost: Precision and Power in Every Slice</h5>
                                    <ul>
                                        <li><strong>Innovative Design:</strong> Celfrost brings innovation to the forefront with meat slicers and grinders that redefine kitchen efficiency. Our commitment to precision and power ensures a seamless slicing experience.</li>
                                        <li><strong>Culinary Versatility:</strong> Explore the diverse capabilities of Celfrost meat slicers, catering to a spectrum of culinary applications. From fine cuts to robust slices, our models empower chefs to achieve their desired results effortlessly.</li>
                                    </ul>

                                    <h5>Why Choose Celfrost and Globe Food Equipment?</h5>
                                    <ul>
                                        <li><strong>Culinary Mastery:</strong> Our meat slicers and grinders are crafted to meet the demands of professional kitchens, allowing chefs to achieve culinary mastery with every slice.</li>
                                        <li><strong>Robust Construction:</strong> Built to last, these appliances from Celfrost and Globe Food Equipment boast durability, ensuring they withstand the rigors of commercial kitchen environments.</li>
                                        <li><strong>Precision and Power:</strong> Experience the perfect blend of precision and power, allowing you to create culinary masterpieces with ease.</li>
                                    </ul>

                                    <p>Transform your kitchen into a culinary haven with Meat Slicers and Grinders from Celfrost and Globe Food Equipment. Whether you prefer the masterful simplicity of Globe Food Equipment or the innovative design of Celfrost, our appliances are your gateway to precision and power in every slice. Choose excellence, choose precision, choose culinary mastery.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}