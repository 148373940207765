import React from "react";

export default function Salamanders() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Salamanders

                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">


                                    <h1>Unleash Culinary Excellence with Lincat and Toastmaster Salamanders
                                    </h1>
                                    <p className="col-10">
                                        Discover the essence of precision grilling with our extensive range of Salamander grills, proudly presented through renowned brands: Lincat and Toastmaster. Crafted with meticulous attention, these Salamanders elevate your grilling experience, making them ideal for a diverse array of foods, from succulent steaks and burgers to chops, cutlets, toasted sandwiches, and pizzas.


                                    </p>


                                </p>


                                <p className="pt-4 text-justify">


                                    <h1>
                                        Lincat Salamanders - Phoenix and Opus Series

                                    </h1>
                                    <p className="col-10">
                                    Step into a world of rapid heating prowess with our Lincat Salamanders from the Phoenix and Opus series. These grills impress with their quick heating capability, reaching optimal temperatures from cold in just 8 seconds! The chrome-plated units not only contribute to reduced energy costs but also create a more comfortable working environment. Explore our range, including 1/1GN compatible models, adding convenience tailored to your specific needs.


                                    </p>


                                </p>


                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br/><br/><br/><br/><br/>
                            <img className="aboutimage1 " src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-112-1.png`} alt="Salamanders image" title="Salamanders image" />

                             </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">

                    <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                            <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-387-1.png`} alt="Salamanders image" title="Salamanders image" />
                            </div>
                        </div>


                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">


                                <p className="pt-4 text-justify">


                                    <h1>
                                    Toastmaster Salamanders - Performance Redefined

                                    </h1>
                                    <p className="col-12">
                                    Delve into the innovative design and superior functionality of our Toastmaster Salamanders. Precision-crafted, these grills are perfect for a variety of culinary applications, ensuring consistent and efficient grilling results. Let Toastmaster be your culinary companion, where performance seamlessly meets reliability.


                                    </p>


                                </p>


                                <p className="pt-4 text-justify">


                                    <h1>
                                    Tailored Solutions for Your Business


                                    </h1>
                                    <p className="col-12">
                                    No matter your culinary aspirations, we offer a Salamander grill perfectly suited to your business needs. Whether you operate a bustling restaurant, a cozy cafe, or a high-capacity kitchen, our range provides solutions aligned with your unique requirements.

          <br/>
          Experience a new standard of grilling satisfaction with Middleby Celfrost. Explore our Salamander grills from Lincat and Toastmaster, and let your culinary creations shine



                                    </p>


                                </p>


                            </div>
                        </div>



                    </div>
                </div>
            </section>




        </>
    )
}