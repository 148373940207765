import React from "react";

export default function Inductioncooking() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Induction Cooking
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">

                                    <p>
                                        Welcome to a new era of cooking excellence. At Middleby Celfrost, we bring you the pinnacle of induction cooking solutions, featuring the renowned CookTek brand from the USA and the value-driven options from Midd-Kitchen, known for its German components and engineering.
                                    </p>

                                </p>

                                <p className="pt-4 text-justify">

                                    <h1>CookTek Induction Cooking - A Mark of Excellence:</h1>

                                    <p>
                                        Our premium induction solutions from CookTek are the choice of five-star hotels, acclaimed for their robust performance. From high-end kitchens to lavish buffet counters, CookTek's induction technology redefines cooking precision. Experience rapid heating, precise temperature control, and energy efficiency like never before. CookTek also introduces the remarkable "Incogneeto" invisible warmers, seamlessly blending style and functionality to keep your culinary creations warm and inviting.   </p>

                                </p>





                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br/>
                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-334-1.png`} alt="Induction Cooking image" title="Induction Cooking image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-106-1.png`} alt="Induction Cooking image" title="Induction Cooking image" />
                            </div>
                        </div>


                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Midd-Kitchen - Affordable Quality with German Precision:
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">

                                    <p className="col-12">
                                        Midd-Kitchen - Affordable Quality with German Precision:
                                        For those seeking exceptional value in induction cooking, Midd-Kitchen delivers with its
                                        German-engineered controls. Our range of Midd-Kitchen induction solutions brings
                                        affordability without sacrificing quality. These induction cooktops are designed for
                                        versatility, durability, and efficient cooking, making them an ideal choice for various culinary settings.  </p>


                                    <p className="col-12">
                                        Middleby Celfrost understands that your kitchen deserves the best, whether you're operating a fine dining restaurant, a bustling buffet, or a value-conscious establishment. Our induction cooking solutions provide the performance and reliability you can count on.
                                    </p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}