import React from "react";

export default function Firebrickoven() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Fire Brick Ovens
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Experience the Superior Culinary Craft with Australian Beech Oven: A Symphony of Stone Hearth Baking</h5>
                                    <p>Unleash Culinary Brilliance with Elegant Fire Brick Ovens. Ignite your passion for cooking with the Beech Oven, a masterpiece in stone hearth style baking. Crafted to perfection, these fire brick ovens redefine the art of live cooking, adding a touch of elegance to your culinary endeavors.</p>

                                    <h5>Stone Hearth Style Baking: The Epitome of Culinary Excellence</h5>
                                    <ul>
                                        <li><strong>Unmatched Elegance:</strong> The Australian Beech Oven is not just an appliance; it's a work of art. Elevate your live cooking experience with the exquisite design that adds an unmatched touch of elegance to your kitchen.</li>
                                        <li><strong>Stone Hearth Magic:</strong> Experience the magic of stone hearth style baking, where every dish is infused with the authentic flavors and textures that only a fire brick oven can deliver.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-328-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-6">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Versatility in Design and Size: Tailored to Your Culinary Vision</h5>
                                    <ul>
                                        <li><strong>Aesthetic Options:</strong> Choose from a variety of options, including round, square, and more. The Australian Beech Oven is not just a tool; it's a canvas for your culinary creativity, available in sizes and shapes that complement your kitchen aesthetics.</li>
                                        <li><strong>Live Cooking Spectacle:</strong> Transform your kitchen into a live cooking spectacle. Watch as these ovens, with their stone hearth baking prowess, turn each dish into a culinary masterpiece.</li>
                                    </ul>

                                    <h5>Why Choose Australian Beech Oven?</h5>
                                    <ul>
                                        <li><strong>Superior Craftsmanship:</strong> Meticulously crafted with precision, the Australian Beech Oven is a symbol of superior craftsmanship. Each detail is designed to enhance your cooking experience.</li>
                                        <li><strong>Versatile Sizes:</strong> From intimate kitchens to commercial spaces, these fire brick ovens come in various sizes, catering to your specific needs. Discover the perfect fit for your culinary vision.</li>
                                    </ul>

                                    <p>Ignite your culinary creativity, and embrace the allure of stone hearth style baking with the Australian Beech Oven. Elevate your live cooking experience, and let the elegance of these fire brick ovens become the centerpiece of your kitchen artistry. Choose excellence, choose sophistication, choose Australian Beech Oven for a culinary journey like no other.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}