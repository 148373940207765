import React from "react";

export default function Visicooler() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Visi Coolers Visi Freezers
                                    </strong>
                                </h1>
                                <br />
                                <div>
                                <h1>Celfrost Visi-Cooler and Visi Freezer: Your Gateway to Cooling Excellence</h1><br/>
                                <big>The Ultimate Cooling Experience with Celfrost's legacy of Visi-Coolers and Visi Freezers</big><br/><br/>
                                    <p style={{ textAlign: 'justify' }}>Celfrost introduces a range of cutting-edge Visi-Cooler and Visi Freezer models, meticulously crafted to meet Indian cooling needs. From retail spaces and airports to pharmacies, lounges, restaurants, hotels, coffee shops, and QSRs, our cooling solutions redefine elegance and performance.</p><br/>
                                    <strong>Diverse Models for Every Requirement</strong><br/><br/>
                                    <p>Versatile Capacities: Choose from a range of capacities, spanning from 100 to 1500 litres, ensuring that there's a perfect fit for every space and requirement. Popular in Various Sectors: Trusted and preferred, our Visi-Coolers and Visi Freezers find their place in a multitude of settings, including retail outlets, live kitchens, airports, pharmacies, lounges, restaurants, and more.</p><br/>
                                    <strong>Elegant Look with Personal Branding Canopy</strong><br/><br/>
                                    <p>Customizable Branding: Make a lasting impression with your personal branding on the elegant canopy. Transform your cooling solution into a statement piece that aligns seamlessly with your brand identity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 py-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-161-1.png`} alt="Waste Station" title="Waste Station" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1 py-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-160-1.png`} alt="Waste Station" title="Waste Station image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <div className="col-md-10">
                                    <strong>NFG Series: Visi Freezers for Ultimate Cooling</strong><br/><br/>
                                    <p>Temperature Precision: The NFG series Visi Freezers maintain a temperature range of -18°C to -22°C, ensuring the perfect preservation of items. Models like NFG 200, NFG 450, NFG 1000A, and NFG 1500 cater to various needs. Ideal for Frozen Delights: Perfect for ice creams, convenience stores, meat storage, and self-pick counters, the NFG series performs exceptionally well even in ambient temperatures below 28°C.</p><br/>
                                    <strong>FKG Series: Visi Coolers for Optimal Chilling</strong><br/><br/>
                                    <p>Cooling Excellence: The FKG series Visi Coolers maintain a temperature range of +2°C to +8°C, offering a cool haven for your perishables. Choose from single, double, or triple door options based on your spatial requirements. <br/>Ideal for Various Settings: Thriving in ambient temperatures under 32°C, the FKG series is your ideal companion for diverse settings, ensuring your products stay fresh and inviting.</p><br/>
                                    <p>Choose Celfrost Visi-Cooler and Visi Freezer for a seamless blend of elegance, performance, and reliability. Transform your cooling experience and let your brand shine with our customizable solutions.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}