import React, { useState, useEffect } from "react";

export default function BakeryPastry() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
          

            <section className={`section section1 bg-white section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>

                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fw-bold fs-2"><strong><span>
                            Bakery and Pastry Shop
                        </span></strong></h1>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h4 className="primarycolor text-center fw-bold">Sweeten Your Success: Bakery & Pastry Solutions from Middleby Celfrost</h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-5 mb-5 section22 `}>

                                {/* <div className="col-md-5 mb-5"> */}
                                <img className="aboutimage1 p-0 mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/exp-03.jpg`} />
                                <img className="aboutimage1 p-0 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/exp-04.jpg`} />
                            </div>
                            <div className="col-md-6">
                                <p>Turn your passion for pastries into a thriving business with Middleby Celfrost. We equip you to create irresistible breads, pastries, and desserts that keep customers coming back for more.</p>

                                <h2 className="text-blue fw-bold">
                                    From Batch to Beautiful:
                                </h2>
                                <ul className="pt-2">
                                    <li> <span className="fw-bold h4 primarycolor"> High-Performance Ovens</span> : Ensure consistent baking for flawless cakes, cookies, and breads.</li>
                                    <li> <span className="fw-bold h4 primarycolor"> Planetary Mixers:</span>  Handle large dough batches with ease.</li>
                                    <li> <span className="fw-bold h4 primarycolor">Proofing Cabinets </span>: Create the ideal environment for consistent dough rise.</li>
                                    <li> <span className="fw-bold h4 primarycolor"> Display Cases: </span>Showcase your delectable creations to tempt every customer.</li>
                                </ul>
                                <p className="fw-6 mt-4">Middleby Celfrost is your partner in bakery and pastry shop success. Our equipment empowers you to streamline production, guarantee quality, and create a beautiful presentation that wows your customers. Let's collaborate to make your bakery dreams a reality!</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}