import React from "react";

export default function Speedovens() {
    return (
        <>
            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Speed Ovens
                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <p>Middleby Celfrost takes your culinary artistry to the next level with our selection of high-speed ovens. We proudly offer the Lincat Cibo Plus and TurboChef, renowned for their rapid cooking technology.</p>

                                    <h5>Lincat Cibo Plus Speed Ovens:</h5>
                                    <p>For those who demand exceptional speed and versatility, Lincat Cibo Plus is the answer. This speed oven redefines efficiency and precision in cooking, whether you're baking, roasting, or grilling. With rapid cooking technology, it's the perfect companion for any fast-paced kitchen operation. Lincat Cibo plus has unique Tri-heat Technology with base temperature going up to 360°C.</p>
                                </p>

                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 py-0 px-5 m-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-38-1.png`} alt="Speed Ovens image" title="Speed Ovens image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>




            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1 py-0 px-5 m-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-40-1.png`} alt="Speed Ovens image" title="Speed Ovens image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <br/>
                                <p className="pt-4 text-justify">
                                    <h5>TurboChef Speed Ovens:</h5>
                                    <p>TurboChef is synonymous with innovation and cutting-edge technology. Our TurboChef speed ovens are designed to meet the demands of high-volume kitchens, ensuring that your dishes are cooked quickly without compromising quality. These ovens offer precision, consistency, and rapid results that are in seconds.</p>

                                    <p>At Middleby Celfrost, we understand that time is of the essence in professional kitchens or QSR. Our speed ovens offer the performance and reliability you need to prepare delicious dishes in record time.</p>

                                    <p>Choose Middleby Celfrost for quality, precision, and a cooking speed that elevates your culinary creations to new heights. Whether it's Lincat Cibo Plus or TurboChef, we've got your need for speed covered.</p>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>
    )
}