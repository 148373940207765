import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css';

export default function Blogs() {
    const { id } = useParams();

    const [data, setData] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "AllBlogs",
                "id": id ? id : 'blogs'
            });
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const renderProductCat = () => {
        if (!data) return null;

        return data.map((product, index) => (

            index === 0 && id === 'blogs' ? (

                <div className={`col-md-12 col-lg-8`} key={product.id}>
                    <article className="article article--news featured">
                        <a href={`${process.env.REACT_APP_APP_URL}/blog/${product.slug}`}>
                            <div className="inner">
                                <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/${product.image})` }}></div>

                                <div className="bottom">
                                    <div className="content">
                                        <h2>{product.heading}</h2>
                                        <div className="blogDesc">
                                            <p dangerouslySetInnerHTML={{ __html: product.description.slice(0, 200) }} />
                                            ... <strong>read more</strong>
                                        </div>
                                    </div>
                                    <div className="category">Blogs</div>
                                </div>
                                <div className="date">
                                    <span>
                                        <span className="day">{new Date(product.date).getDate()}</span>
                                        <span className="month-year">{new Date(product.date).toLocaleString('default', { month: 'short' })} {new Date(product.date).getFullYear().toString().slice(-2)}</span>

                                    </span>
                                </div>
                            </div>
                        </a>
                    </article>
                </div>
            ) :
                id === 'videos' ?
                    (
                        <div className="col-md-6 col-lg-4" key={product.id}>
                            <article className="article article--news article--videos">
                                <a href={`${product.video}`} target="_blank">
                                    <div className="inner video-section">
                                        <div className="top" style={{position:'relative', backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/${product.image})` }}>
                                        <div className="youtube-absolute">
                                            <img width={100} className="imagestyle" src={`${process.env.REACT_APP_API_URL}assets/img/yy.png`} />
                                        </div>

                                        </div>

                                        <div className="bottom">
                                            <div className="content">
                                                <h2>{product.heading}</h2>
                                            </div>
                                        </div>


                                    </div>
                                </a>
                            </article>
                        </div>
                    ) :
                    (
                        <div className="col-md-6 col-lg-4" key={product.id}>
                            <article className="article article--news">
                                <a href={`${process.env.REACT_APP_APP_URL}/blog/${product.slug}`}>
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/${product.image})` }}></div>

                                        <div className="bottom">
                                            <div className="content">
                                                <h2>{product.heading}</h2>
                                                <div className="blogDesc">
                                                    <p dangerouslySetInnerHTML={{ __html: product.description.slice(0, 170) }} />
                                                    ... <strong>read more</strong>
                                                </div>
                                            </div>
                                            <div className="category">Blogs</div>
                                        </div>
                                        <div className="date">
                                            <span>
                                                <span className="day">{new Date(product.date).getDate()}</span>
                                                <span className="month-year">{new Date(product.date).toLocaleString('default', { month: 'short' })} {new Date(product.date).getFullYear().toString().slice(-2)}</span>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                    )
        ));
    };


    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><a itemProp="item" href="/"><span itemProp="name">Home</span></a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem"><span itemProp="item"><span itemProp="name">
                                <strong>{id ? id : 'blogs'}</strong></span></span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>

            <section className="section section--page-links">
                <div className="container">
                    <div className="row gx-md-5 gy-2 align-items-center">
                        <div className="col-xl-auto">
                            <p className="logo">
                                <img alt="News" src={`${process.env.REACT_APP_API_URL}assets/img/logo-middleby-uk-news.png`} />
                            </p>
                        </div>

                        <div className="col-auto d-none d-xl-block">
                            <div className="divider"></div>
                        </div>
                        <div className="col-md-auto">
                            <a className={`${id == 'blogs' ? 'is-active' : ''}`} href={`${process.env.REACT_APP_APP_URL}/category/blogs`}>
                                <span className="icon fa fa-commenting">
                                </span>Blogs                            </a>
                        </div>
                        <div className="col-md-auto">
                            <a className={`${id == 'case-studies' ? 'is-active' : ''}`} href={`${process.env.REACT_APP_APP_URL}/category/case-studies`}>
                                <span className="icon fa fa-search-plus">

                                </span>Case Studies                            </a>
                        </div>
                        <div className="col-md-auto">
                            <a className={`${id == 'events' ? 'is-active' : ''}`} href={`${process.env.REACT_APP_APP_URL}/category/events`}>
                                <span className="icon fa fa-calendar">
                                </span>Events                            </a>
                        </div>
                        <div className="col-md-auto">
                            <a className={`${id == 'videos' ? 'is-active' : ''}`} href={`${process.env.REACT_APP_APP_URL}/category/videos`}>
                                <span className="icon fa fa-video-camera">
                                </span>Videos                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    {/* <div className="row g-0 mb-5 justify-content-end">
                        <div className="col-3">
                            <div className="custom--select">
                                <select onchange="location = this.value;" className="js-custom-select">
                                    <option value="" selected="">All</option>
                                    <option value="blog 1">Blog 1</option>
                                    <option value="blog 2">Blog 2</option>
                                    <option value="blog 3">Blog 3</option>
                                </select>
                            </div>
                        </div>
                    </div> */}
                    <div className="row g-4 pt-5">
                        {renderProductCat()}

                    </div>
                </div>
            </section>
        </>
    )
}