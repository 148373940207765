import React from "react";

export default function Bainmarie() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                        Bain Marie
                                    </strong>
                                </h1>
                                <br />
                                <p className="col-10 text-justify">
                                    <h5>Optimize Your Kitchen Efficiency with Premium Bain Marie Solutions</h5>
                                    <p>Discover precision and versatility in culinary operations with our curated selection of Bain Maries from Middleby Corporation's esteemed brands, including Celfrost, Toastmaster, Star, Lincat, APW Wyott, and Cook Tek.</p>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 py-0 m-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-397-1.jpg`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" style={{ height: "200px", width: "auto" }}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Unmatched Quality, Unrivalled Performance</h5>
                                    <ul>
                                        <li><strong>Celfrost:</strong> Embrace excellence with Celfrost's Bain Maries, designed to seamlessly integrate into any kitchen space. Enjoy precise temperature control and durable construction, ensuring your culinary creations stay at their optimal serving condition.</li>
                                        <li><strong>Toastmaster:</strong> Toastmaster brings innovation to the table with Bain Maries that prioritize functionality and ease of use. Enhance your buffet presentation and keep your dishes consistently warm with Toastmaster's reliable solutions.</li>
                                        <li><strong>Star:</strong> Star's Bain Maries embody efficiency and durability, offering advanced features to meet the demands of high-paced kitchens. Discover a perfect balance of performance and aesthetics with Star's renowned kitchen solutions.</li>
                                        <li><strong>Lincat:</strong> Lincat's commitment to quality shines through its range of Bain Maries, providing consistent heat and reliable performance. Enhance your kitchen's efficiency with Lincat's precise and durable equipment.</li>
                                        <li><strong>APW Wyott:</strong> APW Wyott's Bain Maries are crafted with precision, delivering superior heat distribution for perfect temperature control. Experience the durability and innovation that APW Wyott brings to every kitchen.</li>
                                        <li><strong>Cook Tek:</strong> Cook Tek revolutionizes induction cooking with state-of-the-art Bain Maries, combining energy efficiency with rapid heating. Enhance your kitchen workflow and reduce energy costs with Cook Tek's innovative solutions.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/144souper-sizes.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <p className="text-justify mt-4">
                                    <h5>Why Choose Middleby Corporation's Brands?</h5>
                                    <ul>
                                        <li><strong>Diverse Selection:</strong> Explore a wide range of Bain Maries to suit various kitchen sizes and requirements.</li>
                                        <li><strong>Trusted Brands:</strong> Benefit from the legacy of renowned brands, known for quality and innovation.</li>
                                        <li><strong>Expert Support:</strong> Our team is ready to guide you in selecting the ideal Bain Marie solution for your kitchen.</li>
                                    </ul>

                                    <p>Transform Your Culinary Experience Today</p>
                                    <p>Optimize your kitchen with Middleby Corporation's Bain Maries. Explore our collection from Celfrost, Toastmaster, Star, Lincat, APW Wyott, and Cook Tek, and experience culinary excellence like never before.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}