import React from "react";

export default function Icemachines() {
    return (
        <>

            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Ice Machines



                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">


                                    <h1>Discover Superior Cooling with NSF Certified Ice Solutions
                                    </h1>
                                    <p className="col-11">
                                        Welcome to Middleby Celfrost Innovations Pvt Ltd, India, your trusted destination for p
                                        remium ice machines under the esteemed Celfrost brand.
                                        From compact countertop units to robust commercial capacities,
                                        our range spans from 15 kg/24 hours to an impressive 900 kg/24 hours,
                                         offering versatility for every need. Choose between bubble top or water inlet connection options, all proudly certified by NSF for the highest standards of quality and safety.

                                    </p>

                                </p>




                                <p className="pt-4 text-justify">


                                    <h1  className="pt-3">Celfrost Ice Machines - Cool, Reliable, and Certified
                                    </h1>
                                    <p className="col-10">
                                    Experience the perfect fusion of efficiency and reliability with our NSF certified Celfrost ice machines. Whether you're managing a cozy cafe or a bustling restaurant, our range ensures a steady supply of ice tailored to your establishment's demands. Select the capacity that suits your business, all backed by NSF certification, ensuring uncompromised quality and safety. </p>

                                    <h1  className="pt-2 text-justify">
                                    Follett - Pioneering Automatic Ice Solutions
                                    </h1>
                                    <p className="col-10 pt-2">
                                    Step into a new era of ice handling with Follett, an industry leader in automatic ice solutions. Bid farewell to manual scooping as Follett's cutting-edge technology brings you instantly accessible ice with unparalleled convenience. As a premium choice in automatic vending solutions, Follett sets the standard for efficiency, transforming how you handle and serve ice with seamless precision.
                                    <br/>

                                    Experience superior cooling with Middleby Celfrost. Explore our range of NSF certified ice machines, delivering unparalleled convenience, reliability, and performance.

                                    </p>

                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-219-1.png`} alt="Ice Machines image" title="Ice Machines image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}