import React, { useState, useEffect } from "react";

export default function GreenMiddleby() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>



            <section className={`section section1 section--products bg-white ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fw-bold text-blue fs-2"><strong><span>Middleby Green </span></strong></h1>
                        <hr />
                        <img className="mb-5 rounded-1" src={`${process.env.REACT_APP_API_URL}assets/img/exp-09.jpg`}/>
                        <div className="row mb-5">
                            <div className="col-md-12 mx-auto px-3 text-center">
                                <h4 className="primarycolor fw-bold ">Middleby Green: Committed to a Sustainable Future</h4>
                                <p>At Middleby Celfrost, we understand the growing importance of environmental responsibility in the food service industry. We are committed to developing and providing innovative foodservice equipment solutions that promote sustainability without compromising on performance or efficiency</p>
                         
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-5 py-5">
                            <div className={`col-md-12 section26 `}>
                                <h1 className="text-center fw-bold fs-2"><strong>Why Go <span className="text-success">Green</span>?</strong></h1>
                                <hr />
                                {/* <h1 className="fw-bold">Why Go Green?</h1> */}
                                <img className="mb-5 rounded-1" src={`${process.env.REACT_APP_API_URL}assets/img/explore/green-01.png`} />


                                <h5 className="fw-bold">
                                    The food service industry has a significant environmental footprint. By adopting sustainable practices, we can collectively make a positive impact:
                                </h5>

                                <ul className="mt-3">
                                    <li> <span className="primarycolor fw-bold fs-1-2">Reduced Energy Consumption:   </span>Our commitment to energy-efficient equipment helps conserve resources and minimize your carbon footprint.</li>
                                    <li> <span className="primarycolor  fw-bold fs-1-2">Lower Operating Costs:</span> Energy-efficient equipment translates to lower utility bills, creating long-term cost savings for your business.</li>
                                    <li> <span className="primarycolor  fw-bold fs-1-2">Responsible Resource Management:</span> We utilize eco-friendly materials and design features that minimize water consumption and waste generation.</li>
                                    <li> <span className="primarycolor  fw-bold fs-1-2">Enhanced Brand Image:</span> Consumers are increasingly drawn to businesses that prioritize environmental responsibility. By choosing Middleby Celfrost, you demonstrate your commitment to a sustainable future.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-12 section26 `}>
                                <h1 className="text-center fw-bold fs-2"><strong>How Middleby Celfrost Contributes to a Greener Tomorrow?</strong></h1>
                                <hr />
                                {/* <h1 className="fw-bold">Why Go Green?</h1> */}
                                <img className="mb-5 rounded-1" src={`${process.env.REACT_APP_API_URL}assets/img/explore/green-02.png`} />
                                <h5 className="fw-bold">We integrate sustainability principles throughout our product development and manufacturing processes:</h5>
                                <ul className="mt-3">
                                    <li><span className="primarycolor fw-bold fs-1-2">Energy-Efficient Technology: </span>Our equipment utilizes advanced technologies like variable-speed drives and improved insulation to reduce energy consumption.</li>
                                    <li><span className="primarycolor fw-bold fs-1-2">Sustainable Materials: </span>We prioritize the use of recyclable and eco-friendly materials in our product construction.</li>
                                    <li><span className="primarycolor fw-bold fs-1-2">Water Conservation Features: </span>Certain equipment incorporates water-saving features, such as low-flow faucets and automatic shut-off mechanisms.</li>
                                    <li><span className="primarycolor fw-bold fs-1-2">Optimizing Equipment Performance: </span>Our equipment is designed for optimal performance, minimizing the need for replacements and reducing overall environmental impact.</li>
                                </ul>

                                <h5 className="fw-bold">Partnering for a Sustainable Future: </h5>
                                <p className="fw-6 pt-4">By choosing Middleby Celfrost, you're not just investing in high-quality equipment; you're partnering with a company dedicated to environmental responsibility. Together, we can create a more sustainable food service industry for generations to come.</p>

                                <h5 className="fw-bold">Learn more about our Middleby Green product range and how you can make a difference! </h5>
                                <div className="col-md-3 col-lg-2 mt-3">
                                    <a className="btn btn--block" href="/Explore"><span>Our Products</span><span className="icon fa fa-arrow-right m-0"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}