import React, { useState, useEffect } from "react";

export default function BulkCooking() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            


            <section className={`section section1 section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h4 className="primarycolor fw-bold">1. Scale Up Success: Institutional & Bulk Cooking Solutions with Middleby Celfrost</h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-6 6 section31 `}>

                                <p>Middleby Celfrost empowers you to excel in institutional and bulk cooking operations. Serve large volumes efficiently while maintaining consistent quality and safety standards. Our solutions cater to a variety of needs, from university dining halls to corporate cafeterias and healthcare facilities.</p>

                                <h2 className="fw-bold pb-3 text-blue">Optimize Your Kitchen Workflow:</h2>
                                <ul className="mt-2 list-unstyled">
                                    <li> <span className="primarycolor fw-bold">✔  High-Capacity Cooking Equipment:</span> Efficiently prepare large batches of food to meet high demands.</li>
                                    <li> <span className="primarycolor fw-bold">✔ Durable Holding Cabinets:</span> Keep cooked meals at safe serving temperatures for extended periods.</li>
                                    <li> <span className="primarycolor fw-bold">✔ Blast Chillers & Shock Freezers: </span>Rapidly cool large quantities of food to preserve quality and safety.</li>
                                    <li> <span className="primarycolor fw-bold">✔ Easy-to-Clean Surfaces:</span> Minimize downtime and maintain a sanitary kitchen environment.</li>
                                </ul>
                                <p className="">Partner with Middleby Celfrost to streamline your institutional or bulk cooking operation. Our equipment is designed for efficiency, durability, and safety, empowering you to deliver high-quality meals on a large scale.</p>
                            </div>

                            <div className={`col-md-6 mb-5 imagecon section32 `}>

                                {/* <div className="image-container"> */}
                                <img className="kitchenimage p-0 box-shadow p-3 rounded-1 exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/exp-02.jpg`} />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}