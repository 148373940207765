import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Explore.css';
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import axios from "axios";

export default function Explore() {
    const navigate = useNavigate();

    useEffect(() => {
        new Splide('#main-slider', {
            pagination: false,
            type: 'fade',
            drag: 'free',
            focus: 'right',
            perPage: 1,
            autoScroll: {
                speed: 1,
            },
            speed: 4e3,
            rewind: !0,
            autoplay: !0,
            interval: 6e3,
            pauseOnHover: !1,
            pauseOnFocus: !1,
        }).mount();
    }, []);

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [brands, setBrands] = useState(null);
    const [marquee, setMarquee] = useState(null);


    useEffect(() => {
        fetchDataBrands();
        fetchMarquee();
    }, []);
    const fetchDataBrands = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'HeaderMenuBrands' });
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands data:', error);
        }
    };


    // Function to fetch data from the API for marquee atg
    const fetchMarquee = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'OurPartners' });
            setMarquee(response.data);
        } catch (error) {
            console.error('Error fetching brands data:', error);
        }
    };



    const renderProductBrandslogo = () => {
        if (!brands || !brands.data) return null;

        return brands.data.map(brand => (
            <div className="splide__slide"
                role="button"
                style={{ marginRight: '40px', height: '80px' }}
                key={brand.id}              >
                <img width="197" height="100" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.logo}`}
                    className="attachment-full size-full" decoding="async" loading="lazy" alt={brand.name} />
            </div>
        ));
    };
    const renderProductBrandslogoDesc = () => {
        if (!brands || !brands.data) return null;

        return brands.data.map(brand => (
            <div className="splide__slide" key={brand.id + '-desc'} >
                {/* <p>Brand--{brand.id + '-desc'}</p> */}
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className="logo">
                            <img width="197" height="200" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.logo}`}
                                className="attachment-full size-full" alt={brand.name} decoding="async" loading="lazy" />
                        </div>
                        <div className="wysiwyg">
                            <h3>{brand.heading}</h3>
                            <div dangerouslySetInnerHTML={{ __html: brand.short_desc }} />

                            <a className="btn" href={`products/brand/${brand.slug}`} tabIndex="-1"><span>View {brand.name} Products</span><span className="icon">

                            </span></a>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-block">
                        <img width="600" height="750" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.image}`}
                            className="attachment-brand-image size-brand-image"
                            alt={brand.name} decoding="async" loading="lazy" />
                    </div>
                </div>
            </div>
        ));
    };
    const renderCustomerlogo = () => {
        if (!marquee || !marquee.data) return null;
        return marquee.data.map(brand => (
            <div key={brand.id} >
                {/* Assuming the logo path is relative */}
                <img className="attachment-full size-full p-2" decoding="async" loading="lazy" src={`${process.env.REACT_APP_API_URL}/${brand.url}`} alt={brand.alt} />
            </div>

        ));
    };



    useEffect(() => {
        var main = new Splide('#main-slider12', {
            type: 'fade',
            rewind: true,
            pagination: false,
            arrows: false,
        });

        var thumbnails = new Splide('#thumbnail-slider', {
            fixedWidth: 100,
            fixedHeight: 60,
            gap: 10,
            rewind: true,
            pagination: false,
            isNavigation: true,
            breakpoints: {
                600: {
                    fixedWidth: 60,
                    fixedHeight: 44,
                },
            },
        });

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();

    }, [brands]);





    const [blogs, setBlogs] = useState(null);

    const fetchBlogs = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "AllBlogs"
            });
            setBlogs(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchBlogs();
    }, []);

    const LatestBLOgs = () => {
        if (!blogs) return null;

        return blogs.map((product, index) => (

            <div className="splide__slide px-3 col-md-6 col-lg-4"
                key={index} >
                <article className="article article--news">
                    <a href={`${process.env.REACT_APP_APP_URL}/blog/${product.slug}`}>
                        <div className="inner">
                            <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/images/blogs/${product.image})` }}></div>

                            <div className="bottom">
                                <div className="content">
                                    <h2>{product.heading}</h2>
                                    <div className="blogDesc">
                                        <p dangerouslySetInnerHTML={{ __html: product.description.slice(0, 170) }} />
                                        ... <strong>read more</strong>
                                    </div>
                                </div>
                                <div className="category">Blogs</div>
                            </div>
                            <div className="date">
                                <span>
                                    <span className="day">{new Date(product.date).getDate()}</span>
                                    <span className="month-year">{new Date(product.date).toLocaleString('default', { month: 'short' })} {new Date(product.date).getFullYear().toString().slice(-2)}</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </article>
            </div>

        ));
    };


    useEffect(() => {
        new Splide('#blog-slider', {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            pagination: false,
            breakpoints: {
                768: { 
                    perPage: 1,
                },
                1100: { 
                    perPage: 2,
                },
            },
        }).mount();
    }, [blogs]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const searchQuery = formData.get('search');
        navigate(`/products/search/${encodeURIComponent(searchQuery)}`);
    };


    return (
        <>

            <section className="js-block-masthead section section--masthead advanced explore-page">
                <div id="main-slider" className="splide">
                    <div className="splide__track splide-overlay">
                        <div className="splide__list">
                            <div className="splide__slide" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore1.jpg`} />
                            </div>
                            <div className="splide__slide">
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore2.jpg`} />
                            </div>
                            <div className="splide__slide" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore3.jpg`} />
                            </div>
                            <div className="splide__slide">
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore4.jpg`} />
                            </div>
                            <div className="splide__slide" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore5.jpg`} />
                            </div>
                            <div className="splide__slide" >
                                <img className="w-auto" src={`${process.env.REACT_APP_API_URL}assets/img/explore6.jpg`} />
                            </div>
                        </div>
                    </div>

                    <div className="content explore-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 mx-auto p-5"
                                // style={{ backgroundColor: '#000000ab' }}
                                >
                                    <div className="wysiwyg">
                                        <h1 className="text-white slide-text"> Find Your Perfect <br />Kitchen Equipment Match!</h1>
                                        <h5 className="text-white text-justify slide-para">Welcome to your one-stop shop for optimizing your commercial kitchen! Middleby Celfrost offers a comprehensive toolbox of innovative solutions designed to meet the specific needs of your business. Whether you run a bustling restaurant, a thriving supermarket, or a high-volume takeaway operation, we have the tools to elevate your efficiency, boost profitability, and impress your customers.
                                        </h5>
                                    </div>
                                    <div className="col-md-6 row g-2 mt-3">
                                        <div className="col-sm-4">
                                            <Link className="btn btn-primary text-center" to={`/our-products`}>
                                                Our Products
                                            </Link>
                                        </div>

                                        <div className="col-md-8">
                                            <form className="js-form-product-search" onSubmit={handleSubmit} autoComplete="off" method="GET">
                                                <label className="visually-hidden" htmlFor="s">Search products</label>
                                                <div className="input-group">
                                                    <span className="icon"><i className="fas fa-magnifying-glass"></i> </span>
                                                    <input className="form-control" name="search" type="text" placeholder="Search products&hellip;" />
                                                </div>
                                                <button className="visually-hidden" type="submit">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 
            <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="wysiwyg">
                                <h1 className="text-white pb-3"> Find Your Perfect <br />Kitchen Equipment Match!</h1>
                                        <p>Welcome to your one-stop shop for optimizing your commercial kitchen! Middleby Celfrost offers a comprehensive toolbox of innovative solutions designed to meet the specific needs of your business. Whether you run a bustling restaurant, a thriving supermarket, or a high-volume takeaway operation, we have the tools to elevate your efficiency, boost profitability, and impress your customers.
                                        </p>
                                    </div>
                                    <div className="row g-2 mt-5">
                                        <div className="col-md-4">
                                            <Link className="btn btn--block" to={`#`}>
                                                <span>Our Products</span>
                                                <span className="icon fa fa-arrow-right"></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

            {/* <section className={`section section1  section--products mx-0 my-0 pt-3 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}> */}

            <section className={`section section1 section--products mx-0 my-0 pt-5 bg-white ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fs-2 text-blue">
                            <strong><span>Explore Solutions</span></strong>
                        </h1>
                        <hr />
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/dining-coffee">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/pizza-01.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Casual Dining, Fast Casual, QSR, Multi-Cuisine, Cafes, & Coffee Shops </h4>
                                                <div className="expDesc my-2">
                                                    <p>
                                                        <p>Envision a bustling restaurant abuzz with satisfied customers.
                                                        </p>
                                                    </p>...
                                                    {/* <br/> */}
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/burger">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/burger-01.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Burger<br /><br /></h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Dominate the burger game with efficient, high-quality equipment</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/private-dining">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/fine-dine-01.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Fine Dining/Private Dining</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Craft unforgettable fine dining and private dining experiences.</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/bulk-cooking">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/fine-dine-02.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Institution/ Corporate/ Bulk cooking</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Middleby Celfrost empowers you to excel in institutional and bulk cooking</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/bakery-pastry">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/bakery-01.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Bakery and Pastry shop</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Turn your passion for pastries into a thriving business</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/authentic-pizzas">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/pizza-02.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Pizzeria</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Equip your pizzeria for success with Middleby Celfrost, your one-stop shop</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/ventless-solutions">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/exp-07.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Ventless Solutions:</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Middleby Celfrost understands the challenges of limited ventilation</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/green-middleby">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/middleby-green.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Middleby Green</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Middleby Celfrost is committed to a greener future. Our Middleby Green</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/automation">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/automation.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Automation</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Middleby Celfrost understands the pressure of today's foodservice industry.</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/food-retail">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/supermarket.webp)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Food Retail/Super market</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Today's food retail landscape is all about convenience and efficiency.</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/beverage">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/exp-14.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Bar/ Pubs / Beverage</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>We offer innovative solutions to streamline your beverage operations, from draft beer management</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/cloud-kitchen">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/ghost-kitchen-01.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Take way / Delivery/ Ghost kitchen/ Cloud kitchen</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Celfrost understands the unique needs of ghost kitchens, cloud kitchens</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/ice-cream">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/ice-cream-parlour.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">Ice Cream Parlour/ Juice bars</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>The world craves delightful treats, but managing busy ice cream and juice</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/marine">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/explore/marine-02.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">MARINE</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>While Middleby Celfrost's current focus is on land-based foodservice solutions</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="article article--news">
                                <a href="/explore/iot">
                                    <div className="inner">
                                        <div className="top" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/img/exp-19.jpg)` }}></div>
                                        <div className="bottom bg-red">
                                            <div className="content h-220">
                                                <h4 className="fw-bold">IOT</h4>
                                                <div className="expDesc my-2">
                                                    <p><p>Imagine a kitchen where every appliance seamlessly communicates, optimizing performance</p></p>...
                                                    <p>Read more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        </div>
                    </div>
                </div>
            </section >


            <section className="section section--customers" style={{ backgroundColor: '#ffffff', Color: 'inherit' }}>
                <div className="container">
                    <div className="wysiwyg">
                        <p className='text-black' style={{ textAlign: 'center' }}>
                            <strong>Our product range benefits national chains such as</strong>
                            <br /> KFC, Burger King, Domino's, Amul, Starbucks and many more…
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <marquee direction="right" width="450" behavior="alternate" style={{ color: '#E1E4E7', width: '100%' }}>
                                {renderCustomerlogo()}
                            </marquee>
                        </div>
                        <div className="col-md-6">
                            <marquee direction="left" width="450" behavior="alternate" style={{ color: '#E1E4E7', width: '100%' }}>
                                {renderCustomerlogo()}
                            </marquee>
                        </div>
                    </div>
                </div>
            </section>
            <section id="ourbrandsection" className="js-block-brands section section--brands" style={{ background: 'linear-gradient(0deg,var(--color-white) 0,var(--color-tertiary))' }}>



                <div className="container">
                    <div className="wysiwyg">
                        <h2 style={{ textAlign: 'center' }}><strong>
                            <span style={{ color: '#dd1b35' }}>OUR BRANDS</span></strong>
                        </h2><p style={{ textAlign: 'center' }}>Click to explore each product range</p>
                        <hr />
                        <p style={{ textAlign: 'center' }}></p>
                    </div>

                    <div className='splide-wrapper'>

                        <div className="js-splide-brands-thumbs splide splide--brands-thumbs" id='thumbnail-slider'>
                            <div className="splide__track">
                                <div className="splide__list">
                                    {renderProductBrandslogo()}

                                </div>
                            </div>
                        </div>

                        <div className="js-splide-brands-main splide splide--brands-main pt-5" id='main-slider12'>
                            <div className="splide__track" >
                                <div className="splide__list">
                                    {renderProductBrandslogoDesc()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section className={`section section3 bg-black text-white section--products m-0 `}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-11 mx-auto px-5 pb-0 text-center">
                            <h1 className="text-wheat fw-bold mb-4">Latest Blogs</h1>
                        </div>

                        <div className="splide " id='blog-slider'>
                            <div className="splide__track">
                                <div className="splide__list">
                                    {LatestBLOgs()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>




        </>
    )
}