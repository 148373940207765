import React, { useState, useRef } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './career.css';

export default function Career() {

    
    const [BookFromData, setBookFromData] = useState({
        action: 'careersSubmit',
        inputFullName: '',
        inputLastName: '',
        inputNumber: '',
        inputEmail: '',
        position: '',
        date_of_emp: '',
        companyname: '',
        jobtitle: '',
        inputMessage: '',
        image1: '',
        image2: ''
    });

  
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image1") {
            if (files) {
            const images = e.currentTarget.files[0];
            setBookFromData({
                ...BookFromData,
                image1: images
            });
        }
        }else  if (name === "image2") {
            if (files) {
            const images12 = e.currentTarget.files[0];
            setBookFromData({
                ...BookFromData,
                image2: images12
            });
        }
        }else{
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));

        }
    };
    const toastId = useRef(null)

    const BookhandleSubmit = async (e) => {
        toastId.current =toast.loading("Please Wait...");
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('action', 'careersSubmit');
            formData.append('inputFullName', BookFromData.inputFullName);
            formData.append('inputLastName', BookFromData.inputLastName);
            formData.append('inputNumber', BookFromData.inputNumber);
            formData.append('inputEmail', BookFromData.inputEmail);
            formData.append('position', BookFromData.position);
            formData.append('date_of_emp', BookFromData.date_of_emp);
            formData.append('companyname', BookFromData.companyname);
            formData.append('jobtitle', BookFromData.jobtitle);
            formData.append('inputMessage', BookFromData.inputMessage);
            formData.append('image1', BookFromData.image1);
            formData.append('image2', BookFromData.image2);

            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === true) {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "success",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
                setBookFromData({
                    action: 'careersSubmit',
                    inputFullName: '',
                    inputLastName: '',
                    inputNumber: '',
                    inputEmail: '',
                    position: '',
                    date_of_emp: '',
                    companyname: '',
                    jobtitle: '',
                    inputMessage: '',
                    image1: '',
                    image2: ''
                });
            } else {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "error",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



    return (
        <>
        <ToastContainer />
            <section className="js-block-masthead section section--masthead advanced py-5 bg-slider-explores">
                <div className="elementor-background-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto bg-itm2 ">
                            <div className="text-center">
                                {/* <h4 className="pb-3"><span className="bg-primary">Middleby Celfrost</span></h4> */}
                                <h1 className="text-white pb-3">Empower Your Career in Commercial Food Service equipment with Middleby Celfrost</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section section6 bg-white section--products m-0 `}>

                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fw-bold fs-2"></h1>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h5 className="primarycolor text-center fw-normal">Middleby Celfrost, a premier destination for innovative and reliable foodservice equipment solutions. With a rich legacy spanning decades, Middleby is a Global leader in designing, manufacturing, and distributing a comprehensive range of cutting-edge commercial kitchen equipment.</h5>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-5 mb-5 section22 `}>

                                {/* <div className="col-md-5 mb-5"> */}
                                <img className="aboutimage1 p-0 mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/exp-03.jpg`} />
                                <img className="aboutimage1 p-0 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/exp-04.jpg`} />
                            </div>
                            <div className="col-md-6">
                                <h2 className="text-blue fw-bold">
                                    Why Choose Middleby Celfrost?
                                </h2>
                                <ul className="pt-2">
                                    <li><span className="fw-bold h4 primarycolor">Industry Leader : </span> We are a global company with a rich history of developing cutting-edge food service equipment solutions.</li>
                                    <li><span className="fw-bold h4 primarycolor">Passionate Team : </span> Our team is comprised of talented and dedicated individuals who are committed to excellence.</li>
                                    <li><span className="fw-bold h4 primarycolor">Innovation : </span> We foster a culture of creativity and invest heavily in research and development to bring the latest technologies to market.</li>
                                    <li><span className="fw-bold h4 primarycolor">Growth Opportunities : </span> We offer a dynamic work environment with opportunities for professional development and career advancement.</li>
                                    <li><span className="fw-bold h4 primarycolor">Competitive Benefits : </span> We provide a comprehensive benefits package that includes health insurance, paid time off, and retirement savings plans</li>
                                    {/* <li><span className="fw-bold h4 primarycolor">Colorfull : </span> black</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section section7 bg-black text-white section--products m-0`}>

                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fs-2">
                            <strong><span>Current Openings</span></strong>
                        </h1><hr />
                        <div className="row align-items-center justify-content-between pt-0">
                            <div className="col-md-6">
                                <p className="mt-4">We are always looking for talented and motivated individuals to join our team. We have a variety of openings across different departments, </p>

                                <h2 className="text-wheat">Including:</h2>

                                <ul className="pt-2 list-unstyled">
                                    <li><span className="text-wheat">✔  Engineering</span></li>
                                    <li><span className="text-wheat">✔  Sales & Marketing</span></li>
                                    <li><span className="text-wheat">✔  Operations</span></li>
                                    <li><span className="text-wheat">✔  Finance & Accounting</span></li>
                                    <li><span className="text-wheat">✔  Human Resources</span></li>
                                </ul>

                                <p className="mt-4">What We Look For At Middleby Celfrost, we value candidates who possess the following </p>

                                <h2 className="text-wheat">Qualities:</h2>

                                <ul className="pt-2 list-unstyled">
                                    <li><span className="text-wheat">✔  A strong work ethic and a commitment to quality</span></li>
                                    <li><span className="text-wheat">✔  A collaborative spirit and the ability to work effectively in a team environment</span></li>
                                    <li><span className="text-wheat">✔  Excellent communication and interpersonal skills</span></li>
                                    <li><span className="text-wheat">✔  A passion for the food service industry (preferred)</span></li>
                                    <li><span className="text-wheat">✔  A proven track record of success in a relevant field (for experienced candidates)</span></li>
                                </ul>
                            </div>

                            <div className={`col-md-6 mb-5 section23 `}>
                                <img className="pizzeriaimage p-0 mb-3 exp-img1" src={`${process.env.REACT_APP_API_URL}assets/img/exp-05.jpg`} />
                                <img className="pizzeriaimage p-0 exp-img2" src={`${process.env.REACT_APP_API_URL}assets/img/exp-06.jpg`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`section section6 bg-white section--products m-0 `}>

                <div className="container">
                    <div className="wysiwyg">
                        <h1 className="text-center fw-bold fs-2">Application Form</h1>
                        <hr />
                        <div className="row align-items-center justify-content-between">
                            <div className={`col-md-5 mb-5 section22 `}>
                                {/* <div className="col-md-5 mb-5"> */}
                                <img className="aboutimage1 p-0 mb-3 exp-img3" src={`${process.env.REACT_APP_API_URL}assets/img/exp-03.jpg`} />
                                <img className="aboutimage1 p-0 exp-img4" src={`${process.env.REACT_APP_API_URL}assets/img/exp-04.jpg`} />
                            </div>
                            <div className="col-md-6">
                                <h5 className="primarycolor fw-normal">How to Apply </h5>
                                <p>We encourage you to browse our current openings and submit your resume and cover letter online. We look forward to hearing from you!</p> <br />
                                <p>In addition to the above, you may consider including the following sections</p>
                                {/* <h2 className="text-blue fw-bold">Why Choose Middleby Celfrost?</h2> */}
                                <ul className="pt-2">
                                    <li><span className="fw-bold h4 primarycolor">Company Culture : </span> Briefly describe your company culture and work environment.</li>
                                    <li><span className="fw-bold h4 primarycolor">Employee Testimonials : </span> Include quotes from satisfied employees about their experience working at Middleby Celfrost.</li>
                                    <li><span className="fw-bold h4 primarycolor">Awards & Recognition : </span> Highlight any awards or recognition that Middleby Celfrost has received.</li>
                                    <li><span className="fw-bold h4 primarycolor">Benefits in Detail : </span> Provide a more detailed overview of the benefits package offered by Middleby Celfrost.</li>
                                    <li><span className="fw-bold h4 primarycolor">Social Media Links : </span> Include links to your company's social media pages (LinkedIn, Facebook, etc.).</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form-area  mb-5" id="form">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 px-5 pt-5 rounded-1 m-auto text-center box-shadow">
                            <strong className="car-title primarycolor">
                                Middleby Celfrost - Job Application Form
                            </strong>
                            <p className="text-center pt-2 pb-2">
                                Thank you for your interest in a career at Middleby Celfrost! To apply for a position, please complete all sections of this form and submit your resume and cover letter.
                            </p>
                            <div className="contact-form pt-3">
                                <form id="contact-form" name="contact_form" onSubmit={BookhandleSubmit} className="default-form2" method="post">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text"
                                                   onChange={handleChange}
                                                    name="inputFullName"
                                                    placeholder="First Name" value={BookFromData.inputFullName}
                                                    required="" />
                                            </div>
                                            <div className="input-box">
                                                <input type="text" name="inputEmail" onChange={handleChange} placeholder="Email Address" value={BookFromData.inputEmail}  required="" />
                                            </div>
                                            <div className="input-box">
                                                <select className="servicepageinput " name="position" required=""   onChange={handleChange}  value={BookFromData.position}>
                                                    <option value="" disabled selected>Select the position </option>
                                                    <option value="Position 1">Position 1</option>
                                                    <option value="Position 2">Position 2</option>
                                                    <option value="Position 3">Position 3</option>
                                                    <option value="Other"> Other   </option>
                                                </select>
                                            </div>
                                            <div className="input-box">
                                                <input type="text" name="companyname" placeholder="Company Name" required=""  value={BookFromData.companyname}  onChange={handleChange}/>
                                            </div>
                                        </div>


                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text"
                                                    name="inputLastName"
                                                    placeholder="Last Name"   onChange={handleChange} value={BookFromData.inputLastName}
                                                    required="" />
                                            </div>
                                            <div className="input-box">
                                                <input type="text"

                                                    name="inputNumber"
                                                    onChange={handleChange}  value={BookFromData.inputNumber}
                                                    placeholder="Phone Number" />
                                            </div>
                                            <div className="input-box">
                                                <input    onChange={handleChange} type="text" name="date_of_emp" placeholder="Dates of Employment (Month/Year - Month/Year)" required="" />
                                            </div>
                                            
                                            <div className="input-box">
                                                <input type="text"
                                                    name="jobtitle" placeholder="Job Title"   onChange={handleChange} value={BookFromData.jobtitle}
                                                    required="" />
                                            </div>


                                        </div>
                                        
                                        <div className="row">  
                                        <div className="input-box col-lg-6">
                                                <label htmlFor="UploadResume" className=" text-left">
                                                Upload Resume{" "}
                                                </label>
                                                <input id="UploadLetter" type="file"   onChange={handleChange} className="servicepageinput" name="image1" accept=".doc, .docx, .pdf" required=""  />
                                            </div>
                                           
                                            <div className="input-box col-lg-6">
                                                <label htmlFor="UploadLetter12121" className=" text-left">
                                                Upload Letter{" "}
                                                </label>
                                                <input id="UploadLetter12121"    onChange={handleChange} type="file" className="servicepageinput " name="image2" accept=".doc, .docx, .pdf" required=""  />
                                            </div>
                                            </div>
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="input-box">
                                                <textarea type="text"
                                                    name="inputMessage"   value={BookFromData.inputMessage} onChange={handleChange} placeholder="Summary of Responsibilities & Achievements"
                                                    required="" />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="button-box text-center">
                                                <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                                <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                    <span className="txt">
                                                        Submit
                                                    </span>
                                                </button>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}