import React from "react";
import './Blogs.css';

export default function Culinary() {
    return (
        <>
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList" className="text-white">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Culinary Support</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            {/* <br /><br /><br /> */}
            <section className="js-block-masthead section section--masthead advanced py-5 bg-slider">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mx-auto">
                            <div className="wysiwyg bg-itm text-center">
                                <h1 className="text-white">Middleby Celfrost: Your Culinary Partner in Success</h1>
                                <p className="text-white">Welcome to Middleby Celfrost, your premier destination for innovative and reliable foodservice equipment solutions. With a rich legacy spanning decades, Middleby is a Global leader in designing, manufacturing, and distributing a comprehensive range of cutting-edge commercial kitchen equipment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--products mx-0" style={{ marginTop: '-150px' }}>
                <div className="container">
                    <div className="wysiwyg">
                        <h4 className="sec-subtitle fontsizelrg mb-5">
                            <strong><span>AS A MIDDLEBY CELFROST CUSTOMER, YOU GET ACCESS TO OUR TEAM’S EXPERTISE FROM DAY ONE.</span></strong>
                        </h4>
                        <div className="row">
                            <div className="col-md-12 mx-auto px-3">
                                <h4 className=" primarycolor">Explore a World of Culinary Possibilities:</h4>
                                <p>Our comprehensive culinary support hub offers a wealth of resources to inspire and empower chefs of all levels:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-screwdriver-wrench"></i>
                                </span>
                                <p className="mt-3"><strong>Equipment Expertise: </strong>Delve into detailed product information and specifications for our extensive range of commercial kitchen equipment. Discover how our ovens, fryers, steamers, and more can elevate your food preparation and production processes.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="item item-shadow">
                                <span className="icon text-white">
                                    <i className="fat fa-user-chef"></i>
                                </span>
                                <p className="mt-3"><strong>Recipes & Inspiration: </strong>Get inspired by a curated collection of recipes specifically designed to showcase the versatility and efficiency of Middleby Celfrost equipment. Explore innovative culinary techniques and discover new ways to create mouthwatering dishes.</p><br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="item item-shadow">
                            <span className="icon text-white">
                                    <i className="fat fa-lightbulb-exclamation"></i>
                                </span>
                                <p className="mt-3"><strong>Application Guides & Tips: </strong>Maximize the potential of your Middleby Celfrost equipment with our library of application guides and expert tips. Learn best practices for using specific equipment, troubleshooting techniques, and valuable insights from industry professionals.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="item item-shadow">
                            <span className="icon text-white">
                                    <i className="fat fa-books"></i>
                                </span>
                                <p className="mt-3"><strong>Culinary Education Resources: </strong>Expand your culinary knowledge and refine your skills with access to educational resources and training materials. These resources, developed in collaboration with industry experts, can help you master culinary techniques and stay ahead of the curve.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--products bg-img mx-0" style={{ marginTop: '-50px' }}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-8 px-4">
                                <h4 className="primarycolor">Unparalleled Customer Support:</h4>
                                <p className="text-white">Our commitment extends beyond equipment. Our dedicated customer support team is readily available to answer your questions, provide expert advice, and ensure your equipment performs optimally.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="item h-320">
                                <span className="icon text-white">
                                    <i className="fat fa-lightbulb-gear"></i>
                                </span>
                                <p className="mt-3"><strong>Technical Support: </strong>Access comprehensive technical support resources and troubleshooting guides to address any equipment-related issues quickly and efficiently.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="item h-320">
                                <span className="icon text-white">
                                    <i className="fat fa-screwdriver-wrench"></i>
                                </span>
                                <p className="mt-3"><strong>Parts & Service: </strong>Our reliable network of authorized service providers ensures prompt and professional maintenance and repair services for your Middleby Celfrost equipment.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="item h-320">
                                <span className="icon text-white">
                                    <i className="fat fa-user-chef"></i>
                                </span>
                                <p className="mt-3"><strong>Chef Hotline: </strong>Connect with our team of culinary experts through our dedicated Chef Hotline. Get expert advice on menu planning, equipment selection, and maximizing the capabilities of your Middleby Celfrost kitchen solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--products mx-0" style={{ marginTop: '-50px' }}>
                <div className="container">
                    <div className="wysiwyg">
                        <div className="row">
                            <div className="col-md-12 mx-auto px-4">
                                <h4 className="primarycolor">Join the Middleby Celfrost Culinary Community:</h4>
                                <p>Connect with a passionate network of chefs and culinary professionals through our online community forums. Share recipes, exchange ideas, and learn from each other's experiences to continuously refine your culinary skills and elevate your foodservice operation.</p>

                                <h4 className="primarycolor">Empowering Culinary Excellence:</h4>
                                <p>Middleby Celfrost is more than just a supplier of equipment; we are your trusted partner in culinary success. We believe in fostering a collaborative environment where chefs and culinary professionals can thrive. By providing the resources, support, and inspiration you need, we empower you to unleash your creativity, push culinary boundaries, and consistently deliver exceptional dining experiences.</p>

                                <h4>Explore our culinary support hub today and embark on a journey of culinary mastery with Middleby Celfrost!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}