import React from "react";

export default function Toasters() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                        Toasters
                                    </strong>
                                </h1>
                                <br />
                                <div>
                                    <p style={{ textAlign: 'justify' }}>At Middleby Celfrost, we understand that the perfect slice of toast can make all the difference. That's why we offer a range of toasters from trusted brands, Lincat and Toastmaster, designed to transform your Burger and breakfast experience.</p><br />
                                    <strong>Lincat Pop-Up and Conveyor Toasters:</strong><br />
                                    <p>For those in pursuit of toasting perfection, Lincat delivers. Our pop-up and conveyor toasters are a staple in 5-star hotels, adorning breakfast counters to provide your guests with flawlessly toasted bread. Enjoy efficiency, precision, and consistency, ensuring every slice is golden and delicious.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1 py-0 m-0" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-401-1.png`} alt="Waste Station" title="Waste Station" style={{ height: "300px", width: "auto" }}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <img className="aboutimage1 py-0 m-0 w-100" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-110-1.png`} alt="Waste Station" title="Waste Station"/>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6 mb-5">
                            <div className="wysiwyg">
                                <div>
                                    <strong>Toastmaster Vertical Bun Toaster:</strong><br /><br />
                                    <p>When time is of the essence, our Toastmaster vertical bun toaster takes center stage. This lightning-fast appliance delivers perfectly toasted buns in as little as 15 seconds. Ideal for busy kitchens and demanding service, it ensures that your burgers, sandwiches, and buns are ready in a flash.</p><br />
                                    <p>Middleby Celfrost brings you the quality, performance, and efficiency you need to elevate your breakfast service. Toasting perfection is just a moment away, and we're here to make it happen.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}