import React from "react";

export default function Bakerymixers() {
    return (
        <>            
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                        Bakery Mixers
                                    </strong>
                                </h1>
                                <br /> <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <strong>Unleash Baking Excellence with Our Exceptional Bakery Mixers</strong>
                                    <p className="mb-3">Transform your baking experience with Middleby Celfrost's cutting-edge bakery mixers. Our curated selection features renowned brands like Toastmaster, Varimixer, and Escher, ensuring your bakery or pizzeria benefits from top-notch mixing solutions.</p>
                                    <strong>Toastmaster: Gold Standard in Reliability and Versatility</strong>
                                    <p className="mb-3">Trusted by bakers worldwide, Toastmaster mixers embody reliability and versatility. From small batches to large dough quantities, Toastmaster brings you a range of mixers in various sizes and configurations, delivering the power and consistency your baking demands.</p>
                                    <strong>Varimixer: Innovative Excellence in Mixing</strong>
                                    <p className="mb-3">Experience innovative mixing excellence with Varimixer. The patented Varimixer W-Series boasts a unique bowl design equipped with a dual-roller and scraper system. Revel in faster mixing times, minimal bowl scraping, and unmatched precision. Varimixer is the preferred choice for efficiency and user-friendly design.</p>
                                    <strong>Escher: Premium Solutions for Large-scale Production</strong>
                                    <p className="mb-3">Escher mixers redefine large-scale production for premium bakeries and pizzerias. With substantial capacities, Escher ensures your operation meets the demands of high-volume baking. Elevate your baking process with Escher's high-performance mixers.</p>
                                    <strong>Tailored Sizes to Suit Your Unique Needs</strong>
                                    <p className="mb-3">Our mixers are available in a range of sizes, catering to diverse requirements. From 7-liter countertop models to 20, 30, and 40-liter bowl capacities from Toastmaster and Celfrost, and larger capacities in Varimixer and Escher, we have the perfect mixer for every production volume. <br/>
                                    Selecting the ideal bakery mixer involves considering factors like production volume, desired products, and budget. Our mixers are meticulously designed to meet these specific needs, providing efficiency and precision in every step of your baking process.<br/>
                                    Contact us today for comprehensive information, including detailed specifications, competitive pricing, warranty details, and additional services available. Transform your baking experience with the unparalleled bakery mixer solutions from Middleby Celfrost.</p>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-384-1.jpg`} alt="Bakery Mixers" title="Bakery Mixers" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}