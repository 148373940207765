import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Career() {

    const [BookFromData, setBookFromData] = useState({
        action: 'careersSubmit',
        inputFullName: '',
        inputNumber: '',
        inputEmail: '',
        inputCity: '',
        inputMessage: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const BookhandleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, BookFromData);

            if (response.data.status === true) {
                toast.success(response.data.message);
                setBookFromData({
                    action: 'careersSubmit',
                    inputFullName: '',
                    inputNumber: '',
                    inputEmail: '',
                    inputCity: '',
                    inputMessage: ''
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <>
            <ToastContainer />
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </a>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name">
                                        <strong>Career</strong>
                                    </span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <br /><br /><br />

            <section className="contact-form-area" id="form">
                <div className="container">
                    <div className="title text-center">
                        <strong className="car-title">Be A Part Of Middleby Celfrost Team </strong>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <br />
                            <p>
                                At Middleby Celfrost, we are relentless about delivering a pleasant experience to our customers by bringing world class products & solutions to them. But it is our employees who enhance their overall experience. <br />
                                <strong>No Current Job Openings for now.</strong>
                            </p>
                            <br /> <br /> <br />
                            <div className="contact-form">
                                <form id="contact-form" name="contact_form" className="default-form2" onSubmit={BookhandleSubmit} method="post">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text"
                                                    onChange={handleChange}
                                                    name="inputFullName"
                                                    value={BookFromData.inputFullName}
                                                    placeholder="Name"
                                                    required="" />
                                            </div>
                                            <div className="input-box">
                                                <input type="text"
                                                    onChange={handleChange}
                                                    name="inputNumber"
                                                    value={BookFromData.inputNumber}
                                                    placeholder="Phone" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="input-box">
                                                <input type="text"
                                                    onChange={handleChange}
                                                    name="inputEmail"
                                                    value={BookFromData.inputEmail}
                                                    placeholder="Email" required="" />
                                            </div>
                                            <div className="input-box">
                                                <input type="text"
                                                    onChange={handleChange}
                                                    name="inputCity" placeholder="City"
                                                    value={BookFromData.inputCity}
                                                    required="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="input-box">
                                                <textarea name="inputMessage"
                                                    onChange={handleChange}
                                                    placeholder="Message"
                                                    value={BookFromData.inputMessage}
                                                    required=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="button-box text-center">
                                                <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                                <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                    <span className="txt">Send</span>
                                                </button>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}