import React from "react";

export default function Doughhandling() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Dough Handling
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Precision Craftsmanship for Superior Dough Handling</h5>
                                    <p>Discover a new standard of excellence in your bakery with Middleby Celfrost's superior dough handling equipment. We recognize that baking is a harmonious blend of art and science, demanding precision and consistency. Our curated selection of top-tier dough sheeters, dough dividers, dough rounders, and dough moulders from renowned brands such as Toastmaster, Doyon, and Glimek caters specifically to the needs of large-scale bakeries.</p>

                                    <h5>Unrivaled Performance and Reliability</h5>
                                    <ul>
                                        <li><strong>Toastmaster Dough Sheeters</strong><br/>Toastmaster sets the benchmark for reliability and user-friendly design. Tailored for efficiency and consistent results, our Toastmaster dough sheeters simplify tasks, ensuring the perfect thickness for croissants, pastries, or pizza dough. Experience unparalleled precision in every sheet.</li>
                                        <li><strong>Doyon Dough Sheeters</strong><br/>For those seeking the epitome of quality and performance, Doyon dough sheeters stand as the ultimate choice. Doyon is synonymous with precision and excellence in the bakery world. Engineered to meet the demands of the busiest bakeries, these sheeters offer exceptional durability and precision.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/643FDD-450A.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p className="text-justify">
                                    <h5>Features That Redefine Dough Handling</h5>
                                    <ul>
                                        <li><strong>Versatility:</strong> Ideal for a wide range of dough types and recipes.</li>
                                        <li><strong>Consistency:</strong> Achieve uniform thickness and quality in every sheet.</li>
                                        <li><strong>Durability:</strong> Designed to withstand the rigors of commercial baking.</li>
                                        <li><strong>Ease of Use:</strong> User-friendly controls for seamless operation.</li>
                                    </ul>

                                    <p>Comprehensive Dough Solutions for Large-Scale Operations. In addition to our premium dough sheeters, we provide complete solutions for large-scale industrial dough handling, including dough dividers, dough rounders, and dough moulders. Streamline your bakery's efficiency with our industrial-grade equipment, purpose-built for high-volume production. Experience the perfect blend of craftsmanship, innovation, and reliability in every sheet with Middleby Celfrost.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}