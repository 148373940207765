import React, { useState, useEffect,useRef } from "react"; // Import useState and useEffect
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ProductCarousel from './Componants/ProductCarousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export default function SingleProduct() {
    const [product, setData] = useState(null);
    const [imageArr, setImageArr] = useState(null);

    // const { category, brand, search } = useParams();
    const { id } = useParams();
    // Function to fetch data from the API
    const fetchData = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, {
                "action": "ProductDetails",
                "product": id
            });
            console.log(response.data.data);
            setData(response.data.data);
            const hisat = response.data.data;
            setImageArr([
                hisat.image_path_1 && {
                    url: hisat.image_path_1,
                },
                hisat.image_path_2 && {
                    url: hisat.image_path_2,
                },
                hisat.image_path_3 && {
                    url: hisat.image_path_3,
                },
                hisat.image_path_4 && {
                    url: hisat.image_path_4,
                },
                hisat.image_path_5 && {
                    url: hisat.image_path_5,
                },

            ].filter(Boolean))
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);

    const OpenTingleModal = (modeltype) => {
        if (modeltype) {
            setModalVisible(true);
            setModalType(modeltype);
        } else {
            setModalVisible(false);
        }
    }



    useEffect(() => {
        fetchData(id);
    }, [id]);



    const [BookFromData, setBookFromData] = useState({
        action: 'ProductEnquery',
        product_name: id,
        inputFullName: '',
        inputNumber: '',
        inputEmail: '',
        inputMessage: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const toastId = useRef(null)
    const BookhandleSubmit = async (e) => {
        toastId.current =toast.loading("Please Wait...");
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, BookFromData);

            if (response.data.status === true) {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "success",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
                setModalVisible(false)
                setBookFromData({
                    action: 'ProductEnquery',
                    product_name: id,
                    inputFullName: '',
                    inputNumber: '',
                    inputEmail: '',
                    inputMessage: ''
                });
            } else {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "error",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <>
            <ToastContainer />
            <section className="section section--masthead basic">
                <div className="container">
                    <div className="breadcrumbs">
                        <ol itemScope="" itemType="https://schema.org/BreadcrumbList">
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <Link itemProp="item" href="/">
                                    <span itemProp="name">Home</span>
                                </Link>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <Link itemProp="item" to={product && product.Categorslug ? `/products/category/${product.Categorslug}` : ''}>
                                    <span itemProp="name">{product && product.CategoryName}</span>
                                </Link>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <Link itemProp="item" to={product && product.Brandslug ? `/brand/${product.Brandslug}` : ''}>
                                    <span itemProp="name">{product && product.BrandName}</span>
                                </Link>
                                <meta itemProp="position" content="1" />
                            </li>
                            <li className="crumb crumb-3" itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <span itemProp="item">
                                    <span itemProp="name"><strong>{product && product.product_name}</strong></span>
                                </span>
                                <meta itemProp="position" content="2" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <br /><br /><br />

            {/* <!-- Section – Product --> */}
            <section className="section section--product  mb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-5 order-md-last">
                            {product &&
                                <div className="column column--right">
                                    <div className="logo">
                                        <a href="#">
                                            <img width="339" height="200" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${product.BrandLogo}`} className="attachment-full size-full"
                                             alt={product.product_name} decoding="async" loading="lazy" style={{ width: 'auto', height: '40px' }} />
                                        </a></div>
                                    <h1>{product.product_name}</h1>
                                    <p><strong>MODEL:</strong>{product.model}</p>
                                    <h2>DESCRIPTION</h2>
                                    <div dangerouslySetInnerHTML={{ __html: product.description }} />
                                    <div className="actions">
                                        <div className="row g-3">
                                        {product.product_link &&
                                            <div className="col-12">
                                                <a href={`${product.product_link}`} target="_blank" className="btn">
                                                    <span>Enquire about this product</span>
                                                    <span className="icon">
                                                        <i className="fa fa-chevron-right"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            }
                                            {product.brochure_link &&
                                                <div className="col-12">
                                                    <a target="_blank" className="btn btn--secondary" download href={`${process.env.REACT_APP_API_URL}assets/images/brochure/${product.brochure_link}`}>
                                                        <span>Download Brochure</span>
                                                        <span className="icon">
                                                            <i className="fa fa-chevron-right"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            }
                                            <div className="col-12">
                                                <a onClick={() => OpenTingleModal('productenqurie')} className="btn" id="product-enquire" data-product="<?= $productrew['product_name']; ?>">
                                                    <span>Contact Us</span>
                                                    <span className="icon"><i className="fa fa-chevron-right"></i></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-5 col-lg-7 order-md-first">
                            <div className="column column--left">
                                {product && <ProductCarousel product={product.id} productImg={imageArr} />}
                            </div>
                        </div>




                    </div>
                </div>
            </section>

            {product && product.more_info &&
                <section className="section section--product" style={{ background: 'var(--color-tertiary)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 pro-specs py-5">
                                <h2>PRODUCT DETAILS</h2>
                                <div dangerouslySetInnerHTML={{ __html: product.more_info }} />   </div>
                        </div>
                    </div>
                </section>
            }

            <div className="tingle-modal tingle-modal--overflow" id="tingle-modal25">
                <button type="button" className="tingle-modal__close" id="tingle-modal__close25">
                    <span className="tingle-modal__closeIcon">
                        <i className="fa fa-xmark"></i>
                    </span>
                    <span className="tingle-modal__closeLabel">Close</span>
                </button>
                <div className="tingle-modal-box">
                    <div className="tingle-modal-box__content">
                        <div id="BookProduct" className="modal-content">
                            <div className="wysiwyg">
                                <h2>
                                    <strong>Enquire about this product</strong>
                                </h2>
                            </div>
                            <div className="wpcf7 js" id="wpcf7-f698-o1" lang="en-GB" dir="ltr">
                                <div className="screen-reader-response">
                                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                                    <ul></ul>
                                </div>
                                <form action="" method="post" id="add_Form_product" className="wpcf7-form init" autoComplete="off">
                                    <input type="hidden" name="product_name" value="<?= $productrew['product_name']; ?>" />
                                    <div className="form-group form-label-field">
                                        <p>
                                            <label className="label" htmlFor="inputFullName">Full Name*</label>
                                            <span className="wpcf7-form-control-wrap" data-name="inputFullName">
                                                <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" name="inputFullName" aria-required="true" aria-invalid="false" value="" type="text" id="inputFullName" />
                                                <div id="err-inputFullName"></div>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="form-group form-label-field">
                                        <p>
                                            <label className="label" htmlFor="inputNumber">Contact Number*</label>
                                            <span className="wpcf7-form-control-wrap" data-name="inputNumber">
                                                <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" name="inputNumber" aria-required="true" aria-invalid="false" value="" type="tel" id="inputNumber" />
                                                <div id="err-inputNumber"></div>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="form-group form-label-field">
                                        <p>
                                            <label className="label" htmlFor="inputEmail">Email Address*</label>
                                            <span className="wpcf7-form-control-wrap" data-name="inputEmail">
                                                <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" name="inputEmail" aria-required="true" aria-invalid="false" value="" type="email" id="inputEmail" />
                                                <div id="err-inputEmail"></div>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="form-group form-group-textarea form-label-field">
                                        <p>
                                            <label className="label" htmlFor="inputMessage">How can we help?</label>
                                            <span className="wpcf7-form-control-wrap" data-name="inputMessage">
                                                <textarea cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" name="inputMessage" aria-invalid="false" id="inputMessage"></textarea>
                                                <div id="err-inputMessage"></div>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <p>*Required </p>
                                        </div>
                                        <div className="col-5">
                                            <button type="submit" className="btn btn--secondary btn--block" name="BookproductSubmit" id="BookproductSubmit" value="BookproductSubmit">
                                                <span>Send</span>
                                                <span className="icon">
                                                    <i className="fa fa-chevron-right"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="wpcf7-response-output" aria-hidden="true"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className={`tingle-modal tingle-modal--overflow ${modalVisible ? 'tingle-modal--visible' : ''}`} id="tingle-modal23">
                <button type="button" className="tingle-modal__close" id="tingle-modal__close23" onClick={() => OpenTingleModal()}>
                    <span className="tingle-modal__closeIcon">
                    </span>
                    <span className="tingle-modal__closeLabel">Close</span>
                </button>
                <div className="tingle-modal-box">
                    <div className="tingle-modal-box__content">
                        {modalType && modalType === 'productenqurie' &&
                            <div id="demonstration1" className="modal-content">
                                <div className="wysiwyg">
                                    <h2>
                                        <strong>Enquire About Product </strong>
                                    </h2>
                                </div>
                                <div className="wpcf7 js" id="wpcf7-f698-o1" lang="en-GB" dir="ltr">
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        <ul></ul>
                                    </div>
                                    <form onSubmit={BookhandleSubmit} method="post" id="add_Form" className="wpcf7-form init">
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputFullName">Full Name*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputFullName">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        name="inputFullName"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputFullName}
                                                        type="text"
                                                        id="inputFullName" />
                                                    <div id="err-inputFullName"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputNumber">Contact Number*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputNumber">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                                        name="inputNumber"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputNumber}
                                                        type="tel"
                                                        id="inputNumber" />
                                                    <div id="err-inputNumber"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputEmail">Email Address*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputEmail">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        name="inputEmail"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputEmail}
                                                        type="email"
                                                        id="inputEmail" />
                                                    <div id="err-inputEmail"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-group-textarea form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputMessage">How can we help?</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputMessage">
                                                    <textarea
                                                        onChange={handleChange}
                                                        cols="40" rows="10"
                                                        className="wpcf7-form-control wpcf7-textarea"
                                                        name="inputMessage"
                                                        aria-invalid="false"
                                                        id="inputMessage"
                                                        value={BookFromData.inputMessage} ></textarea>
                                                    <div id="err-inputMessage"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                <p>*Required </p>
                                            </div>
                                            <div className="col-5">
                                                <p>
                                                    <button className="btn btn--secondary btn--block"
                                                        name="DemonstrationSubmit" id="DemonstrationSubmit" type="submit">
                                                        <span>Send</span>
                                                        <span className="icon"></span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true"></div>
                                    </form>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}