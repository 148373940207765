import React from "react";

export default function Minibars() {
    return (
        <>
            <section className="pt-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Minibars



                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">

                                    <p>
                                        Our Celfrost Minibars are not just functional; they add a touch of sophistication and convenience to your guest rooms. With options that include both compressor and absorption cooling systems, as well as solid or glass doors, you have the freedom to choose the Minibar that suits your hotel's unique ambiance.</p>

                                </p>

                                <p className="pt-4 text-justify">

                                    <h1>Why Choose Celfrost Minibars? </h1>

                                    <ul>
                                        <li>
                                            <strong> Efficiency : </strong>
                                            Our Minibars are designed for energy efficiency, ensuring minimal impact on your utility bills.
                                        </li>

                                        <li>
                                            <strong> Variety : </strong>
                                            From different cooling systems to door types, we offer a range of options to cater to your specific needs. </li>

                                        <li>
                                            <strong> Quality : </strong>
                                            Celfrost Minibars are built to last, with reliable performance that ensures your guests' satisfaction.
                                        </li>
                                    </ul>

                                    <p>

                                        Enhance your hotel rooms with Celfrost Minibars. It's the small touch that can make a big difference in your guests' stay. Choose Celfrost for quality, style, and the perfect Minibar to complement your hotel's ambiance.
                                    </p>
                                </p>





                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1 transform" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-225-1.png`} alt="Minibars image" title="Minibars image" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>



        </>
    )
}