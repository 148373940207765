import React from "react";

export default function Dishwasher() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Dish Washer
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <h5>Middleby Celfrost Dishwashers: Setting the Standard for Hygiene in Your Kitchen</h5>
                                    <p>In a post-COVID world, sanitation is more critical than ever, and at Middleby Celfrost, we take it seriously. Our renowned Celfrost brand is synonymous with quality, reliability, and robust commercial dishwashers designed to meet the highest hygiene standards.</p>

                                    <h5>Explore Our Wide Range of Dishwashers:</h5>
                                    <ul>
                                        <li><strong>Undercounter Glass Washer:</strong> Ensure the highest level of glassware hygiene with our Undercounter Glass Washer. Compact yet powerful, it delivers exceptional cleanliness for your glassware, contributing to a safe and pristine drinking experience.</li>
                                        <li><strong>Undercounter Dishwasher:</strong> Versatile, efficient, and reliable, our Undercounter Dishwasher is a kitchen workhorse. With exceptional sanitation capabilities, it meets the demands of various utensils, ensuring they are thoroughly disinfected and ready for use.</li>
                                        <li><strong>Hood Type Dishwasher:</strong> Ideal for heavy-duty dishwashing in high-demand kitchens, our Hood Type Dishwasher sets the standard for superior hygiene. It handles large loads efficiently, maintaining impeccable cleanliness in your kitchen.</li>
                                        <li><strong>Conveyor Dishwasher:</strong> The pinnacle of convenience for large-scale operations, our Conveyor Dishwasher features advanced sanitation technology. With the capacity to handle substantial quantities, it ensures that every dish meets the highest cleanliness standards.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <br /><br />
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-236-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p className="text-justify">
                                    <h5>Exceptional Sanitation Capabilities</h5>
                                    <p>Our dishwashers clean at high temperatures, exceeding 80°C, ensuring thorough disinfection and the elimination of contaminants. The commitment to hygiene extends beyond industry standards, providing a reliable and safe environment for your kitchen.</p>

                                    <p>Choose Celfrost for Top-Notch Hygiene, Reliability, and Performance</p>
                                    <p>Ensure your kitchen's cleanliness, efficiency, and customer safety with Middleby Celfrost. Our 100% commercial and robust quality dishwashers, backed by ultimate service support, offer the assurance you need in today's hygiene-conscious landscape. Invest in Middleby Celfrost for a dishwashing solution that goes beyond expectations.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}