import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';


export default function Landing() {

    useEffect(() => {
        new Splide('.js-splide-masthead', {
            type: 'fade',
            drag: 'free',
            focus: 'right',
            perPage: 1,
            autoScroll: {
                speed: 1,
            },
            speed: 4e3,
            rewind: !0,
            updateOnMove: !0,
            autoplay: !0,
            interval: 6e3,
            pauseOnHover: !1,
            pauseOnFocus: !1,
            arrows: !1,
            pagination: !1
            // type: 'loop',
            // drag: 'free',
            // focus: 'right',
            // perPage: 1,
            // autoScroll: {
            //     speed: 1,
            // },
        }).mount();
    }, []);

    const [BookFromData, setBookFromData] = useState({
        action: 'DemonstrationSubmit',
        inputFullName: '',
        inputNumber: '',
        inputEmail: '',
        inputCity: '',
        inputMessage: ''
    });

    const [catalouge, setCatalouge] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const toastId = useRef(null)

    const BookhandleSubmit = async (e) => {
        toastId.current =toast.loading("Please Wait...");
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, BookFromData);

            if (response.data.status === true) {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "success",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
                setModalVisible(false)
                setBookFromData({
                    action: 'DemonstrationSubmit',
                    inputFullName: '',
                    inputNumber: '',
                    inputEmail: '',
                    inputCity: '',
                    inputMessage: ''
                });
            } else {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "error",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const [DownloadFromData, setDownloadFromData] = useState({
        action: 'DownloadBrochureSubmit',
        inputFullName1: '',
        inputNumber1: '',
        inputEmail1: '',
        companyName1: '',
        inputMessage1: '',
        brochure: ''
    });

    const DownloadhandleChange = (e) => {
        const { name, value } = e.target;
        setDownloadFromData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const DownloadhandleSubmit = async (e) => {
        toastId.current =toast.loading("Please Wait...");
        e.preventDefault();
        try {
            console.log(DownloadFromData)
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, DownloadFromData);

            if (response.data.status === true) {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "success",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
                setModalVisible(false)
                setDownloadFromData({
                    action: 'DownloadBrochureSubmit',
                    inputFullName1: '',
                    inputNumber1: '',
                    inputEmail1: '',
                    companyName1: '',
                    inputMessage1: '',
                    brochure: ''
                });
                window.open(`${process.env.REACT_APP_APP_URL}/catalouge/assets/catalouge/${DownloadFromData.brochure}`, "_blank");
            } else {
                toast.update(toastId.current, {
                    render: response.data.message,
                    type: "error",
                    autoClose: 5000,
                    closeOnClick: true,
                    isLoading: false
                })
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const [data, setData] = useState(null);
    const [brands, setBrands] = useState(null);
    const [marquee, setMarquee] = useState(null);
    const navigate = useNavigate();
    // Function to fetch data from the API for HeaderMenuCategory
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'HeaderMenuCategory' });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const ListCatalogueData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'ListCatalogue' });
            setCatalouge(response.data);
        } catch (error) {
            console.error('Error fetching Catalouge data:', error);
        }
    };

    // Function to fetch data from the API for HeaderMenuBrands
    const fetchDataBrands = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'HeaderMenuBrands' });
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands data:', error);
        }
    };


    // Function to fetch data from the API for marquee atg
    const fetchMarquee = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_MAIN}`, { action: 'OurPartners' });
            setMarquee(response.data);
        } catch (error) {
            console.error('Error fetching brands data:', error);
        }
    };

    const renderProductCat = () => {
        if (!data || !data.data) return null;

        return data.data.map(product => (
            <div className="col-md-4 col-lg-3" key={product.id}>
                <article className="article--product-type">
                    <Link to={`/products/category/${product.slug}`}>
                        <img src={`${process.env.REACT_APP_API_URL}assets/images/category/${product.image}`} />
                        <h3>{product.name}</h3>
                    </Link>
                </article>
            </div>
        ));
    };

    const renderProductBrandslogo = () => {
        if (!brands || !brands.data) return null;

        return brands.data.map(brand => (
            <div className="splide__slide"
                role="button"
                style={{ marginRight: '40px', height: '80px' }}
                key={brand.id}              >
                <img width="197" height="100" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.logo}`}
                    className="attachment-full size-full" decoding="async" loading="lazy" alt={brand.name} />
            </div>
        ));
    };
    const renderProductBrandslogoDesc = () => {
        if (!brands || !brands.data) return null;

        return brands.data.map(brand => (
            <div className="splide__slide" key={brand.id + '-desc'} >
                {/* <p>Brand--{brand.id + '-desc'}</p> */}
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className="logo">
                            <img width="197" height="200" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.logo}`}
                                className="attachment-full size-full" alt={brand.name} decoding="async" loading="lazy" />
                        </div>
                        <div className="wysiwyg">
                            <h3>{brand.heading}</h3>
                            <div dangerouslySetInnerHTML={{ __html: brand.short_desc }} />

                            <a className="btn" href={`products/brand/${brand.slug}`} tabIndex="-1"><span>View {brand.name} Products</span><span className="icon">

                            </span></a>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-block">
                        <img width="600" height="750" src={`${process.env.REACT_APP_API_URL}assets/images/tags/${brand.image}`}
                            className="attachment-brand-image size-brand-image"
                            alt={brand.name} decoding="async" loading="lazy" />
                    </div>
                </div>
            </div>
        ));
    };
    const renderCustomerlogo = () => {
        if (!marquee || !marquee.data) return null;
        return marquee.data.map(brand => (
            <div key={brand.id} >
                {/* Assuming the logo path is relative */}
                <img className="attachment-full size-full p-2" decoding="async" loading="lazy" src={`${process.env.REACT_APP_API_URL}/${brand.url}`} alt={brand.alt} />
            </div>

        ));
    };

    const renderCatalouge = () => {
        if (!catalouge || !catalouge.data) return null;

        return catalouge.data.map(product => (
            <option value={product.pdf}>{product.name}</option>
        ));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const searchQuery = formData.get('search');
        navigate(`/products/search/${encodeURIComponent(searchQuery)}`);
    };



    useEffect(() => {
        fetchData();
        fetchDataBrands();
        ListCatalogueData();
        renderProductBrandslogo();
        renderProductBrandslogoDesc();
        fetchMarquee();
    }, []);


    useEffect(() => {
        var main = new Splide('#main-slider', {
            type: 'fade',
            rewind: true,
            pagination: false,
            arrows: false,
        });

        var thumbnails = new Splide('#thumbnail-slider', {
            fixedWidth: 100,
            fixedHeight: 60,
            gap: 10,
            rewind: true,
            pagination: false,
            isNavigation: true,
            breakpoints: {
                600: {
                    fixedWidth: 60,
                    fixedHeight: 44,
                },
            },
        });

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();

    }, [brands]);



    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);

    const OpenTingleModal = (modeltype) => {
        if (modeltype) {
            setModalVisible(true);
            setModalType(modeltype);
        } else {
            setModalVisible(false);
        }
    }

    return (
        <>
            <ToastContainer />
            <section className="js-block-masthead section section--masthead advanced">
                <div className="section--masthead__top">
                    <div className="js-parallax images images--middle" data-offset="-0.05" style={{ backgroundImage: 'url("assets/uploads/bckgnd--masthead-top-01.jpg")' }}>
                    </div>
                    <div className="js-parallax images images--back" data-offset="0.15" style={{ backgroundImage: 'url("assets/uploads/img--masthead-bckgnd-01.jpg")' }}></div>
                    <div
                        className="js-splide-masthead js-parallax images images--front splide splide--masthead"
                        data-offset="0.15" id="secondary-slider">
                        <div className="splide__track">
                            <div className="splide__list">
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/img-masthead-home-01.png")' }}></div>
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/ciboplus.png")' }}></div>
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/sveba.png")' }}></div>
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/josper.png")' }}></div>
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/celfrost.png")' }}></div>
                                <div className="splide__slide" style={{ backgroundImage: 'url("assets/uploads/icetro.png")' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="wysiwyg">
                                        <p>Welcome to Middleby Celfrost, your premier destination for innovative and reliable foodservice equipment solutions. With a rich legacy spanning decades, Middleby is a Global leader in designing, manufacturing, and distributing a comprehensive range of cutting-edge commercial kitchen equipment.
                                        </p>
                                    </div>
                                    <div className="row g-2 mt-5">
                                        <div className="col-md-4">
                                            <Link className="btn btn--block" to={`/about-us`}>
                                                <span>About Us</span>
                                                <span className="icon"></span>
                                            </Link>
                                        </div>
                                        <div className="col-md-8">
                                            <form className="js-form-product-search" onSubmit={handleSubmit} autoComplete="off" method="GET">
                                                <label className="visually-hidden" htmlFor="s">Search products</label>
                                                <div className="input-group">
                                                    <span className="icon"><i className="fas fa-magnifying-glass"></i> </span>
                                                    <input className="form-control" name="search" type="text" placeholder="Search products&hellip;" />
                                                </div>
                                                <button className="visually-hidden" type="submit">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section--masthead__bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="wysiwyg">
                                    <p style={{ textAlign: 'center' }}>
                                        Middleby Celfrost is a part of USA-based Middleby Corporation, <strong>Middleby Celfrost</strong> distribute global catering equipment brands, such as Lincat, Pitco, TurboChef, Sveba dahlen, Firex, Midd=kitchen, Toastmaster, Cooktek, Josper, Bakers Pride and more through a network of Indian distribution partners.
                                    </p>
                                    <p style={{ textAlign: 'center' }}>
                                        <a className="btn mx-2" onClick={() => OpenTingleModal('freedemo')} id="form-demonstration">Book a free demo<span className="icon"></span></a>
                                        <a className="btn btn--secondary" onClick={() => OpenTingleModal('downloadbroucher')} id="form-brochure">Download your 2024 brochure<span className="icon"></span></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section section--shadow top"></div>
            <section className="section section--products" id='ourproductsection'>
                <div className="container">
                    <div className="wysiwyg">
                        <h2 style={{ textAlign: 'center' }}><strong>
                            <span style={{ Color: '#dd1b35' }}>OUR PRODUCTS</span></strong></h2>
                        <p style={{ textAlign: 'center' }}>Click to explore each
                            product range</p>
                        <hr />
                        <p style={{ textAlign: 'center' }}></p>
                    </div>
                    <div className="row g-4">
                        {renderProductCat()}
                    </div>
                </div>
            </section>
            <section className="section section--customers" style={{ backgroundColor: '#ffffff', Color: 'inherit' }}>
                <div className="container">
                    <div className="wysiwyg">
                        <p className='text-black' style={{ textAlign: 'center' }}>
                            <strong>Our product range benefits national chains such as</strong>
                            <br /> KFC, Burger King, Domino's, Amul, Starbucks and many more…
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <marquee direction="right" width="450" behavior="alternate" style={{ color: '#E1E4E7', width: '100%' }}>
                                {renderCustomerlogo()}
                            </marquee>
                        </div>
                        <div className="col-md-6">
                            <marquee direction="left" width="450" behavior="alternate" style={{ color: '#E1E4E7', width: '100%' }}>
                                {renderCustomerlogo()}
                            </marquee>
                        </div>
                    </div>
                </div>
            </section>
            <section id="ourbrandsection" className="js-block-brands section section--brands" style={{ background: 'linear-gradient(0deg,var(--color-white) 0,var(--color-tertiary))' }}>



                <div className="container">
                    <div className="wysiwyg">
                        <h2 style={{ textAlign: 'center' }}><strong>
                            <span style={{ color: '#dd1b35' }}>OUR BRANDS</span></strong>
                        </h2><p style={{ textAlign: 'center' }}>Click to explore each product range</p>
                        <hr />
                        <p style={{ textAlign: 'center' }}></p>
                    </div>

                    <div className='splide-wrapper'>

                        <div className="js-splide-brands-thumbs splide splide--brands-thumbs" id='thumbnail-slider'>
                            <div className="splide__track">
                                <div className="splide__list">

                                    {renderProductBrandslogo()}

                                </div>
                            </div>
                        </div>

                        <div className="js-splide-brands-main splide splide--brands-main pt-5" id='main-slider'>
                            <div className="splide__track" >
                                <div className="splide__list">
                                    {renderProductBrandslogoDesc()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className={`tingle-modal tingle-modal--overflow ${modalVisible ? 'tingle-modal--visible' : ''}`} id="tingle-modal23">
                <button type="button" className="tingle-modal__close" id="tingle-modal__close23" onClick={() => OpenTingleModal()}>
                    <span className="tingle-modal__closeIcon">
                    </span>
                    <span className="tingle-modal__closeLabel">Close</span>
                </button>
                <div className="tingle-modal-box">
                    <div className="tingle-modal-box__content">
                        {modalType && modalType === 'freedemo' &&
                            <div id="demonstration1" className="modal-content">
                                <div className="wysiwyg">
                                    <h2>
                                        <strong>Book a Demonstration</strong>
                                    </h2>
                                </div>
                                <div className="wpcf7 js" id="wpcf7-f698-o1" lang="en-GB" dir="ltr">
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        <ul></ul>
                                    </div>
                                    <form onSubmit={BookhandleSubmit} method="post" id="add_Form" className="wpcf7-form init">
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputFullName">Full Name*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputFullName">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        name="inputFullName"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputFullName}
                                                        type="text"
                                                        id="inputFullName" />
                                                    <div id="err-inputFullName"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputNumber">Contact Number*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputNumber">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                                        name="inputNumber"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputNumber}
                                                        type="tel"
                                                        id="inputNumber" />
                                                    <div id="err-inputNumber"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputEmail">Email Address*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputEmail">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        name="inputEmail"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputEmail}
                                                        type="email"
                                                        id="inputEmail" />
                                                    <div id="err-inputEmail"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputCity">City*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputCity">
                                                    <input size="40"
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        name="inputCity"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={BookFromData.inputCity}
                                                        type="text"
                                                        id="inputCity" />
                                                    <div id="err-inputCity"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-group-textarea form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputMessage">How can we help?</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputMessage">
                                                    <textarea
                                                        onChange={handleChange}
                                                        cols="40" rows="10"
                                                        className="wpcf7-form-control wpcf7-textarea"
                                                        name="inputMessage"
                                                        aria-invalid="false"
                                                        id="inputMessage"
                                                        value={BookFromData.inputMessage} ></textarea>
                                                    <div id="err-inputMessage"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                <p>*Required </p>
                                            </div>
                                            <div className="col-5">
                                                <p>
                                                    <button className="btn btn--secondary btn--block"
                                                        name="DemonstrationSubmit" id="DemonstrationSubmit" type="submit">
                                                        <span>Send</span>
                                                        <span className="icon"></span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true"></div>
                                    </form>
                                </div>
                            </div>
                        }

                        {modalType && modalType === 'downloadbroucher' &&
                            <div id="demonstration2" className="modal-content">
                                <div className="wysiwyg">
                                    <h2>
                                        <strong>Download your 2024 brochure</strong>
                                    </h2>
                                </div>
                                <div className="wpcf7 js" id="wpcf7-f698-o2" lang="en-GB" dir="ltr">
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        <ul></ul>
                                    </div>
                                    <form onSubmit={DownloadhandleSubmit} method="post" id="add_Form2" className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputFullName1">Full Name*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputFullName1">
                                                    <input size="40"
                                                        onChange={DownloadhandleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        name="inputFullName1"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={DownloadFromData.inputFullName1}
                                                        type="text"
                                                        id="inputFullName1" />
                                                    <div id="err-inputFullName1"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputNumber1">Contact Number*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputNumber1">
                                                    <input onChange={DownloadhandleChange}
                                                        size="40"
                                                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                                        name="inputNumber1" aria-required="true" aria-invalid="false"
                                                        value={DownloadFromData.inputNumber1}
                                                        type="tel" id="inputNumber1" />
                                                    <div id="err-inputNumber1"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputEmail1">Email Address*</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputEmail1">
                                                    <input onChange={DownloadhandleChange}
                                                        size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        name="inputEmail1" aria-required="true" aria-invalid="false"
                                                        value={DownloadFromData.inputEmail1}
                                                        type="email" id="inputEmail1" />
                                                    <div id="err-inputEmail1"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <label className="label" htmlFor="companyName1">Company Name</label>
                                                <span className="wpcf7-form-control-wrap" data-name="companyName1">
                                                    <input onChange={DownloadhandleChange}
                                                        size="40"
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        name="companyName1"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        value={DownloadFromData.companyName1}
                                                        type="email" id="companyName1" />
                                                    <div id="err-companyName1"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-label-field">
                                            <p>
                                                <span className="wpcf7-form-control-wrap" data-name="Brochure">
                                                    <select id="brochure" name="brochure" onChange={DownloadhandleChange}>
                                                        <option value="" disabled selected>(Brochure) Select your option</option>
                                                        {renderCatalouge()}
                                                        {/* <option value="Bakery-&-Catering-Toastmaster">Bakery & Catering - Toastmaster</option>
                                                        <option value="Bar-Essential">Bar Essential</option>
                                                        <option value="Cibo-&-Cibo">Cibo & Cibo</option>
                                                        <option value="Classic-Series-тАУ-Freezers-&-Coolers">Classic Series-Freezers & Coolers</option>
                                                        <option value="Coffee-Machines-&-Commercial-Blenders">Coffee Machines & Commercial Blenders</option>
                                                        <option value="Cold-Room">Cold Room</option>
                                                        <option value="Combi-Oven-тАУ-HOUNO">Combi Oven-HOUNO</option>
                                                        <option value="Commercial-Microwave-Oven">Commercial Microwave Oven</option>
                                                        <option value="Confectionary-Showcase">Confectionary Showcase</option>
                                                        <option value="Conveyor-&-Speed-Ovens,-Fryers,-Inductions">Conveyor & Speed Ovens, Fryers, Inductions</option>
                                                        <option value="Dishwasher">Dishwasher</option>
                                                        <option value="Ice-cube-Machines">Ice cube Machines</option>
                                                        <option value="IMC">IMC</option>
                                                        <option value="Kitchen-Refrigeration">Kitchen Refrigeration</option>
                                                        <option value="Lincat-IMC">Lincat+IMC</option>
                                                        <option value="Medical-Refrigeration">Medical Refrigeration</option>
                                                        <option value="Middleby-Range">Middleby Range</option>
                                                        <option value="Minibar">Minibar</option>
                                                        <option value="Retail-Refrigeration-тАУ-Freezers-&-Coolers">Retail Refrigeration-Freezers & Coolers</option>
                                                        <option value="Soft-Serve-тАУ-Icetro">Soft Serve-Icetro</option>
                                                        <option value="Soft-Serve-тАУ-Taylor">Soft Serve-Taylor</option> */}
                                                    </select>
                                                    <div id="err-brochure"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="form-group form-group-textarea form-label-field">
                                            <p>
                                                <label className="label" htmlFor="inputMessage1">City</label>
                                                <span className="wpcf7-form-control-wrap" data-name="inputMessage">
                                                    <input
                                                        onChange={DownloadhandleChange}
                                                        cols="40" rows="10"
                                                        className="wpcf7-form-control wpcf7-textarea"
                                                        name="inputMessage1" aria-invalid="false"
                                                        id="inputMessage1"/>
                                                    <div id="err-inputMessage1"></div>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                <p>*Required </p>
                                            </div>
                                            <div className="col-5">
                                                <p>
                                                    <button className="btn btn--secondary btn--block" id="DownloadBrochureSubmit" name="DownloadBrochureSubmit" type="submit">
                                                        <span>Download</span>
                                                        <span className="icon"></span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true"></div>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}