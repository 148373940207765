import React from "react";

export default function Batchcookingbrattpans() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 style={{ textAlign: 'left' }}>
                                    <strong style={{ color: '#dd1b35', borderBottom: '1px solid black' }}>
                                    Bulk Cooking /Bratt Pan
                                    </strong>
                                </h1>
                                <br />
                                <p style={{ textAlign: 'justify' }}>
                                    <strong>Master Batch Cooking with Firex Bratt Pans</strong>
                                    <p className="mb-3">Unlock the potential of your kitchen with our exceptional Batch Cooking/Bratt Pans from the renowned Firex brand. Designed for culinary innovation and efficiency, these Bratt Pans are your key to mastering large-scale cooking with precision and control.</p>
                                    <strong>Firex Bratt Pans: A Culinary Game Changer</strong>
                                    <ul>
                                        <li className="pb-0 mt-2">Precision Performance :</li>
                                        <p className="mb-3">Experience precise temperature control and even heat distribution, allowing you to achieve perfection in every batch. Firex Bratt Pans are crafted for chefs who demand excellence in their cooking processes.</p>
                                        <li className="pb-0">Versatility Unleashed :</li>
                                        <p className="mb-3">From sautéing and browning to simmering and boiling, Firex Bratt Pans empower chefs with versatile cooking options. Seamlessly transition between cooking techniques to elevate the quality of your dishes.</p>
                                        <li className="pb-0">Intuitive Design :</li>
                                        <p className="mb-3">Navigating through culinary tasks is a breeze with Firex's user-friendly design. The intuitive controls and ergonomic features make batch cooking efficient and enjoyable.</p>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-101-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">
                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-96-1.png`} alt="Batch Cooking Bratt Pans image" title="Batch Cooking Bratt Pans image"  style={{ height: "275px", width: "auto" }} />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <p style={{ textAlign: 'justify' }}>
                                    <strong>Why Firex for Batch Cooking?</strong>
                                    <ul>
                                        <li className="pb-0 mt-2">Innovative Technology: Firex Bratt Pans incorporate cutting-edge technology for superior performance.</li>
                                        <li className="pb-0 mt-2">Durability: Built with robust materials, Firex ensures longevity and reliability even in the busiest kitchens.</li>
                                        <li className="pb-0 mt-2 mb-3">Chef-Centric Solutions: Crafted with the needs of chefs in mind, Firex Bratt Pans streamline your batch cooking operations.</li>
                                    </ul>
                                    <strong>Transform Your Kitchen Today</strong>
                                    <p className="mb-3 mt-2">Revolutionize your batch cooking experience with Firex Bratt Pans. Explore the range and witness the seamless fusion of innovation and culinary expertise.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}