import React, { useState, useEffect } from "react";

export default function Beverage() {

    const [sectionVisibility, setSectionVisibility] = useState({});
    useEffect(() => {
        const handleScroll = () => {
            const sectionPositions = {};
            const windowHeight = window.innerHeight;

            // Calculate positions for each section
            for (let i = 1; i <= 1; i++) {
                const sectionPosition = document.querySelector(`.section${i}`).getBoundingClientRect().top;
                sectionPositions[i] = sectionPosition < windowHeight;
            }

            // Update state with section visibility
            setSectionVisibility(sectionPositions);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call handleScroll initially to set visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
         


            <section className={`section p-0 section1 bg-white section--products m-0 ${sectionVisibility[1] ? 'fade-in' : 'fade-out'}`}>
                <div className="w-98 p-0">
                    <div className="row ">
                        <div className="col-lg-6 bg-image2"></div>
                        <div className={`col-lg-6 section28`}>
                            <div className="px-3  mt-4 mb-2 pb-2">
                                <h6 className="fw-bold  primarycolor lh-base">
                                    The bar scene thrives on speed, precision, and a touch of flair.
                                    But managing a high-volume pub with limited staff can be a recipe for chaos.
                                    Here's where Middleby automation steps in.
                                </h6>
                                <h6 className="fw-bold mt-4 mb-4 lh-base">
                                    We offer innovative solutions to streamline your beverage operations, from draft beer management to cocktail creation.
                                    <span className="primarycolor">  Imagine:</span>
                                </h6>

                                <ul className="list-unstyled mt-3">
                                    <li>                  <span className="primarycolor "> ✔   Automated Draft Systems: </span> Eliminate waste and ensure consistent pours.</li>
                                    <li className="pt-3"> <span className="primarycolor "> ✔ Inventory Management: </span> Maintain optimal stock levels and reduce ordering headaches.</li>
                                    <li className="pt-3"> <span className="primarycolor "> ✔ Precise Cocktail Dispensing: </span> Deliver perfectly balanced drinks every time.</li>
                                    <li className="pt-3"> <span className="primarycolor "> ✔ Faster Service:  </span> Keep queues moving and customer satisfaction high.</li>
                                </ul>

                                <p className=" pt-4 ">
                                    Middleby Celfrost understands the unique challenges of bars and pubs. Let's craft a customized automation strategy that frees your
                                    bartenders to focus on what they do best: creating exceptional customer experiences.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}