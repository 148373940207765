import React from "react";

export default function Confectionaryshowcases() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-sm-6">
                            <div className="wysiwyg">
                                <h1 className="text-left" >
                                    <strong className="titleborder" >
                                        Confectionary Showcases

                                    </strong>
                                </h1>

                                <p className="pt-4 text-justify">
                                    <h1>Entice Customers with Exquisite Displays: Celfrost & Lincat Confectionery Showcases</h1>
                                    <p>Elevate your bakery or pastry shop with the captivating power of Celfrost and Lincat confectionery showcases! These beautifully designed display cases are more than just storage units – they're powerful marketing tools that showcase your delectable creations in all their glory. Middleby Celfrost offers a comprehensive selection of Celfrost and Lincat confectionery showcases to cater to any size or style of operation.</p>

                                    <h2>Unveiling the Advantages:</h2>
                                    <ul>
                                        <li>Enhanced Appeal: Crystal-clear glass panels and strategically placed lighting create an irresistible visual showcase for your pastries, cakes, and desserts. Attract customers and tempt them with mouthwatering displays.</li>
                                        <li>Temperature Control: Choose from refrigerated, ambient, or heated display options to ensure your treats maintain optimal freshness and texture, regardless of the product.</li>
                                        <li>Versatility: Our extensive range includes various sizes and configurations, from sleek countertop models to spacious multi-deck cabinets. Find the perfect showcase to fit your space and display needs.</li>
                                        <li>Superior Functionality: Ergonomic design features like adjustable shelves and easy-to-clean surfaces ensure efficient operation and maintain a professional presentation.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="wysiwyg">

                                <img className="aboutimage1" src={`${process.env.REACT_APP_API_URL}assets/img/category/pro-208-1.png`} alt="Confectionary Showcases image" title="Confectionary Showcases image"  style={{ height: "600px", width: "auto" }} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wysiwyg">
                                <p className="text-justify">
                                    <h3>Celfrost & Lincat: The Perfect Fit for Your Bakery</h3>
                                    <p><strong>Celfrost:</strong> Renowned for their meticulous German engineering, Celfrost showcases deliver exceptional performance and durability. These high-end models are ideal for establishments seeking a premium aesthetic and advanced features.</p>
                                    <p><strong>Lincat:</strong> Lincat showcases offer outstanding value and functionality. With their reliable performance and sleek designs, they perfectly complement any bakery or pastry shop, ensuring your treats are displayed with distinction.</p>

                                    <h3>Let Your Creations Shine:</h3>
                                    <p>Middleby Celfrost is your one-stop shop for all your confectionery display needs. Browse our selection of Celfrost and Lincat showcases and discover the perfect solution to captivate your customers and elevate your bakery's visual appeal.</p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}